import { useTranslation } from "next-i18next";
import { TaskAssignment } from "@_types/*";

export type AdminTaskAssignmentProps = {
  taskAssignment: TaskAssignment;
  deleteTaskAssignment: () => void;
};

export const AdminTaskAssignment = (props: AdminTaskAssignmentProps) => {
  const { taskAssignment } = props;

  const { t } = useTranslation("admin");

  return (
    <div className="pb-4">
      <p className="text-body-3 flex flex-row items-center gap-4 font-bold">
        <button onClick={props.deleteTaskAssignment}>
          <span className="font-bold text-purple">
            {t("adminTaskAssignment.delete")}
          </span>
        </button>
        <span>
          {taskAssignment.assignee.firstName} {taskAssignment.assignee.lastName}
        </span>
        <span>{taskAssignment.assignee.email}</span>
        <span>{taskAssignment.pandadocRole}</span>
        <span>
          {t("adminTaskAssignment.taskAssignmentId", {
            taskId: taskAssignment.id,
          })}
        </span>
        <span>
          {t("adminTaskAssignment.sessionIdText", {
            sessionId:
              taskAssignment.pandadocSession?.sessionId ??
              t("adminTaskAssignment.none"),
          })}
        </span>
      </p>
    </div>
  );
};
