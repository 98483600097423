import type { TFunction } from "i18next";
import type { AdoptiveParent } from "@_types/*";

export const getTableRows = (item: AdoptiveParent, t: TFunction<"connect">) => [
  {
    key: t("familyListing.familyDetails.adoptionParents.rows.personality"),
    values: [item.archetype?.name],
  },
  {
    key: t("familyListing.familyDetails.adoptionParents.rows.raceEthnicity"),
    values: item.raceEthnicity,
  },
  {
    key: t("familyListing.familyDetails.adoptionParents.rows.religion"),
    values: item.religion,
  },
  {
    key: t("familyListing.familyDetails.adoptionParents.rows.education"),
    values: [item.education],
  },
  {
    key: t(
      "familyListing.familyDetails.adoptionParents.rows.politicalAffiliation"
    ),
    values: [item.politicalAffiliation],
  },
];
