import { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "next-i18next";
import { FormModal, Loading } from "@pairtreefamily/ui";
import { RolesOption, TaskAssignmentModalProps } from "./types";
import { useGetPersonOptions } from "./hooks/useGetPersonOptions";
import { useGetProviderTasks } from "./hooks/useGetProviderTasks";
import { useSetRolesOptions } from "./hooks/useSetRolesOptions";
import { AssigneesAmount } from "./components/AssigneesAmount";
import { TaskAssignmentForm } from "./components/TaskAssignmentForm";
import { useForm } from "react-hook-form";
import {
  FormValues,
  validationSchemaExistentPerson,
  validationSchemaNewPerson,
} from "./validation-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGetRequiredAssigneesAmount } from "./hooks/useGetRequiredAssigneesAmount";
import { newPersonId } from "./constants";
import { useGetIsPersonValid } from "./hooks/useGetIsPersonValid";
import { useSetValuesForSelectedPerson } from "./hooks/useSetValuesForSelectedPerson";

export function TaskAssignmentModal({
  parents,
  tasksRoles,
  currentAssignees,
  isLoadingRoles,
  isOpen,
  onClose,
  setTaskAssignment,
  caseLoginUsers,
  tasks,
}: TaskAssignmentModalProps) {
  const { t } = useTranslation(["common", "admin"]);
  const [rolesOptions, setRolesOptions] = useState<RolesOption[] | null>();
  const requiredAssigneesAmount = useGetRequiredAssigneesAmount(tasksRoles);

  const hasInvalidRole = useMemo(() => {
    return !!rolesOptions?.some((item) => !item.label.length);
  }, [rolesOptions]);

  const hasRoleInputField =
    currentAssignees && currentAssignees?.length < requiredAssigneesAmount;

  const formMethods = useForm<FormValues>({
    defaultValues: {
      personId: newPersonId,
      email: "",
      firstName: "",
      lastName: "",
      role: "",
    },
    resolver: (data, ...args) => {
      if (data.personId === newPersonId) {
        return zodResolver(validationSchemaNewPerson)(data, ...args);
      }

      return zodResolver(validationSchemaExistentPerson)(data, ...args);
    },
  });

  const providerTasks = useGetProviderTasks(tasks);
  const personId = formMethods.watch("personId");

  const setRoleOptionCallback = useCallback(
    (id: string) => {
      formMethods.setValue("role", id);
    },
    [formMethods]
  );

  const isPersonValid = useGetIsPersonValid(formMethods);

  useSetRolesOptions({
    tasksRoles,
    setRoleOption: setRoleOptionCallback,
    setRolesOptions,
    currentAssignees,
  });

  useSetValuesForSelectedPerson({
    formMethods,
    parents,
    caseLoginUsers,
    providerTasks,
  });

  const personOptions = useGetPersonOptions({
    providerTasks,
    caseLoginUsers,
    parents,
  });

  useEffect(() => {
    const personId = providerTasks?.length
      ? caseLoginUsers.find((item) => item.showContactInfoIfProvider)?.id ??
        caseLoginUsers[0]?.id
      : newPersonId;

    formMethods.setValue("personId", personId);
  }, [caseLoginUsers, formMethods, providerTasks]);

  const handleModalClose = useCallback(() => {
    onClose();
    formMethods.reset();
  }, [formMethods, onClose]);

  const isSubmitDisabled =
    (requiredAssigneesAmount > 0 && !rolesOptions?.length) ||
    hasInvalidRole ||
    (!isPersonValid && personId !== newPersonId);

  const handleFormSubmit = useCallback(
    async (values: FormValues) => {
      await setTaskAssignment({
        person: {
          email: values.email || "",
          firstName: values.firstName || "",
          lastName: values.lastName || "",
        },
        role: values.role,
      });

      handleModalClose();
    },

    [handleModalClose, setTaskAssignment]
  );

  return (
    <FormModal
      formMethods={formMethods}
      onSubmit={handleFormSubmit}
      isSubmitDisabled={isSubmitDisabled}
      submitLabel={t("common:actions.submit")}
      title={t("admin:taskAssignmentModal.title")}
      open={isOpen}
      onClose={handleModalClose}
    >
      {!isLoadingRoles && (
        <>
          <AssigneesAmount
            currentAssigneesAmount={currentAssignees?.length || 0}
            requiredAssigneesAmount={requiredAssigneesAmount}
          />

          <div className="mt-2">
            <TaskAssignmentForm
              formMethods={formMethods}
              personOptions={personOptions}
              hasRoleInputField={hasRoleInputField}
              rolesOptions={rolesOptions}
            />
          </div>

          {!isPersonValid && personId !== newPersonId && (
            <p className="my-8 text-peach">
              {t("admin:taskAssignmentModal.messages.missedPersonDate")}
            </p>
          )}

          {currentAssignees &&
            currentAssignees.length >= requiredAssigneesAmount &&
            requiredAssigneesAmount > 0 &&
            !hasInvalidRole && (
              <p className="my-8 text-peach">
                {t("admin:taskAssignmentModal.messages.assigneesFilled")}
              </p>
            )}

          {tasksRoles
            ?.flatMap((item) => item.templateRoles)
            .some(
              (item) => !item.templateUuid || !item.templateUuid.length
            ) && (
            <p className="my-8 text-peach">
              {t("admin:taskAssignmentModal.messages.emptyTemplates")}
            </p>
          )}

          {hasInvalidRole && (
            <p className="my-8 text-peach">
              {t("admin:taskAssignmentModal.messages.pandaDocTemplatesRoles")}
            </p>
          )}
        </>
      )}

      {isLoadingRoles && (
        <>
          <p>
            <small>
              {t("admin:taskAssignmentModal.messages.fetchingTemplates")}
            </small>
          </p>

          <Loading />
        </>
      )}
    </FormModal>
  );
}
