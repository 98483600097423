import { ComponentType, SVGProps } from "react";
import { Link, Box, BoxProps } from "@mui/material";
import { styles } from "./styles";

type ProfileLinkIconProps = BoxProps & {
  url?: string;
  IconComponent: ComponentType<SVGProps<SVGSVGElement>>;
};

export const ProfileLinkIcon = (props: ProfileLinkIconProps) => {
  const { url, IconComponent, ...restProps } = props;

  if (!Boolean(url?.length)) return <Box sx={styles.wrapper} {...restProps} />;

  return (
    <Box sx={styles.wrapper} {...restProps}>
      <Link href={url} target="_blank" rel="noreferrer" sx={styles.link}>
        <IconComponent />
      </Link>
    </Box>
  );
};
