import { stringToColor } from "./utils";

export const styles = {
  wrapper: (name: string) => {
    return {
      borderRadius: "50%",
      height: 40,
      width: 40,
      overflow: "hidden",
      backgroundColor: stringToColor(name),
      color: "white",
      display: "flex",
      gap: 2,
      alignItems: "center",
      justifyContent: "center",
      flex: "0 0 auto",
    };
  },
};
