import { Routes } from "@features/routing";
import { Box, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { firebaseApp } from "@lib";
import { AuthMode, LoginAndSignUp, useUserData } from "@shared/components";
import { useCallback, useEffect, useState } from "react";
import { useRegisterProToken } from "@api";
import { useAuthStatus, useSnackbarNotification } from "@pairtreefamily/ui";
import { StepTwo } from "./ProInfo/StepTwo";
import { useTranslation } from "next-i18next";

export function LoginSignUp() {
  const router = useRouter();
  const { t } = useTranslation("professional");
  const { callSnackbar } = useSnackbarNotification();
  const registerProToken = useRegisterProToken();
  const { userData: userDataWithPro, isUserDataLoading } = useUserData();
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const { signOut } = useAuthStatus();

  const isSignUp = router.route === Routes.ProAccountSignUp;
  const title = isSignUp
    ? t("authorization.getStarted")
    : t("authorization.login");
  const mode = isSignUp ? AuthMode.signUp : AuthMode.login;
  const submitButtonLabel = isSignUp
    ? t("authorization.submit.signUp")
    : t("authorization.submit.signIn");

  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

  const handleSuccess = useCallback(
    async (args: { token: string; mode: AuthMode }) => {
      setIsLoadingToken(true);
      if (mode === AuthMode.signUp) {
        // create the pro account
        const registerResponse = await registerProToken({
          token: args.token,
        });

        if (!registerResponse.ok) {
          // TODO PT-4380 need real copy/behavior
          callSnackbar({
            message:
              "Something went wrong creating your account - please try again later.",
            type: "error",
          });
        }
      }
      setIsSuccess(true);
      setIsLoadingToken(false);
    },
    [callSnackbar, mode, registerProToken]
  );

  const handleError = useCallback(
    (args: { message: string }) => {
      setIsLoadingToken(false);
      callSnackbar({
        message: args.message,
        type: "error",
      });
    },
    [callSnackbar]
  );

  useEffect(() => {
    if (router.asPath.includes("signOut")) {
      signOut();

      setTimeout(() => {
        router.replace(Routes.ProAccountSignIn);
      });

      return;
    }

    if (!userDataWithPro) {
      return;
    }

    // TODO: investigate very strange issue. userDataWithPro is differs of real data
    if (
      !(userDataWithPro as any)?.hasPendingOrgRequests ||
      !userDataWithPro?.proDetails?.has_pending_org_requests
    ) {
      router.replace(Routes.ProAccountSignUpWelcome);
    } else {
      router.replace(Routes.Home);
    }
  }, [userDataWithPro, router, signOut]);

  return (
    <Box display="flex">
      <Box maxWidth="480px" mx="auto" width="100%">
        <Typography variant="head5" component="h1" textAlign="center">
          {title}
        </Typography>

        <LoginAndSignUp
          mode={mode}
          app={firebaseApp}
          submitButtonLabel={submitButtonLabel}
          onSuccess={handleSuccess}
          disabled={isLoadingToken || (!!userDataWithPro && isUserDataLoading)}
          isLoading={isLoadingToken || (!!userDataWithPro && isUserDataLoading)}
          onFailure={handleError}
        />
        {(userDataWithPro as any)?.role === "provider" &&
          (userDataWithPro as any)?.proDetails?.has_pending_org_requests &&
          mode === AuthMode.login &&
          isSuccess && (
            <StepTwo
              title={t("authorization.organization.notActive.title")}
              body={t("authorization.organization.notActive.subtitle")}
            ></StepTwo>
          )}
      </Box>
    </Box>
  );
}
