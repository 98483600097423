import { useCallback, useState } from "react";
import { MemberDetails } from "./MemberDetails";
import { MembersTable } from "./members-table";
import { Box, Fade } from "@mui/material";
import { NewInvitation } from "./members-invitation";
import { PendingInvitations } from "./members-invitation/PendingInvitations";
import { ContentWrapper } from "@shared/components";
import { OrganizationMember, useGetOrganizationMembersList } from "@api";
import { Loading } from "@pairtreefamily/ui";
import {
  useGetPendingInvites,
  useResendOrganizationInvite,
  useRevokeInvite,
} from "@api";
import { useSnackbarNotification } from "@pairtreefamily/ui";
import { useTranslation } from "next-i18next";

export const Members = () => {
  const [selectedMember, setSelectedMember] =
    useState<OrganizationMember | null>(null);
  const { data } = useGetPendingInvites();
  const revokeInvite = useRevokeInvite();
  const resendInvite = useResendOrganizationInvite();
  const { callSnackbar } = useSnackbarNotification();
  const { t } = useTranslation("organization");

  const { data: dataMembersList, isLoading } = useGetOrganizationMembersList();

  const handleMemberClicked = useCallback((member: OrganizationMember) => {
    setSelectedMember(member);
  }, []);

  const handleResendInvite = useCallback(
    async (inviteUuid: string) => {
      try {
        await resendInvite({ inviteUuid });

        callSnackbar({
          message: t("membersTab.invitation.notifications.successResend"),
        });
      } catch (error) {
        callSnackbar({
          message: t("membersTab.invitation.notifications.failedResend"),
          type: "error",
        });
      }
    },
    [callSnackbar, resendInvite, t]
  );

  const handleRevokeInvite = useCallback(
    async (inviteUuid: string) => {
      try {
        await revokeInvite({ inviteUuid });
      } catch (error) {
        callSnackbar({
          message: t("membersTab.invitation.notifications.failedRevoke"),
          type: "error",
        });
      }
    },
    [callSnackbar, revokeInvite, t]
  );

  return (
    <ContentWrapper>
      {isLoading && !dataMembersList ? (
        <Loading />
      ) : (
        <Box pb={5}>
          {dataMembersList && dataMembersList.length > 0 && (
            <MembersTable
              onMemberClick={handleMemberClicked}
              members={dataMembersList ?? []}
            />
          )}

          <MemberDetails
            isSidePanelOpen={!!selectedMember}
            onCloseSidePanel={() => setSelectedMember(null)}
            selectedMember={selectedMember}
          />

          <NewInvitation />
          <Fade exit={false} in={!!data?.length} unmountOnExit>
            <div>
              <PendingInvitations
                invitations={data}
                onResend={handleResendInvite}
                onRevoke={handleRevokeInvite}
              />
            </div>
          </Fade>
        </Box>
      )}
    </ContentWrapper>
  );
};
