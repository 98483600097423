export enum AccountGroup {
  Fam = "fam",
  Pro = "pro",
  Bio = "bio",
  Ref = "ref",
}

export enum Zodiac {
  Aries = "Aries",
  Taurus = "Taurus",
  Gemini = "Gemini",
  Cancer = "Cancer",
  Leo = "Leo",
  Virgo = "Virgo",
  Libra = "Libra",
  Scorpio = "Scorpio",
  Sagittarius = "Sagittarius",
  Capricorn = "Capricorn",
  Aquarius = "Aquarius",
  Pisces = "Pisces",
  PreferNotToSay = "Prefer not to say",
}

export enum Religion {
  Christianity = "Christianity",
  Buddhism = "Buddhism",
  Hinduism = "Hinduism",
  Islam = "Islam",
  Judaism = "Judaism",
  Lds = "Church of Jesus Christ of LDS",
  Catholicism = "Catholicism",
  CatholicismChristianity = "Catholicism/Christianity",
  Spiritual = "Spiritual",
  PreferNotToSay = "Prefer not to say",
  None = "None",
  Other = "Other",
}

export enum Education {
  SomeCollege = "Some College",
  MastersDegree = "Master’s Degree",
  PhdOrHigher = "Ph.D. or higher",
  BachelorsDegree = "Bachelor’s Degree",
  SomeHighSchool = "Some High School",
  HighSchool = "High School",
  TradeSchool = "Trade School",
  PreferNotToSay = "Prefer not to say",
}

export enum PoliticalAffiliation {
  VeryConservative = "Very Conservative",
  LeanConservative = "Lean Conservative",
  LeanRight = "Lean Right",
  Independent = "Independent",
  LeanLeft = "Lean Left",
  LeanLiberal = "Lean Liberal",
  VeryLiberal = "Very Liberal",
  NotPolitical = "Not Political",
  PreferNotToSay = "Prefer not to say",
}

export enum RaceEthnicity {
  HispanicOrLatino = "Hispanic or Latino",
  NativeAmerican = "American Indian or Alaska Native",
  Asian = "Asian",
  NativeHawaiianOrPacificIslander = "Native Hawaiian or Other Pacific Islander",
  PreferNotToSay = "Prefer not to say",
  AfricanAmerican = "Black or African American",
  Caucasian = "Caucasian",
}

export enum OpenTo {
  MultipleChildren = "Multiple children",
  SpecialNeeds = "Disabilities or Special Needs",
  DrugExposure = "Prenatal drug exposure",
}

export type OpenToAdditionalInfo = {
  key: OpenTo;
  additionalInfo: string;
};

export enum AdoptionRelationshipPreference {
  VeryOpen = "Very Open",
  ModeratelyOpen = "Moderately Open",
  Closed = "Closed",
}

export interface SiteUrls {
  personalSite: string;
  instagramUrl: string;
  facebookUrl: string;
  twitterUrl: string;
  tiktokUrl: string;
}
