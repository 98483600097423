import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "next-i18next";

import { Box, Typography } from "@mui/material";
import {
  ProfileBookIcon,
  ProfileLiveIcon,
  ProfileVideoIcon,
} from "@pairtreefamily/ui";
import { ActionCell, NameCell, ProfileLinkIcon } from "../components";

import { FamilyListingField, FamilyListingRow } from "../types";
import { getConnectStatusIcon, getHomeStudyStatusIcon } from "../utils";

const columnHelper = createColumnHelper<FamilyListingRow>();

export const useColumns = (
  searchQuery: string,
  onFamilyNameClick: (slug: string, isPublished: boolean) => void,
  onContactClick: (
    familyUuid: string,
    slug: string,
    isPublished: boolean
  ) => void,
  onUnlink: () => void
) => {
  const { t } = useTranslation("connect");

  return useMemo(
    () => [
      columnHelper.accessor(FamilyListingField.FamName, {
        header: t("familyListing.table.headers.familyName"),
        cell: (cell) => (
          <NameCell
            cell={cell}
            searchQuery={searchQuery}
            onClick={() =>
              onFamilyNameClick(
                cell.row.original.slug,
                cell.row.original[FamilyListingField.ConnectStatus]
              )
            }
          />
        ),
      }),
      columnHelper.accessor(FamilyListingField.State, {
        header: t("familyListing.table.headers.state"),
        cell: (cell) => cell.getValue(),
      }),
      columnHelper.accessor(FamilyListingField.ConnectStatus, {
        header: t("familyListing.table.headers.connectStatus"),
        cell: (cell) => (
          <Box display="flex" alignItems="center" gap={2}>
            {getConnectStatusIcon(cell)}
            <Typography variant="caption2">
              {t(
                `familyListing.table.cells.connectStatus.${
                  cell.getValue() ? "published" : "notPublished"
                }`
              )}
            </Typography>
          </Box>
        ),
      }),
      columnHelper.accessor(FamilyListingField.HomeStudyVerified, {
        header: t("familyListing.table.headers.homeStudy"),
        enableSorting: false,
        cell: (cell) => (
          <Box display="flex" alignItems="center" gap={2}>
            {getHomeStudyStatusIcon(cell)}
            <Typography variant="caption2">
              {t(
                `familyListing.table.cells.homeStudyStatus.${
                  cell.getValue() ? "yes" : "no"
                }`
              )}
            </Typography>
          </Box>
        ),
      }),
      columnHelper.accessor((row: FamilyListingRow) => row, {
        header: t("familyListing.table.headers.profile"),
        enableSorting: false,
        cell: (cell) => {
          const isPublished =
            cell.row.original[FamilyListingField.ConnectStatus];

          const profileLink =
            cell.row.original[FamilyListingField.ConnectProfileLink];
          const profileBook = cell.row.original[FamilyListingField.ProfileBook];
          const videoUrl = cell.row.original[FamilyListingField.VideoUrl];

          const hasNoLinks =
            (!profileLink || !isPublished) && !profileBook && !videoUrl;

          return (
            <>
              {hasNoLinks ? (
                <Box pl={2.8}>-</Box>
              ) : (
                <Box display="flex">
                  {isPublished && (
                    <ProfileLinkIcon
                      url={profileLink}
                      IconComponent={ProfileLiveIcon}
                    />
                  )}

                  <ProfileLinkIcon
                    url={profileBook}
                    IconComponent={ProfileBookIcon}
                  />
                  <ProfileLinkIcon
                    url={videoUrl}
                    IconComponent={ProfileVideoIcon}
                  />
                </Box>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor(FamilyListingField.Actions, {
        header: t("familyListing.table.headers.actions"),
        enableSorting: false,
        cell: (cell) => (
          <ActionCell
            familyUuid={cell.row.original[FamilyListingField.Uuid]}
            familyName={cell.row.original[FamilyListingField.FamName][0]}
            isOrgAssociated={
              cell.row.original[FamilyListingField.IsOrgAssociated]
            }
            onContactClick={() =>
              onContactClick(
                cell.row.original[FamilyListingField.Uuid],
                cell.row.original.slug,
                cell.row.original[FamilyListingField.ConnectStatus]
              )
            }
            familyEmail={cell.row.original[FamilyListingField.Email]}
            onUnlinkSuccess={onUnlink}
          />
        ),
      }),
    ],
    [t, searchQuery, onFamilyNameClick, onUnlink, onContactClick]
  );
};
