import { TemplateFolder } from "@api";
import { useMemo } from "react";

import type { SelectOption } from "@pairtreefamily/ui";

export const useGetTemplateFoldersOptions = (
  templateFolders: TemplateFolder[] | undefined
): SelectOption<string>[] => {
  return useMemo(() => {
    if (!templateFolders) return [];

    const folderOptions = templateFolders.map((i) => {
      return {
        id: i.uuid,
        label: i.name,
      };
    });

    folderOptions.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return folderOptions;
  }, [templateFolders]);
};
