import { FormModal, SelectField, TextField } from "@pairtreefamily/ui";
import { useCallback } from "react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { useGetRoleOptions } from "./hooks";
import { FormValues, validationSchema } from "./validation-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { UserRole, AssignUserToCasePayload } from "@api";

export type AssignCaseUserModalProps = {
  isOpen: boolean;
  onClose: () => void;
  assignUserToCase: (payload: AssignUserToCasePayload) => Promise<boolean>;
};

export function AssignCaseUserModal({
  assignUserToCase,
  isOpen,
  onClose,
}: AssignCaseUserModalProps) {
  const { t } = useTranslation(["admin", "common"]);
  const roleOptions = useGetRoleOptions();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      role: UserRole.Family,
      email: "",
      firstName: "",
      lastName: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const role = formMethods.watch("role");

  const handleModalClose = useCallback(() => {
    formMethods.reset();
    onClose();
  }, [formMethods, onClose]);

  const handleSubmit = useCallback(async () => {
    await assignUserToCase(formMethods.getValues());
    handleModalClose();
  }, [assignUserToCase, formMethods, handleModalClose]);

  return (
    <FormModal<FormValues>
      formMethods={formMethods}
      onSubmit={handleSubmit}
      submitLabel={t("common:actions.submit")}
      title={t("admin:assignCaseUserModal.title")}
      open={isOpen}
      onClose={handleModalClose}
    >
      <SelectField
        label={t("admin:assignCaseUserModal.fields.role.label")}
        name="role"
        options={roleOptions}
        placeholder={t("admin:assignCaseUserModal.fields.role.placeholder")}
      />

      <TextField
        name="email"
        label={t("admin:assignCaseUserModal.fields.email")}
      />

      {role === UserRole.Family && (
        <>
          <TextField
            name="firstName"
            label={t("admin:assignCaseUserModal.fields.firstName")}
          />
          <TextField
            name="lastName"
            label={t("admin:assignCaseUserModal.fields.lastName")}
          />
        </>
      )}
    </FormModal>
  );
}
