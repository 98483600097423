import {
  useCreateReferenceTask,
  useDeleteTask,
  useGetTaskGroups,
  useInvalidateTaskList,
  useIssueDocumentForTask,
  useSendReferenceRequest,
  useUpdateTaskGroupOrder,
  useUpdateTasksOrder,
  useUpsertElectronicDocumentTask,
  useUpsertFileUploadTask,
  useUpsertTaskGroup,
  useGetFileUrl,
  useUpdateTaskQuestionAnswer,
  useGetFileData,
  useDeleteDocument,
  useUploadDocument,
  useGetCaseProviders,
  useInvalidateGetProviderCases,
  useGetCaseCompletion,
  useGetCaseLoginUsers,
  useInvalidateGetCaseCompletion,
  useCompleteCase,
  useUpsertExternalSignerTask,
  useRefreshTask,
  useChangeCaseStatus,
  useUpdateCaseNote,
} from "@api";
import { Task } from "@_types/*";
import { useCallback, useMemo } from "react";
import { useTranslation } from "next-i18next";
import { useRefreshElectronicDocumentSession } from "src/api/trpc/electronicDocuments/refreshSession";

export type UseProfessionalPageArgs = {
  caseId: string;
};

export type UseProfessionalPageTools = ReturnType<typeof useProfessionalTools>;

export const useProfessionalTools = ({ caseId }: UseProfessionalPageArgs) => {
  const { t } = useTranslation("professionalCase");

  const invalidateTaskList = useInvalidateTaskList({ caseId });
  const invalidateProviderCases = useInvalidateGetProviderCases();
  const invalidateGetCaseCompletion = useInvalidateGetCaseCompletion();

  const { data: taskGroups, error: taskGroupsError } = useGetTaskGroups({
    caseId,
  });

  const issueDocument = useIssueDocumentForTask({
    caseId,
    onSuccess: invalidateTaskList,
  });

  const sendReferenceRequest = useSendReferenceRequest({
    caseId,
    onSuccess: invalidateTaskList,
  });

  const createReferenceTask = useCreateReferenceTask({
    caseId,
    onSuccess: invalidateTaskList,
  });

  const _deleteTask = useDeleteTask({ caseId, onSuccess: invalidateTaskList });
  const deleteTask = useCallback(
    async (task: Task) => {
      if (confirm(t("actions.deleteTaskConfirm", { taskTitle: task.title }))) {
        return _deleteTask(task);
      }
    },
    [_deleteTask, t]
  );

  const updateTaskOrder = useUpdateTasksOrder({
    onSuccess: invalidateTaskList,
  });

  const updateTaskGroupOrder = useUpdateTaskGroupOrder({
    onSuccess: invalidateTaskList,
  });

  const upsertElectronicDocumentTask = useUpsertElectronicDocumentTask({
    onSuccess: invalidateTaskList,
  });

  const upsertFileUploadTask = useUpsertFileUploadTask({
    onSuccess: invalidateTaskList,
  });

  const getCaseCompletion = useGetCaseCompletion({ caseId });

  const { completeCase, completeCaseLoading } = useCompleteCase({
    caseId,
    onSuccess: async () => {
      await invalidateProviderCases();
      await invalidateGetCaseCompletion({ caseId });
    },
  });

  const { changeCaseStatus, changeStatusLoading } = useChangeCaseStatus({
    caseId,
    onSuccess: async () => {
      await invalidateProviderCases();
    },
  });

  const upsertTaskGroup = useUpsertTaskGroup({
    caseId,
    onSuccess: invalidateTaskList,
  });

  const getFileUrl = useGetFileUrl();

  const updateTaskQuestionAnswer = useUpdateTaskQuestionAnswer({
    onSuccess: invalidateTaskList,
  });

  const getFileData = useGetFileData();

  const uploadDocument = useUploadDocument({
    onSuccess: invalidateTaskList,
  });

  const deleteDocument = useDeleteDocument({ onSuccess: invalidateTaskList });

  const caseProviders = useGetCaseProviders({
    caseId,
    onlyProvidersWithContactInfo: true,
  });

  const { data: caseLoginUsersData } = useGetCaseLoginUsers({ caseId });
  const caseLoginUsers = useMemo(
    () => caseLoginUsersData?.content ?? [],
    [caseLoginUsersData]
  );

  const upsertExternalSignerTask = useUpsertExternalSignerTask({
    onSuccess: invalidateTaskList,
  });

  const refreshTask = useRefreshTask({
    caseId,
    onSuccess: invalidateTaskList,
  });

  const updateCaseNote = useUpdateCaseNote({ caseId });

  const refreshElectronicDocumentSession = useRefreshElectronicDocumentSession({
    caseId: caseId,
    onSuccess: invalidateTaskList,
  });

  return {
    taskGroups,
    taskGroupsError,
    issueDocument,
    sendReferenceRequest,
    deleteTask,
    createReferenceTask,
    updateTaskOrder,
    updateTaskGroupOrder,
    upsertElectronicDocumentTask,
    upsertFileUploadTask,
    completeCase,
    completeCaseLoading,
    changeStatusLoading,
    getCaseCompletion,
    upsertTaskGroup,
    getFileUrl,
    updateTaskQuestionAnswer,
    getFileData,
    uploadDocument,
    deleteDocument,
    caseProviders,
    caseLoginUsers,
    upsertExternalSignerTask,
    refreshTask,
    changeCaseStatus,
    updateCaseNote,
    refreshElectronicDocumentSession,
  };
};
