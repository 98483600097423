export const styles = {
  wrapper: {
    display: "flex",
    gap: 2,
    alignItems: "center",
  },
  form: { flexGrow: 1 },
  textArea: { minHeight: "48px" },
  iconButton: {
    p: 0.5,
    transition: "color 0.2s",

    "&:disabled": {
      color: "text.disabled",
    },

    color: "primary.main",

    "&:hover": {
      color: "primary.light",
    },

    "&:active": {
      color: "primary.dark",
    },
  },
};
