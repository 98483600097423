import { useMemo } from "react";
import { formatParentFullName } from "../utils";

import { appConfig } from "@lib";
import type { FamilyListItem } from "@api";
import type { FamilyListingRow } from "../types";

export const useTableData = (data?: FamilyListItem[]): FamilyListingRow[] => {
  return useMemo(() => {
    if (!data) return [];

    return data.map((fam) => {
      const famName = fam.adoptiveParents.map(formatParentFullName);
      const connectProfileLink = `${appConfig.pairTreeFamilySiteUrl}/families/${fam.slug}`;
      return {
        famName,
        connectProfileLink,
        slug: fam.slug,
        state: fam.state,
        connectStatus: fam.isPublished,
        homeStudyVerified: fam.homeStudyVerified,
        profileBook: fam.profileBook?.mediaUrl,
        videoUrl: fam.videoUrl,
        uuid: fam.uuid,
        isOrgAssociated: fam.orgMemberships?.length > 0,
        email: fam.email,
      };
    });
  }, [data]);
};
