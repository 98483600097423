import { Box, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";

import { Switch, SwitchProps } from "@pairtreefamily/ui";
import { styles } from "./styles";

type ViewToggleProps = Pick<SwitchProps, "checked" | "onChange">;

export const OrganizationFamiliesViewToggle = (props: ViewToggleProps) => {
  const { checked, onChange } = props;

  const { t } = useTranslation("connect");

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="body4">{t("viewToggle.label")}</Typography>

      <Switch
        checked={checked}
        labelPlacement="start"
        label={t(`viewToggle.values.${checked ? "on" : "off"}`)}
        onChange={onChange}
      />
    </Box>
  );
};
