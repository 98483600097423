import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

export type MemberTable = {
  id: string;
  name: string;
  email: string;
  position?: string;
  phone: string;
};

const columnHelper = createColumnHelper<MemberTable>();

export const useColumns = () => {
  const { t } = useTranslation("organization");

  return [
    columnHelper.accessor("name", {
      header: t("membersTab.table.name"),
      cell: (cell) => <>{cell.getValue() || "-"}</>,
      maxSize: 300,
    }),
    columnHelper.accessor("email", {
      header: t("membersTab.table.email"),
      cell: (cell) => <>{cell.getValue() || "-"}</>,
      maxSize: 300,
    }),
    columnHelper.accessor("position", {
      header: t("membersTab.table.position"),
      cell: (cell) => <>{cell.getValue() || "-"}</>,
      maxSize: 300,
    }),
    columnHelper.accessor("phone", {
      header: t("membersTab.table.phone"),
      cell: (cell) => <>{cell.getValue() || "-"}</>,
      size: 200,
    }),
  ];
};
