import { useTranslation } from "next-i18next";

import { Loading, SidePanel } from "@pairtreefamily/ui";
import { Box, Divider } from "@mui/material";
import {
  About,
  AdoptionPreferences,
  Heading,
  ParentList,
  ProfileLinks,
} from "./components";

import { appConfig } from "@lib";
import { useGetFamilyProfileDetails } from "@api";

import type { FamilyDetailsSidePanelProps } from "./types";
import { useGetFullFamilyName } from "../../hooks";
import { useMemo } from "react";
import { OpenTo, OpenToAdditionalInfo } from "@_types/*";
import { useOrganizationsData } from "@shared/components";

export const FamilyDetailsSidePanel = ({
  slug,
  isOpen,
  onClose,
  isPublished,
  onContactClick,
}: FamilyDetailsSidePanelProps) => {
  const { t } = useTranslation("connect");
  const { userOrganizationData } = useOrganizationsData();
  // TODO would be better to avoid coercing these values while also keeping
  // them non-nullable at the API boundary
  const { data, isLoading } = useGetFamilyProfileDetails({
    slug: slug ?? "",
    organizationUuid: userOrganizationData?.id ?? "",
  });

  const familyFullName = useGetFullFamilyName(data);

  const slugUrl = isPublished
    ? `${appConfig.pairTreeFamilySiteUrl}/families/${data?.slug}`
    : undefined;

  const openToAdditionalInfo: OpenToAdditionalInfo[] = useMemo(() => {
    let additionalInfo = [];
    if (data?.profilePreferences.specialNeedsAdditionalInfo) {
      additionalInfo.push({
        key: OpenTo.SpecialNeeds,
        additionalInfo: data.profilePreferences.specialNeedsAdditionalInfo,
      });
    }
    if (data?.profilePreferences.drugExposureAdditionalInfo) {
      additionalInfo.push({
        key: OpenTo.DrugExposure,
        additionalInfo: data.profilePreferences.drugExposureAdditionalInfo,
      });
    }
    return additionalInfo;
  }, [data]);

  return (
    <SidePanel
      open={isOpen}
      title={t("familyListing.familyDetails.title")}
      onClose={onClose}
    >
      {isLoading && <Loading />}

      {data && (
        <Box display="flex" flexDirection="column" gap={2} pb={4.5}>
          <Heading
            familyName={familyFullName}
            bannerPhoto={data.bannerPhoto?.mediaUrl}
            isVerified={data.homeStudyVerified}
          />

          <Divider />

          <ProfileLinks
            profileVideo={data.videoUrl}
            profileBook={data.profileBook?.mediaUrl}
            onlineProfile={slugUrl}
            onContactClick={() => onContactClick(data.uuid, slug, isPublished)}
          />

          <Divider />

          <AdoptionPreferences
            raceEthnicity={data.raceEthnicity}
            openTo={data.openTo}
            availableBudget={data.adoptionFeeMaxBudget}
            budgetAvailableWithinWindow={data.adoptionFeeAvailable}
            levelOfOpenness={
              data.profilePreferences.adoptionRelationshipPreference
            }
            openToAdditionalInfo={openToAdditionalInfo}
          />

          <Divider />

          <About
            adoptionParentsAmount={data.adoptiveParents.length}
            adoptionProfessionals={data.partnerProfessionals}
            isProPairTreePartner={
              Boolean(data.partnerProfessionals.length) &&
              Boolean(data.orgMemberships.length)
            }
            familyStructure={data.familyStructure}
            state={data.state}
          />

          <ParentList list={data.adoptiveParents} />
        </Box>
      )}
    </SidePanel>
  );
};
