import { FamilyListTable } from "../family-list-table/FamilyListTable";
import { appConfig } from "@lib";
import {
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useTranslation } from "next-i18next";
import { useMemo, useState } from "react";
import { DashboardField, DashboardRow } from "../../types";
import { useGetProviderDashboardCases } from "@api";
import { Loading } from "@pairtreefamily/ui";
import { useColumns } from "./useColumns";
import { Typography } from "@mui/material";
import { SearchBox } from "@shared/components";

export const FamiliesListTab = (props: { showInactive: boolean }) => {
  const { t } = useTranslation("professional");
  const [searchQuery, setSearchQuery] = useState("");
  const columns = useColumns(searchQuery);
  const { data: caseData, isLoading: isCaseDataLoading } =
    useGetProviderDashboardCases(!props.showInactive);

  const [sorting, setSorting] = useState<SortingState>([
    { desc: false, id: DashboardField.FamilyName },
  ]);

  const data = useMemo(() => {
    return caseData?.ok ? caseData.content : [];
  }, [caseData]) as DashboardRow[];

  const filteredData = useMemo(() => {
    if (!searchQuery) return data;

    return data.filter((task) => {
      const fullName = `${task.familyName[0]} ${task.familyName[1]}`;

      return fullName.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [data, searchQuery]);

  const table = useReactTable({
    data: filteredData,
    state: {
      sorting,
    },
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    enableSortingRemoval: false,
  });

  if (!caseData?.ok && !isCaseDataLoading) {
    return (
      <p>
        {t("errors.caseFetchIssue", {
          email: appConfig.supportContactEmail,
        })}
      </p>
    );
  }

  if (isCaseDataLoading) {
    return <Loading />;
  }

  return (
    <>
      <SearchBox
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        placeholder={t("dashboardTable.searchPlaceholder")}
        maxWidth={320}
        mb={3}
      />

      <div className="w-full max-w-full overflow-scroll">
        <FamilyListTable table={table} />
      </div>

      {!table.getRowModel().rows.length && (
        <Typography ml={2} mt={5} display="block" variant="body4">
          {t("errors.emptyFamilies")}
          <a href={`mailto:${appConfig.companyContactEmail}`}>
            {appConfig.companyContactEmail}
          </a>
        </Typography>
      )}
    </>
  );
};
