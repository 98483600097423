import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Loading, useAuthStatus } from "@pairtreefamily/ui";
import { useGetAuthUserData, useGetProviderCases, UserRole } from "@api";
import React, { useEffect, useMemo } from "react";
import { ProfessionalPageView } from "./ProfessionalPageView";
import { UnauthorizedMessage } from "@shared/components";
import { useGetCaseId } from "./hooks/useGetCaseId";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";
import { Routes } from "@features/routing";

export const ProfessionalPage = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { t: professionalCase } = useTranslation("professionalCase");

  useEffect(() => {
    z.setErrorMap(makeZodI18nMap({ t }));
  }, [t]);

  const { authedUser, isLoading: isAuthStatusLoading } = useAuthStatus();
  const { data: userData, isLoading: isUserDataLoading } = useGetAuthUserData();
  const { data: caseData, isLoading: isCaseDataLoading } =
    useGetProviderCases();
  const caseId = useGetCaseId();

  const role = useMemo(() => {
    if (!userData || !userData.ok) {
      return null;
    }

    return userData.content.role;
  }, [userData]);

  const currentCase = useMemo(() => {
    if (!caseData || !caseData.ok || !caseData.content) {
      return null;
    }

    return caseData.content.find((c) => c.id === caseId) ?? null;
  }, [caseData, caseId]);

  useEffect(() => {
    if (!caseId) {
      router.replace(Routes.ProfessionalDashboard);
    }

    if (
      (caseData && caseData.ok && !currentCase) ||
      (currentCase?.hidden && role === UserRole.Provider)
    ) {
      router.replace({
        pathname: Routes.NotFound,
        query: {
          redirectLink: Routes.ProfessionalDashboard,
          text: professionalCase("returnDashboard"),
        },
      });
    }
  }, [
    caseData,
    caseId,
    currentCase,
    currentCase?.hidden,
    role,
    router,
    userData,
    t,
    professionalCase,
  ]);

  if (isAuthStatusLoading || isUserDataLoading || isCaseDataLoading) {
    return <Loading />;
  }

  if (!authedUser?.email || !userData || !userData.ok) {
    return <UnauthorizedMessage />;
  }

  if (!caseData?.ok) {
    return <p>{professionalCase("errors.emptyСases")}</p>;
  }

  if (!currentCase || (currentCase.hidden && role === UserRole.Provider)) {
    return null;
  }

  return (
    <ProfessionalPageView
      isAdmin={role === UserRole.Admin}
      currentCase={currentCase}
    />
  );
};
