import * as z from "zod";
import { requiredString } from "@shared/validation-schemas";

export const validationSchemaNewPerson = z.object({
  personId: z.string().min(1),
  email: z.string().email(),
  firstName: requiredString,
  lastName: requiredString,
  role: z.string(),
});

export const validationSchemaExistentPerson = z.object({
  personId: z.string().min(1),
  email: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  role: z.string(),
});

export type FormValues = z.infer<
  typeof validationSchemaNewPerson | typeof validationSchemaExistentPerson
>;
