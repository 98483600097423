import { useTranslation } from "next-i18next";

type AssigneesAmountProps = {
  currentAssigneesAmount: number;
  requiredAssigneesAmount: number;
};

export const AssigneesAmount = ({
  requiredAssigneesAmount,
  currentAssigneesAmount,
}: AssigneesAmountProps) => {
  const { t } = useTranslation("admin");

  return (
    <div className="text-left">
      {currentAssigneesAmount > 0 && (
        <p>
          {t("taskAssignmentModal.assignees.current", {
            amount: currentAssigneesAmount,
          })}
        </p>
      )}

      {requiredAssigneesAmount > 0 && (
        <p>
          {t("taskAssignmentModal.assignees.required", {
            amount: requiredAssigneesAmount,
          })}
        </p>
      )}
    </div>
  );
};
