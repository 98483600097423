import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import { PoliticalAffiliation } from "@_types/*";

export const usePoliticalAffiliation = () => {
  const { t } = useTranslation("connect");

  return useMemo(
    () => [
      {
        id: PoliticalAffiliation.VeryConservative,
        label: t(
          "familyListing.filters.fields.politicalAffiliation.options.veryConservative"
        ),
      },
      {
        id: PoliticalAffiliation.LeanConservative,
        label: t(
          "familyListing.filters.fields.politicalAffiliation.options.leanConservative"
        ),
      },
      {
        id: PoliticalAffiliation.LeanRight,
        label: t(
          "familyListing.filters.fields.politicalAffiliation.options.leanRight"
        ),
      },
      {
        id: PoliticalAffiliation.Independent,
        label: t(
          "familyListing.filters.fields.politicalAffiliation.options.independent"
        ),
      },
      {
        id: PoliticalAffiliation.LeanLeft,
        label: t(
          "familyListing.filters.fields.politicalAffiliation.options.leanLeft"
        ),
      },
      {
        id: PoliticalAffiliation.LeanLiberal,
        label: t(
          "familyListing.filters.fields.politicalAffiliation.options.leanLiberal"
        ),
      },
      {
        id: PoliticalAffiliation.VeryLiberal,
        label: t(
          "familyListing.filters.fields.politicalAffiliation.options.veryLiberal"
        ),
      },
      {
        id: PoliticalAffiliation.NotPolitical,
        label: t(
          "familyListing.filters.fields.politicalAffiliation.options.notPolitical"
        ),
      },
      {
        id: PoliticalAffiliation.PreferNotToSay,
        label: t(
          "familyListing.filters.fields.politicalAffiliation.options.preferNotToSay"
        ),
      },
    ],
    [t]
  );
};
