import { CellContext } from "@tanstack/react-table";

import { HighlightedString } from "@shared/components";
import { Box, Typography } from "@mui/material";
import { styles } from "./styles";

import type { FamilyListingRow } from "../../types";

type FamilyNameCellProps = {
  cell: CellContext<FamilyListingRow, string[]>;
  searchQuery: string;
  onClick: () => void;
};

export const NameCell = ({
  cell,
  searchQuery,
  onClick,
}: FamilyNameCellProps) => {
  const [firstParentFullName, secondParentFullName] = cell.getValue();

  return (
    <Box sx={styles.wrapper} onClick={onClick}>
      <Typography variant="body4" color="text.secondary">
        <HighlightedString
          value={firstParentFullName}
          searchQuery={searchQuery}
        />
      </Typography>

      {secondParentFullName && (
        <Typography component="div" variant="body6" color="text.light">
          <HighlightedString
            value={secondParentFullName}
            searchQuery={searchQuery}
          />
        </Typography>
      )}
    </Box>
  );
};
