import { appConfig } from "@lib";
import { Box, Typography } from "@mui/material";
import { Button } from "@pairtreefamily/ui";
import { useTranslation } from "react-i18next";

export type StripeProps = {
  customerEmail: string | null;
};

export function StripeBilling(props: StripeProps) {
  const { customerEmail } = props;

  const { t } = useTranslation("billing");

  const emailParam = customerEmail ? `?prefilled_email=${customerEmail}` : "";
  // TODO inject these values
  const servicesLink = appConfig.stripe.servicesUrl;
  const managementPortalLink = appConfig.stripe.managementPortalUrl;

  const servicesButtonLabel = t("buttons.serviceCatalogueLabel");
  const portalButtonLabel = t("buttons.managementPortalLabel");

  const tipHeader = t("tipHeader");
  const tipBody = t("tipBody");

  const products: {
    title: string;
    description: string;
    billingCycle?: string;
    price: string;
  }[] = [
    {
      title: t("products.connect.title"),
      description: t("products.connect.description"),
      billingCycle: t("products.connect.billingCycle"),
      price: t("products.connect.price"),
    },
    {
      title: t("products.profileVideo.title"),
      description: t("products.profileVideo.description"),
      price: t("products.profileVideo.price"),
    },
    {
      title: t("products.profileBook.title"),
      description: t("products.profileBook.description"),
      price: t("products.profileBook.price"),
    },
    {
      title: t("products.profileBookConsultation.title"),
      description: t("products.profileBookConsultation.description"),
      price: t("products.profileBookConsultation.price"),
    },
  ];

  return (
    <Box
      height="100%"
      p={4}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
      sx={{ backgroundColor: "#f5f5f4", color: "text.primary" }}
    >
      <Typography textAlign="center" variant="head2" mt="24px" mb="48px">
        {t("header")}
      </Typography>

      <Box
        display="flex"
        flexWrap="wrap"
        flexDirection="row"
        gap={1.75}
        justifyContent="center"
      >
        {products.map((p, i) => (
          <Box
            key={i}
            display="flex"
            flexDirection="column"
            border={1}
            borderColor="#E7E5E4"
            borderRadius="12px"
            width="282px"
            alignItems="center"
            padding={2}
            px={3}
            sx={{ backgroundColor: "#fff" }}
          >
            <Typography
              variant="head3"
              color="secondary.main"
              textAlign="center"
              maxWidth={200}
              mb="13px"
            >
              {p.title}
            </Typography>
            <Typography variant="body6" textAlign="center" mb="13px">
              {p.description}
            </Typography>
            <Typography variant="body3" color="text.secondary">
              {p.price}
            </Typography>
            {p.billingCycle ? (
              <Typography variant="body9" color="text.secondary">
                <i>{p.billingCycle}</i>
              </Typography>
            ) : null}
          </Box>
        ))}
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="736px"
        mt="13px"
      >
        <Typography color="secondary.main" variant="head6" textAlign="center">
          {tipHeader}
        </Typography>
        <Typography variant="body2" textAlign="center">
          {tipBody}
        </Typography>
      </Box>

      <Box display="flex" mt="24px" gap={4}>
        <Button
          variant="outlined"
          href={`${managementPortalLink}${emailParam}`}
          sx={{
            backgroundColor: "#fff",
            width: "228px",
          }}
        >
          {portalButtonLabel}
        </Button>

        <Button sx={{ width: "228px" }} href={`${servicesLink}${emailParam}`}>
          {servicesButtonLabel}
        </Button>
      </Box>
    </Box>
  );
}
