import { Box, Typography } from "@mui/material";
import {
  AuthorPostDetails,
  AuthorPostDetailsProps,
} from "../../../author-post-details";
import { TruncableText } from "@shared/components";
import { memo } from "react";

type CommentViewProps = {
  text: string;
} & AuthorPostDetailsProps;

export const CommentView = memo(({ text, ...rest }: CommentViewProps) => {
  return (
    <Box py={3}>
      <AuthorPostDetails {...rest} />

      <Typography component="div" mt={2} variant="body6">
        <TruncableText maxLines={4}>{text}</TruncableText>
      </Typography>
    </Box>
  );
});

CommentView.displayName = "CommentView";
