import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import { Religion } from "@_types/*";

export const useReligionOptions = () => {
  const { t } = useTranslation("connect");

  return useMemo(
    () => [
      {
        id: Religion.Christianity,
        label: t("familyListing.filters.fields.religion.options.christianity"),
      },
      {
        id: Religion.CatholicismChristianity,
        label: t(
          "familyListing.filters.fields.religion.options.catholicismChristianity"
        ),
      },
      {
        id: Religion.Buddhism,
        label: t("familyListing.filters.fields.religion.options.buddhism"),
      },
      {
        id: Religion.Hinduism,
        label: t("familyListing.filters.fields.religion.options.hinduism"),
      },
      {
        id: Religion.Islam,
        label: t("familyListing.filters.fields.religion.options.islam"),
      },
      {
        id: Religion.Judaism,
        label: t("familyListing.filters.fields.religion.options.judaism"),
      },
      {
        id: Religion.Lds,
        label: t("familyListing.filters.fields.religion.options.lds"),
      },
      {
        id: Religion.Spiritual,
        label: t("familyListing.filters.fields.religion.options.spiritual"),
      },
      {
        id: Religion.Other,
        label: t("familyListing.filters.fields.religion.options.other"),
      },
      {
        id: Religion.None,
        label: t("familyListing.filters.fields.religion.options.none"),
      },
      {
        id: Religion.PreferNotToSay,
        label: t(
          "familyListing.filters.fields.religion.options.preferNotToSay"
        ),
      },
    ],
    [t]
  );
};
