import { styles } from "../details/styles";
import { Box, IconButton, Typography } from "@mui/material";
import { Avatar } from "../../../avatar/Avatar";
import { getFullName } from "@pairtreefamily/utils";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useSnackbarNotification } from "@pairtreefamily/ui";
import { useTranslation } from "next-i18next";
import { memo, useCallback } from "react";
import { formatDateToView } from "@shared/utils";

export type AuthorPostDetailsProps = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  avatar: string | null;
  orgName: string;
  lastUpdated: string;
};

export const AuthorPostDetails = memo(
  ({
    emailAddress,
    avatar,
    firstName,
    lastName,
    lastUpdated,
    orgName,
  }: AuthorPostDetailsProps) => {
    const { callSnackbar } = useSnackbarNotification();
    const { t } = useTranslation("safetyCenter");

    const handleCopyEmail = useCallback(() => {
      navigator.clipboard.writeText(emailAddress);

      callSnackbar({
        duration: 3000,
        message: t("threadDetails.emailCopied", {
          email: emailAddress,
        }),
      });
    }, [callSnackbar, t, emailAddress]);

    return (
      <Box sx={styles.detailsWrapper}>
        <Box sx={styles.authorInfoWrapper}>
          <Avatar
            firstName={firstName}
            lastName={lastName}
            url={avatar || ""}
          />

          <Box>
            <Box sx={styles.authorNameWrapper}>
              <Typography variant="body9">
                {getFullName(firstName, lastName)}
              </Typography>

              <IconButton
                size="small"
                onClick={handleCopyEmail}
                sx={styles.emailIconButton}
              >
                <EmailOutlinedIcon />
              </IconButton>
            </Box>

            <Box mt={0.25}>
              <Typography variant="body6" color="text.light">
                {t("fromOrg", { orgName })}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Typography flex="0 0 auto" variant="body6" color="text.light">
          {formatDateToView(new Date(lastUpdated))}
        </Typography>
      </Box>
    );
  }
);

AuthorPostDetails.displayName = "AuthorPostDetails";
