import { SelectField, SelectOption, TextField } from "@pairtreefamily/ui";
import { useTranslation } from "next-i18next";
import { RolesOption } from "../types";
import { FormValues } from "../validation-schema";
import { UseFormReturn } from "react-hook-form";
import { newPersonId } from "../constants";
import { useMemo } from "react";

export type FormProps = {
  formMethods: UseFormReturn<FormValues>;
  personOptions: SelectOption[];
  rolesOptions?: RolesOption[] | null;
  hasRoleInputField?: boolean;
};

export const TaskAssignmentForm = ({
  formMethods,
  personOptions,
  rolesOptions,
  hasRoleInputField,
}: FormProps) => {
  const { t } = useTranslation(["admin", "common"]);

  const personId = formMethods.watch("personId");

  const filteredRolesOptions = useMemo(() => {
    return rolesOptions?.filter((item) => item.label.length) ?? [];
  }, [rolesOptions]);

  return (
    <>
      <SelectField
        name="personId"
        options={personOptions}
        label={t("admin:taskAssignmentModal.fields.personId.placeholder")}
      />

      {personId === newPersonId && (
        <>
          <TextField
            name="email"
            label={t("admin:taskAssignmentModal.fields.email.label")}
          />

          <TextField
            name="firstName"
            label={t("admin:taskAssignmentModal.fields.firstName.label")}
          />

          <TextField
            name="lastName"
            label={t("admin:taskAssignmentModal.fields.lastName.label")}
          />
        </>
      )}

      {hasRoleInputField && (
        <SelectField
          options={filteredRolesOptions}
          name="role"
          label={t("admin:taskAssignmentModal.fields.role.placeholder")}
        />
      )}
    </>
  );
};
