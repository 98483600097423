import { useTranslation } from "next-i18next";
import { ContentWrapper } from "@shared/components";
import { Box, Typography } from "@mui/material";
import { palette } from "@pairtreefamily/ui";

export function Header() {
  const { t } = useTranslation("professional");

  return (
    <ContentWrapper background={palette.background.light50}>
      <Box pt={4} pb={2}>
        <Typography variant="head4" component="h4">
          {t("header.caption")}
        </Typography>

        <Typography
          whiteSpace="pre-line"
          mt="10px"
          variant="input"
          component="div"
        >
          {t("header.description")}
        </Typography>
      </Box>
    </ContentWrapper>
  );
}
