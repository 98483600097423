import { useTranslation } from "next-i18next";

import { Box, Typography } from "@mui/material";
import { styles } from "./styles";
import { HomeBaseVerified } from "@pairtreefamily/ui";

export type HeadingProps = {
  bannerPhoto?: string;
  familyName: string;
  isVerified: boolean;
};

export const Heading = ({
  bannerPhoto,
  familyName,
  isVerified,
}: HeadingProps) => {
  const { t } = useTranslation("connect");

  return (
    <Box sx={styles.wrapper}>
      <Box
        component="img"
        src={bannerPhoto || "/img-placeholder.jpg"}
        alt={familyName}
        sx={styles.bannerPhoto}
      />

      <Box sx={styles.infoSide}>
        <Typography component="h3" variant="head3" sx={styles.familyNameLabel}>
          {familyName}
        </Typography>

        {isVerified && (
          <Box sx={styles.verifiedSection}>
            <HomeBaseVerified style={styles.verifiedIcon} />

            <Typography
              component="span"
              variant="caption2"
              sx={styles.verifiedLabel}
            >
              {t("familyListing.familyDetails.homeStudyApproved")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
