export const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  headingWrapper: {
    pt: 4,
    pb: 2,
  },
  description: {
    mt: "10px",
    display: "flex",
    gap: 4,
    justifyContent: "space-between",
    whiteSpace: "pre-line",
  },
};
