import { memo } from "react";
import { DragAndDrop, Handle, SortableItem } from "@shared/components";
import { AdminTaskGroup } from "./AdminTaskGroup";
import { AdminTools } from "../hooks/useAdminTools";

import type {
  ElectronicDocumentTask,
  ExtendedTaskGroup,
  Task,
  TaskGroup,
  CaseLoginUser,
} from "@_types/*";
import type { TasksWithTemplateRoles } from "../types";

export type AdminTaskGroupListProps = {
  taskGroups: ExtendedTaskGroup[];
  caseId: string;
  setTaskAssignment: AdminTools["setTaskAssignment"];
  taskAssignmentStatus: AdminTools["taskAssignmentStatus"];
  caseParticipants: AdminTools["caseParticipants"];
  deleteTaskAssignment: AdminTools["deleteTaskAssignment"];
  deleteTask: AdminTools["deleteTask"];
  issueDocument: AdminTools["issueDocument"];
  onTaskReorder: (
    newOrder: string[],
    group: TaskGroup | ExtendedTaskGroup
  ) => void;
  onTaskGroupReorder: (newOrder: string[]) => void;
  onEditTask: (task: Task) => void;
  deleteTaskGroup: AdminTools["deleteTaskGroup"];
  onEditTaskGroup: (taskGroup: TaskGroup) => void;
  onAddTask: (taskGroup: TaskGroup) => void;
  upsertTaskGroup: AdminTools["upsertTaskGroup"];
  getRequiredRoles: (task: ElectronicDocumentTask[]) => void;
  tasksRoles: TasksWithTemplateRoles[];
  isLoadingRoles: boolean;
  caseLoginUsers: CaseLoginUser[];
  refreshTask: AdminTools["refreshTask"];
  refreshElectronicDocumentSession: AdminTools["refreshElectronicDocumentSession"];
};

export const AdminTaskGroupList = memo(
  ({
    taskGroups,
    onTaskGroupReorder,
    setTaskAssignment,
    onTaskReorder,
    taskAssignmentStatus,
    caseParticipants,
    deleteTaskAssignment,
    deleteTask,
    issueDocument,
    onEditTask,
    deleteTaskGroup,
    onAddTask,
    upsertTaskGroup,
    getRequiredRoles,
    tasksRoles,
    isLoadingRoles,
    onEditTaskGroup,
    caseId,
    caseLoginUsers,
    refreshTask,
    refreshElectronicDocumentSession,
  }: AdminTaskGroupListProps) => {
    return (
      <div>
        <DragAndDrop
          className="text-body-3 even:bg-backgroundgray"
          onSort={(newOrder) => onTaskGroupReorder(newOrder)}
          itemsList={taskGroups.map((taskGroup: ExtendedTaskGroup) => ({
            id: taskGroup.id,
            element: (
              <SortableItem key={taskGroup.id} id={taskGroup.id}>
                {(sortableItemProps) => (
                  <div
                    className="text-body-3 group/parent flex w-full cursor-default"
                    ref={sortableItemProps.setNodeRef}
                    style={sortableItemProps.style}
                    {...sortableItemProps.attributes}
                  >
                    {/* dnd handle */}
                    <span className="flex w-[60px]">
                      <button
                        {...sortableItemProps.listeners}
                        className="m-auto hidden rounded-[12px] border-[1px] border-gray p-2 text-gray group-hover/parent:block"
                      >
                        <Handle />
                      </button>
                    </span>

                    <AdminTaskGroup
                      taskGroup={taskGroup}
                      caseId={caseId}
                      setTaskAssignment={setTaskAssignment}
                      onTaskReorder={onTaskReorder}
                      taskAssignmentStatus={taskAssignmentStatus}
                      caseParticipants={caseParticipants}
                      deleteTaskAssignment={deleteTaskAssignment}
                      deleteTask={deleteTask}
                      issueDocument={issueDocument}
                      onEditTask={onEditTask}
                      deleteTaskGroup={deleteTaskGroup}
                      onEditTaskGroup={() => onEditTaskGroup(taskGroup)}
                      onAddTask={() => onAddTask(taskGroup)}
                      upsertTaskGroup={upsertTaskGroup}
                      getRequiredRoles={getRequiredRoles}
                      tasksRoles={tasksRoles}
                      isLoadingRoles={isLoadingRoles}
                      caseLoginUsers={caseLoginUsers}
                      refreshTask={refreshTask}
                      refreshElectronicDocumentSession={
                        refreshElectronicDocumentSession
                      }
                    />
                  </div>
                )}
              </SortableItem>
            ),
          }))}
        />
      </div>
    );
  }
);

AdminTaskGroupList.displayName = "AdminTaskGroupList";
