import {
  Case,
  CaseLoginUser,
  ElectronicDocumentTask,
  ExtendedTaskGroup,
  FileUploadTask,
  Task,
  TaskGroup,
} from "@_types/*";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "next-i18next";
import { useCase, useReorderTaskGroups, useReorderTasks } from "@shared/hooks";
import { getCompletedAndTotalTaskFromTaskGroups } from "@shared/utils";
import { useGetTemplates } from "@api";
import { getFamilyNameComplete } from "@pairtreefamily/utils";
import {
  BackButton,
  CompleteCaseModal,
  Header,
  ProfessionalPageTabs,
  ProfessionalTabEnum,
  TaskRow,
  MyTasks,
  FamilyTasks,
} from "./components";
import {
  ContentWrapper,
  CreateTaskGroupModal,
  TaskManagementSidePanel,
} from "@shared/components";
import { useCreateUpdateTask } from "./hooks/useCreateUpdateTask";
import { useProfessionalTools } from "./hooks/useProfessionalTools";
import { Modal, palette, useTabs } from "@pairtreefamily/ui";
import { Box, Fade, Typography } from "@mui/material";
import { styles } from "./styles";

type ProfessionalPageViewProps = {
  isAdmin?: boolean; // TODO remove this and handle admin flow separately
  currentCase: Case;
};

export function ProfessionalPageView(props: ProfessionalPageViewProps) {
  const { currentCase } = props;
  const caseId = currentCase.id;

  const { t } = useTranslation("professionalCase");

  const [currentTaskBeingManaged, setCurrentTaskBeingManaged] = useState<
    Task | ElectronicDocumentTask | FileUploadTask
  >();
  const [currentGroupAddingTask, setCurrentGroupAddingTask] =
    useState<TaskGroup>();
  const [isTaskManagerModalOpen, setTaskManagerModalOpen] = useState(false);
  const [isCompleteCaseModalOpen, setCompleteCaseModalOpen] = useState(false);

  const [isCompleteCaseWarningModalOpen, setIsCompleteCaseWarningModalOpen] =
    useState(false);

  const [taskGroupToEdit, setTaskGroupToEdit] = useState<TaskGroup | null>(
    null
  );

  const {
    taskGroups,
    taskGroupsError,
    issueDocument,
    sendReferenceRequest,
    deleteTask,
    createReferenceTask,
    updateTaskOrder,
    updateTaskGroupOrder,
    upsertElectronicDocumentTask,
    upsertFileUploadTask,
    completeCase,
    upsertTaskGroup,
    getFileUrl,
    updateTaskQuestionAnswer,
    getFileData,
    uploadDocument,
    deleteDocument,
    caseProviders,
    caseLoginUsers,
    upsertExternalSignerTask,
    refreshTask,
    changeCaseStatus,
    updateCaseNote,
    changeStatusLoading,
    refreshElectronicDocumentSession,
  } = useProfessionalTools({ caseId });

  const {
    documentHomeLink: famDocumentsLink,
    privateDocumentLink: providerDocumentsLink,
  } = useCase({ caseId, includePrivateDocs: true });

  const reorderTaskGroups = useReorderTaskGroups(
    taskGroups as ExtendedTaskGroup[],
    caseId,
    updateTaskGroupOrder
  );

  // trpc.homeStudy.getTasksForCase returns ExtendedTaskGroup and not TaskGroup. Remove "as" when switched to REST from TRPC
  const reorderTasks = useReorderTasks(
    taskGroups as ExtendedTaskGroup[],
    caseId,
    updateTaskOrder
  );

  const createUpdateTask = useCreateUpdateTask({
    setTaskManagerModalOpen,
    caseId,
    currentGroupAddingTask,
    taskGroups,
    upsertFileUploadTask,
    upsertElectronicDocumentTask,
    upsertExternalSignerTask,
  });

  const familyTaskGroups = (taskGroups as ExtendedTaskGroup[])?.filter(
    (taskGroup) => !taskGroup.providerOnly
  );
  const providerTaskGroups = (taskGroups as ExtendedTaskGroup[])?.filter(
    (taskGroup) => taskGroup.providerOnly
  );

  // calculate progress of FAM tasks only
  const completedAndTotalTasks = getCompletedAndTotalTaskFromTaskGroups(
    (familyTaskGroups as ExtendedTaskGroup[]) ?? []
  );

  let progress = 0;

  if (completedAndTotalTasks) {
    progress = Math.floor(
      (completedAndTotalTasks?.completed * 100) / completedAndTotalTasks?.total
    );
  }

  const { data: templatesResult } = useGetTemplates({
    caseId: caseId,
    rootFolderId: currentCase.defaultTemplatesFolder,
  });

  const templatesList = useMemo(() => {
    if (!templatesResult?.ok) {
      return [];
    }
    return templatesResult?.content;
  }, [templatesResult]);

  const familyName = getFamilyNameComplete({
    parentFirstName: currentCase.parentFirstName,
    parentLastName: currentCase.parentLastName,
    secondParentFirstName: currentCase.secondParentFirstName,
    secondParentLastName: currentCase.secondParentLastName,
  });

  const taskRow = useCallback(
    (task: Task) => {
      return TaskRow({
        task,
        caseId,
        deleteTask,
        setCurrentTaskBeingManaged,
        isAdmin: props.isAdmin,
        setTaskManagerModalOpen,
        deleteDocument,
        uploadDocument,
        updateTaskQuestionAnswer,
        getFileData,
        famDocumentsLink,
        providerDocumentsLink,
        sendReferenceRequest,
        getFileUrl,
        issueDocument,
        refreshTask,
        refreshElectronicDocumentSession,
      });
    },
    [
      caseId,
      deleteDocument,
      deleteTask,
      famDocumentsLink,
      getFileData,
      getFileUrl,
      issueDocument,
      props.isAdmin,
      providerDocumentsLink,
      refreshTask,
      sendReferenceRequest,
      updateTaskQuestionAnswer,
      uploadDocument,
      refreshElectronicDocumentSession,
    ]
  );

  const tasksWithMissingExpirationDate = useMemo(() => {
    return familyTaskGroups
      ?.flatMap((item) => item.tasks)
      ?.filter(
        (item) =>
          item.expirationDateRequired &&
          (!item.expirationDate || item.expirationDate <= new Date())
      );
  }, [familyTaskGroups]);

  const handleStatusChange = useCallback(
    async (value: boolean) => {
      await changeCaseStatus({ caseId, active: value });
    },
    [caseId, changeCaseStatus]
  );

  const { handleTabChange, currentTab } = useTabs<ProfessionalTabEnum>(
    ProfessionalTabEnum.FamilyTasks
  );

  return (
    <>
      <ContentWrapper background={palette.background.light50}>
        <Box pt={4} pb={2}>
          <BackButton />

          <Typography variant="head2" component="h2">
            {familyName}
          </Typography>

          <Box mt={3}>
            <Header
              selectedCase={currentCase}
              caseProviders={caseProviders}
              percentComplete={progress}
              famDocumentsLinks={
                famDocumentsLink?.ok ? famDocumentsLink.content : null
              }
              providerDocumentsLink={
                providerDocumentsLink?.ok ? providerDocumentsLink.content : null
              }
              onUpdateCaseNote={updateCaseNote}
              onComplete={() => setCompleteCaseModalOpen(true)}
              onStatusChange={handleStatusChange}
              changeStatusLoading={changeStatusLoading}
            />
          </Box>
        </Box>
      </ContentWrapper>

      <ContentWrapper background={styles.tabsBackground}>
        <ProfessionalPageTabs value={currentTab} onChange={handleTabChange} />
      </ContentWrapper>

      <ContentWrapper>
        <Box pt={5} pb={10}>
          <Fade
            exit={false}
            in={currentTab === ProfessionalTabEnum.FamilyTasks}
            unmountOnExit
          >
            <Box>
              <FamilyTasks
                familyTaskGroups={familyTaskGroups}
                taskRow={taskRow}
                createReferenceTask={createReferenceTask}
                setTaskGroupToEdit={setTaskGroupToEdit}
                reorderTaskGroups={reorderTaskGroups}
                reorderTasks={reorderTasks}
                taskGroupsError={taskGroupsError}
                setCurrentGroupAddingTask={setCurrentGroupAddingTask}
                setTaskManagerModalOpen={setTaskManagerModalOpen}
              />
            </Box>
          </Fade>

          <Fade
            exit={false}
            in={currentTab === ProfessionalTabEnum.MyTasks}
            unmountOnExit
          >
            <Box>
              <MyTasks
                taskGroupsError={taskGroupsError}
                createReferenceTask={createReferenceTask}
                providerTaskGroups={providerTaskGroups}
                taskRow={taskRow}
              />
            </Box>
          </Fade>
        </Box>
      </ContentWrapper>

      <CompleteCaseModal
        isOpen={isCompleteCaseModalOpen}
        onClose={() => setCompleteCaseModalOpen(false)}
        onSubmit={async (args: {
          approvalDate: Date;
          expirationDate: Date;
        }) => {
          if (tasksWithMissingExpirationDate.length) {
            setIsCompleteCaseWarningModalOpen(true);
          } else {
            await completeCase({
              caseId,
              approvedAt: args.approvalDate,
              expiresAt: args.expirationDate,
            });
          }
        }}
      />

      <Modal
        title="Unable to complete the case"
        open={isCompleteCaseWarningModalOpen}
        onClose={() => {
          setIsCompleteCaseWarningModalOpen(false);
        }}
      >
        <div className="my-8">
          {t("errors.allTasksShouldHaveFutureExpirationDate")}
          <ul className="mt-2">
            {tasksWithMissingExpirationDate?.map((task) => {
              return <li key={`task-id-${task.id}`}>- {task.title}</li>;
            })}
          </ul>
        </div>
      </Modal>

      <TaskManagementSidePanel
        isAdmin={false}
        templatesList={templatesList}
        caseLoginUsers={caseLoginUsers as CaseLoginUser[]}
        taskGroup={currentGroupAddingTask}
        task={currentTaskBeingManaged}
        createUpdateTask={createUpdateTask}
        isOpen={isTaskManagerModalOpen}
        onClose={() => {
          setTaskManagerModalOpen(false);
          setCurrentTaskBeingManaged(undefined);
        }}
      />

      <CreateTaskGroupModal
        isProvider={taskGroupToEdit?.providerOnly ?? false}
        taskGroup={taskGroupToEdit}
        isOpen={!!taskGroupToEdit}
        onClose={() => setTaskGroupToEdit(null)}
        createTaskGroup={(args) => upsertTaskGroup(args).then((_res) => true)}
      />
    </>
  );
}
