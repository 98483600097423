import { z } from "zod";
import {
  phoneNumberValidation,
  requiredString,
} from "@shared/validation-schemas";

export const validationSchema = z.object({
  provider: z.string(),
  email: z.string().email().min(1),
  firstName: requiredString,
  lastName: requiredString,
  phoneNumber: phoneNumberValidation,
  profilePictureUrl: requiredString,
  showContactInfo: z.boolean(),
  isAdmin: z.boolean(),
});

export type FormValues = z.infer<typeof validationSchema>;
