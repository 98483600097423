import { Fragment } from "react";
import { TFunction } from "i18next";
import { Trans } from "next-i18next";

import { Typography, Link } from "@mui/material";
import { styles } from "./styles";

export type ErrorMessageProps = {
  t: TFunction<"connect">;
  errorKey: string;
  email: string;
};

export const ErrorMessage = ({ t, errorKey, email }: ErrorMessageProps) => {
  return (
    <Typography variant="body4" component="p" sx={styles.wrapper}>
      <Trans
        t={t}
        i18nKey={errorKey as any}
        values={{ email }}
        components={[
          <Fragment key="0" />,
          <Typography key="1" variant="body4" />,
          <Link key="2" href={`mailto:${email}`} target="_blank" />,
        ]}
      />
    </Typography>
  );
};
