import { useTranslation } from "next-i18next";
import { useMemo } from "react";

export const useGetRoleOptions = () => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        id: "family",
        label: t("assignCaseUserModal.fields.role.options.family"),
      },
      {
        id: "admin",
        label: t("assignCaseUserModal.fields.role.options.admin"),
      },
    ];
  }, [t]);
};
