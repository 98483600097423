export const styles = {
  filters: {
    marginBottom: 2,
    display: "flex",
    gap: 2,
  },
  fields: {
    paddingBottom: 4.5,
  },
};
