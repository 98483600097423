import { useMemo } from "react";
import { useTranslation } from "next-i18next";
import { RaceEthnicity } from "@_types/*";

export const useRaceEthnicityOptions = () => {
  const { t } = useTranslation("connect");

  return useMemo(
    () => [
      {
        id: RaceEthnicity.NativeHawaiianOrPacificIslander,
        label: t(
          "familyListing.filters.fields.raceEthnicity.options.nativeHawaiianOrPacificIslander"
        ),
      },
      {
        id: RaceEthnicity.Caucasian,
        label: t(
          "familyListing.filters.fields.raceEthnicity.options.caucasian"
        ),
      },
      {
        id: RaceEthnicity.Asian,
        label: t("familyListing.filters.fields.raceEthnicity.options.asian"),
      },
      {
        id: RaceEthnicity.AfricanAmerican,
        label: t(
          "familyListing.filters.fields.raceEthnicity.options.africanAmerican"
        ),
      },
      {
        id: RaceEthnicity.NativeAmerican,
        label: t(
          "familyListing.filters.fields.raceEthnicity.options.nativeAmerican"
        ),
      },
      {
        id: RaceEthnicity.HispanicOrLatino,
        label: t(
          "familyListing.filters.fields.raceEthnicity.options.hispanicOrLatino"
        ),
      },
      {
        id: RaceEthnicity.PreferNotToSay,
        label: t(
          "familyListing.filters.fields.raceEthnicity.options.preferNotToSay"
        ),
      },
    ],
    [t]
  );
};
