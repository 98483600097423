import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styles } from "./styles";

export const DefaultTableRowRenderer = ({
  value,
  hasSecondaryItem,
}: {
  value: any;
  hasSecondaryItem?: boolean;
}) => {
  const { t } = useTranslation("connect");

  return (
    <Typography
      variant={hasSecondaryItem ? "body8" : "body6"}
      sx={styles.tableValueItem}
    >
      {!Boolean(value)
        ? t("familyListing.familyDetails.notSpecifiedLabel")
        : value}
    </Typography>
  );
};

export const SecondaryItemTableRowRenderer = ({ value }: { value: any }) => {
  return (
    <Typography variant="body7" sx={styles.tableValueSecondaryItem}>
      {value}
    </Typography>
  );
};
