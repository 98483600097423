export enum ProfessionalType {
  Agency = "Agency",
  attorney = "Attorney",
  SocialWorker = "Social Worker",
  Consultant = "Consultant",
  Therapist = "Therapist",
  Placement = "Placement",
  None = "",
}

export interface ProfessionalBase {
  uuid: string;
  organizationName: string;
  type: ProfessionalType;
  contactFirstName: string;
  contactLastName: string;
  city: string;
  state: string;
  website: string;
  emailAddress: string;
  phone: string;
  postalCode: string;
}
