import { useTranslation } from "next-i18next";
import { ContactWidget } from "../contact-widget/ContactWidget";
import { appConfig } from "@lib";

type PersonProfile = {
  firstName: string;
  lastName: string;
  title: string | null;
  profilePicture: string | null;
  phoneNumber: string | null;
  email: string | null;
};

export function AdoptionTeam({ people }: { people: PersonProfile[] }) {
  const { t } = useTranslation("family");

  return (
    <div className="w-auto py-2">
      <ul role="list" className="divide-y divide-backgroundgray">
        {people.map((person) => (
          <li key={person.title} className="py-5">
            <ContactWidget
              pageType="Family"
              header={person.firstName + " " + person.lastName}
              title={t("adoptionTeam.contactWidgetTitle")}
              email={appConfig.companyContactEmail}
              profilePicture={person.profilePicture}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
