import { XMarkIcon } from "@pairtreefamily/ui";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { useTranslation } from "next-i18next";
import { Task } from "@_types/*";

type IssueDocumentsErrorsProps = {
  tasksWithError: Task[];
  showIssuedErrors: boolean;
  setShowIssuedErrors: (status: boolean) => void;
  reissueFailed: () => void;
};

export function IssueDocumentsErrors(props: IssueDocumentsErrorsProps) {
  const {
    tasksWithError,
    showIssuedErrors,
    setShowIssuedErrors,
    reissueFailed,
  } = props;

  const { t } = useTranslation("admin");

  const { successCount, totalCount } = useMemo(() => {
    return tasksWithError.reduce(
      (acc, item) => {
        if (item.documentIssueStatus === "success") {
          acc.successCount += 1;
        }
        if (item.documentIssueStatus !== null) {
          acc.totalCount += 1;
        }
        return acc;
      },
      { successCount: 0, totalCount: 0 }
    );
  }, [tasksWithError]);

  return (
    <div>
      <p className="mb-4 text-teal">
        <strong>
          {t("issueDocumentsErrors.result", { successCount, totalCount })}
        </strong>
      </p>
      <div className="flex gap-5">
        <a
          className="cursor-pointer text-gray"
          onClick={() => setShowIssuedErrors(!showIssuedErrors)}
        >
          <strong>
            {showIssuedErrors
              ? t("issueDocumentsErrors.issuedErrorsStatus.hide")
              : t("issueDocumentsErrors.issuedErrorsStatus.show")}
          </strong>
        </a>
        {tasksWithError.length > 0 && (
          <a onClick={reissueFailed} className="cursor-pointer text-gray">
            <strong>{t("issueDocumentsErrors.reissueFailed")}</strong>
          </a>
        )}
      </div>

      {showIssuedErrors &&
        tasksWithError.map((item) => (
          <div
            key={item.id}
            className="grid grid-cols-2  border-b-[1px] border-lightgray py-4"
          >
            <div
              className={`flex ${
                item.documentIssueStatus === "success"
                  ? "text-green"
                  : "text-peach"
              }`}
            >
              {item.documentIssueStatus === "success" && (
                <CheckIcon className="h-[18px] w-[18px]" />
              )}
              {item.documentIssueStatus === "error" && (
                <XMarkIcon className="h-[18px] w-[18px]" />
              )}
              <span className="ml-4 whitespace-nowrap">{item.title}</span>
            </div>

            <div className="text-gray">{item.documentIssueError}</div>
          </div>
        ))}
    </div>
  );
}
