import { useCallback, useState } from "react";

import { Result } from "@pairtreefamily/utils";
import { Button } from "@pairtreefamily/ui";
import {
  TaskDescription,
  TaskTitle,
  UploadedFilesModal,
  TaskStatusElement,
  TaskQuestionStatus,
} from "@shared/components";

import type { FileUploadTask, Task } from "@_types/*";
import { useTranslation } from "react-i18next";
import { RefreshTask } from "@shared/components";
import { Box } from "@mui/material";
import { UseProfessionalPageTools } from "@features";
import { UseFileUpload } from "@shared/hooks";

export type IssuerFileUploadRowProps = {
  task: FileUploadTask;
  setTaskManagerModalOpen: (open: boolean) => void;
  setCurrentTaskBeingManaged: (currentTask: Task) => void;
  viewDocumentsLink: string | null;
  getFileName: UseFileUpload["getFileName"];
  getFileUrl: (fileId: string) => Promise<Result<string, unknown>>;
  refreshTask: UseProfessionalPageTools["refreshTask"];
};

export function IssuerFileUploadRow(props: IssuerFileUploadRowProps) {
  const {
    task,
    setCurrentTaskBeingManaged,
    setTaskManagerModalOpen,
    viewDocumentsLink,
    getFileName,
    getFileUrl,
    refreshTask,
  } = props;

  const { t } = useTranslation("components");

  const [isFileModalOpen, setFileModalOpen] = useState(false);
  const [filesData, setFilesData] = useState<Array<{
    id: string;
    name: string;
  }> | null>(null);

  const getFilesData = useCallback(async () => {
    const names: Array<{ id: string; name: string }> = [];
    for (const file of task?.taskFiles) {
      const fileName = await getFileName(file.googleDriveFileId);
      names.push({ id: file.googleDriveFileId, name: fileName ?? "" });
    }
    setFilesData(names);
  }, [task, getFileName]);

  return {
    column1: (
      <TaskTitle
        shouldDisplayViewDocumentsLink={true}
        task={task}
        viewDocumentsLink={viewDocumentsLink}
        getFileUrl={getFileUrl}
        getFileName={getFileName}
      />
    ),
    column2: <TaskDescription task={task} />,
    column3: task.question ? (
      <TaskQuestionStatus task={task} isFamily={false} />
    ) : (
      <TaskStatusElement task={task} />
    ),
    // TODO: make this a direct link
    column4: (
      <>
        <div className="flex justify-center gap-4">
          {task.taskFiles.length > 0 && (
            <Button
              onClick={() => {
                getFilesData();
                setFileModalOpen(true);
              }}
            >
              {t("issuerFileUploadRow.buttons.view")}
            </Button>
          )}
          <Button
            color="error"
            variant="outlined"
            onClick={async () => {
              setTaskManagerModalOpen(true);
              setCurrentTaskBeingManaged(task);
            }}
          >
            {t("issuerFileUploadRow.buttons.edit")}
          </Button>

          <UploadedFilesModal
            filesData={filesData}
            isOpen={isFileModalOpen}
            onClose={() => setFileModalOpen(false)}
            getFileUrl={getFileUrl}
            viewDocumentsLink={viewDocumentsLink}
          />
        </div>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
          <RefreshTask
            task={task}
            refreshTask={refreshTask}
            expirationDate={task.expirationDate}
          />
        </Box>
      </>
    ),
  };
}
