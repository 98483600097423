import { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { PairTreeIcon } from "@pairtreefamily/ui";

interface ContactWidgetProps {
  pageType: "Family" | "Professional";
  header: string;
  title?: string | null;
  email: string | null;
  profilePicture?: string | null;
}

export function ContactWidget({
  pageType,
  header,
  title,
  email,
  profilePicture,
}: ContactWidgetProps) {
  const { t } = useTranslation("components");
  const [validImage, setValidImage] = useState(false);

  useEffect(() => {
    if (profilePicture) {
      let image = new Image();
      image.onload = () => setValidImage(true);
      image.onerror = () => setValidImage(false);
      image.src = profilePicture;
    }
  }, [profilePicture]);

  return (
    <div className="h-[100px] w-auto">
      <div className="flex flow-root items-center justify-center">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            {pageType === "Family" && profilePicture && validImage ? (
              <img
                className="h-[100px] w-[100px] rounded-full"
                src={profilePicture}
                alt=""
              />
            ) : (
              <PairTreeIcon /> // pro page or if pic is null/invalid link
            )}
          </div>
          <div className="text-body-3 min-w-0 flex-1">
            <p className="truncate pb-1 font-bold">{header}</p>
            <div className="text-darkgray">
              {pageType === "Family" && (
                <>
                  {title && <p className="text-body-3-medium pb-1">{title}</p>}
                  <p>{email}</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
