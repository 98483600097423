import { useMemo, useState } from "react";
import { Trans, useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useReorderTaskGroups, useReorderTasks } from "@shared/hooks";
import { useGetTemplates } from "@api";
import { Button, Checkbox, ConcurrentLoader, Modal } from "@pairtreefamily/ui";
import { FamilySelectorDropDown } from "./FamilySelectorDropDown";
import {
  TaskManagementSidePanel,
  CreateTaskGroupModal,
} from "@shared/components";
import { IssueDocumentsErrors } from "./IssueDocumentsErrors";
import { AssignCaseUserModal } from "./assign-case-user-modal/AssignCaseUserModal";
import { AssignPersonToTasksModal } from "./AssignPersonToTasksModal";
import { AdminTaskGroupList } from "./AdminTaskGroupList";
import { getFamilyName, initLogger } from "@pairtreefamily/utils";
import { useAdminTools } from "../hooks/useAdminTools";
import { ToggleCaseVisibilityModal } from "./ToggleCaseVisibilityModal";
import { Actions } from "./actions/Actions";

import { ReplaceTasksTitleModal } from "./replace-tasks-title-modal";
import { UpsertCaseModal } from "./upsert-case-modal";
import { ProviderAssignmentModal } from "./provider-assignment-modal";
import { useGetRequiredTemplateRoles } from "./admin-view/hooks";
import { Box, Typography } from "@mui/material";

import type {
  Case,
  ElectronicDocumentTask,
  ExtendedTaskGroup,
  FileUploadTask,
  Person,
  CaseLoginUser,
  Task,
  TaskGroup,
} from "@_types/*";

const logger = initLogger("pages.admin.index.tsx");

export type AdminViewProps = {
  currentCase: Case;
  cases: Case[];
  setCaseId: (id: string | null) => void;
  refetchAllCases: () => void;
};

export function AdminView(props: AdminViewProps) {
  const { cases, currentCase, setCaseId } = props;
  const caseId = currentCase.id;
  const selectedCase = currentCase;

  const router = useRouter();
  const { t } = useTranslation("admin");

  const [showHiddenCases, _setShowHiddenCases] = useState<boolean>(
    localStorage.getItem("showHiddenCases") === "true"
  );
  const [isCaseModalOpen, setIsCaseModalOpen] = useState<boolean>(false);
  const [isHideCaseModalOpen, setIsHideCaseModalOpen] =
    useState<boolean>(false);
  const [editCurrentCase, setEditCurrentCase] = useState<boolean>(false);

  const [createTaskGroupModalState, setCreateTaskGroupModalState] = useState<{
    isOpen: boolean;
    isProvider: boolean;
  }>({ isOpen: false, isProvider: false });

  const [currentTaskGroupBeingManaged, setCurrentTaskGroupBeingManaged] =
    useState<TaskGroup | null>();
  const [isAssignCaseUserModalOpen, setIsAssignCaseUserModalOpen] =
    useState<boolean>(false);

  const [isReplaceTasksTitleModalOpen, setIsReplaceTasksTitleModalOpen] =
    useState<boolean>(false);
  const [currentTaskBeingManaged, setCurrentTaskBeingManaged] = useState<
    Task | ElectronicDocumentTask | FileUploadTask
  >();
  const [currentGroupAddingTask, setCurrentGroupAddingTask] =
    useState<TaskGroup>();
  const [isTaskManagerModalOpen, setTaskManagerModalOpen] = useState(false);
  const [providerAssignmentModalOpen, setProviderAssignmentModalOpen] =
    useState(false);
  const [isAssignTasksModalOpen, setAssignTasksModalOpen] = useState(false);

  const [isCloneCaseModalOpened, setIsCloneCaseModalOpened] = useState(false);

  const {
    taskGroups,
    upsertCase,
    upsertTaskGroup,
    cloneCase,
    assignUserToCase,
    upsertElectronicDocumentTask,
    upsertFileUploadTask,
    upsertExternalSignerTask,
    updateTaskGroupOrder,
    updateTaskOrder,
    caseLoginUsers,
    setTaskAssignment,
    taskAssignmentStatus,
    deleteTaskAssignment,
    deleteTask,
    issueDocument,
    deleteTaskGroup,
    caseParticipants,
    providerCandidates,
    assignProviderToCase,
    issueDocumentsForCaseIsIdle,
    issueDocumentsForCaseIsLoading,
    issueDocumentsForCase,
    removeAssigneeFromCase,
    toggleProviderContactInfo,
    getTemplateRoles,
    replaceTasksTitle,
    refreshTask,
    refreshElectronicDocumentSession,
  } = useAdminTools(caseId);

  const { getRequiredRoles, tasksWithRoles, isLoadingRoles } =
    useGetRequiredTemplateRoles(getTemplateRoles);

  const reorderTaskGroups = useReorderTaskGroups(
    taskGroups,
    caseId,
    updateTaskGroupOrder
  );

  const reorderTasks = useReorderTasks(taskGroups, caseId, updateTaskOrder);

  const providerPeople: Array<Person & { isAdmin: boolean }> = useMemo(() => {
    const persons: Array<Person & { isAdmin: boolean }> = [];
    for (const c of providerCandidates) {
      if (c.person !== null) {
        const p = c.person;
        persons.push({ ...p, isAdmin: c.isAdmin ?? false });
      }
    }
    return persons;
  }, [providerCandidates]);

  const issueStandardDocuments = async () => {
    issueDocumentsForCase();
  };

  const [showIssuedErrors, setShowIssuedErrors] = useState<boolean>(false);

  const tasksWithIssueStatus = useMemo(() => {
    let allTasks: Task[] = [];

    taskGroups?.forEach((taskGroup) => {
      allTasks = [...allTasks, ...taskGroup.tasks];
    });

    return allTasks.filter(
      (task) =>
        task.taskType === "electronicDocument" &&
        task.documentIssueStatus !== null
    );
  }, [taskGroups]);

  const filteredCases = useMemo(() => {
    let filtered;

    if (showHiddenCases) {
      return cases;
    }

    filtered = cases.filter((item) => item.hidden === showHiddenCases);

    return filtered;
  }, [cases, showHiddenCases]);

  const numberOfProviders = caseLoginUsers.filter(
    (user) => user.role === "provider"
  ).length;

  const toggleShowHiddenCases = (value: boolean) => {
    _setShowHiddenCases(value);
    localStorage.setItem("showHiddenCases", value.toString());
  };

  const familyTaskGroups = (taskGroups as ExtendedTaskGroup[])?.filter(
    (taskGroup) => !taskGroup.providerOnly
  );
  const providerTaskGroups = (taskGroups as ExtendedTaskGroup[])?.filter(
    (taskGroup) => taskGroup.providerOnly
  );

  const { data: templatesResult } = useGetTemplates({
    caseId: caseId,
    rootFolderId: currentCase.defaultTemplatesFolder,
  });

  const templatesList = useMemo(() => {
    if (!templatesResult?.ok) {
      return [];
    }
    return templatesResult?.content;
  }, [templatesResult]);

  return (
    <ConcurrentLoader>
      <div className="p-8">
        <div className="flex">
          <div className="m-auto w-full max-w-[900px]">
            <div className="m-auto mt-4 mb-8 flex justify-center">
              <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
                <Typography variant="head2" color="primary.main">
                  {t("adoptiveFamilies.caption")}
                </Typography>

                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Box sx={{ width: "600px" }}>
                    <FamilySelectorDropDown
                      cases={filteredCases}
                      selectedCase={selectedCase}
                      onCaseChange={setCaseId}
                    />
                  </Box>

                  <Checkbox
                    label={t("adoptiveFamilies.fields.showHiddenCases.label")}
                    checked={showHiddenCases}
                    onChange={(hide) => {
                      toggleShowHiddenCases(hide);
                      router.replace(
                        `/admin/${
                          cases.filter(
                            (item) =>
                              item.hidden ===
                              (localStorage.getItem("showHiddenCases") ===
                                "true")
                          )[0]?.id ?? cases[0]?.id
                        }`,
                        undefined,
                        { shallow: true }
                      );
                    }}
                  />
                </Box>

                <Typography variant="body2" color="text">
                  {t("adoptiveFamilies.fields.familySelector.hint")}
                </Typography>
              </Box>
            </div>
          </div>
        </div>

        <Box marginBottom={5}>
          <Actions
            setEditCurrentCase={setEditCurrentCase}
            setIsCaseModalOpen={setIsCaseModalOpen}
            setIsCloneCaseModalOpened={setIsCloneCaseModalOpened}
            setIsHideCaseModalOpen={setIsHideCaseModalOpen}
            setIsAssignCaseUserModalOpen={setIsAssignCaseUserModalOpen}
            setIsReplaceTasksTitleModalOpen={setIsReplaceTasksTitleModalOpen}
            setAssignTasksModalOpen={setAssignTasksModalOpen}
            setShowIssuedErrors={setShowIssuedErrors}
            isCurrentCaseHidden={currentCase.hidden}
            issueDocumentsForCaseIsLoading={issueDocumentsForCaseIsLoading}
            issueDocumentsForCaseIsIdle={issueDocumentsForCaseIsIdle}
            tasksWithIssueStatus={tasksWithIssueStatus}
            issueStandardDocuments={issueStandardDocuments}
          />
        </Box>

        {tasksWithIssueStatus.length > 0 && (
          <IssueDocumentsErrors
            tasksWithError={tasksWithIssueStatus}
            showIssuedErrors={showIssuedErrors}
            setShowIssuedErrors={(value) => setShowIssuedErrors(value)}
            reissueFailed={() => issueDocumentsForCase(true)}
          />
        )}

        <div className="text-body-3 my-4">
          {caseLoginUsers.length > 0 && (
            <div className="text-left">
              <strong className="mb-4">{t("existingCaseUsers.title")}</strong>
              <ul className="mx-4 my-4 list-disc">
                {caseLoginUsers.map((user) => (
                  <li key={user.email} className="mt-2 list-none">
                    <span
                      className="cursor-pointer font-bold text-purple"
                      onClick={() =>
                        removeAssigneeFromCase({ role: user.role, id: user.id })
                      }
                    >
                      X
                    </span>{" "}
                    {user.email} ({user.role})
                    {user.role === "provider" && (
                      <Box ml={2} component="span">
                        <Checkbox
                          label={t("existingCaseUsers.showContactInfo")}
                          checked={user.showContactInfoIfProvider}
                          onChange={(isChecked: boolean) =>
                            toggleProviderContactInfo({
                              loginUserId: user.id,
                              showContactInfo: isChecked,
                            })
                          }
                        />
                      </Box>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {caseLoginUsers.length === 0 && (
            <div>
              <p>{t("existingCaseUsers.emptyCases")}</p>
            </div>
          )}

          <Button
            color="proBlue"
            variant="contained"
            onClick={() => setProviderAssignmentModalOpen(true)}
          >
            {numberOfProviders > 0
              ? t("existingCaseUsers.actions.addAdditionalProvider")
              : t("existingCaseUsers.actions.addProvider")}
          </Button>
        </div>

        <UpsertCaseModal
          isOpen={isCaseModalOpen}
          onClose={() => setIsCaseModalOpen(false)}
          currentCase={editCurrentCase ? selectedCase : undefined}
          upsertCase={async (args) => {
            const res = await upsertCase.mutateAsync(args);

            props.refetchAllCases();

            return res.ok;
          }}
        />

        <CreateTaskGroupModal
          isProvider={createTaskGroupModalState.isProvider}
          isOpen={createTaskGroupModalState.isOpen}
          onClose={() => {
            setCreateTaskGroupModalState({ isOpen: false, isProvider: false });
            setCurrentTaskGroupBeingManaged(undefined);
          }}
          taskGroup={currentTaskGroupBeingManaged ?? null}
          createTaskGroup={(args) => upsertTaskGroup(args).then((_res) => true)}
        />

        <AssignCaseUserModal
          isOpen={isAssignCaseUserModalOpen}
          onClose={() => setIsAssignCaseUserModalOpen(false)}
          assignUserToCase={(args) => {
            return assignUserToCase(args).then((_res) => true);
          }}
        />
        <ReplaceTasksTitleModal
          isOpen={isReplaceTasksTitleModalOpen}
          onClose={() => setIsReplaceTasksTitleModalOpen(false)}
          replace={async (placeholder, value) => {
            const replaced = await replaceTasksTitle({
              placeholder,
              value,
              caseId: currentCase.id,
            });
            if (replaced.ok) {
              return Promise.resolve(replaced.content);
            } else {
              return Promise.resolve(null);
            }
          }}
        />
        {taskGroups && (
          <AssignPersonToTasksModal
            caseParticipants={caseParticipants}
            caseId={currentCase.id}
            setTaskAssignment={setTaskAssignment}
            taskGroups={taskGroups as ExtendedTaskGroup[]}
            isOpen={isAssignTasksModalOpen}
            onClose={() => setAssignTasksModalOpen(false)}
            getRequiredRoles={getRequiredRoles}
            tasksRoles={tasksWithRoles ?? []}
            isLoadingRoles={isLoadingRoles}
            caseLoginUsers={caseLoginUsers as CaseLoginUser[]}
          />
        )}
        <ProviderAssignmentModal
          isOpen={providerAssignmentModalOpen}
          onClose={() => setProviderAssignmentModalOpen(false)}
          candidates={providerPeople}
          assignProvider={async ({
            email,
            firstName,
            lastName,
            isAdmin,
            profilePictureUrl,
            phoneNumber,
            showContactInfo,
          }) =>
            assignProviderToCase({
              email,
              firstName,
              lastName,
              isAdmin,
              profilePictureUrl,
              phoneNumber,
              showContactInfo,
            }).then((res) => res)
          }
        />
        <TaskManagementSidePanel
          isAdmin={true}
          templatesList={templatesList}
          caseLoginUsers={caseLoginUsers as CaseLoginUser[]}
          taskGroup={currentGroupAddingTask}
          allTaskGroups={(taskGroups as ExtendedTaskGroup[]) ?? []}
          task={currentTaskBeingManaged}
          createUpdateTask={async (args) => {
            if (args.taskType === "electronicDocument" && args.templateUuid) {
              await upsertElectronicDocumentTask({
                caseId,
                task: {
                  ...args,
                  status: args.status ?? "notStarted",
                  question: args.question,
                  caseId,
                  taskGroupId: args.taskGroupId ?? "",
                  taskType: "electronicDocument",
                  templateUuid: args.templateUuid,
                  recipientEmailIds: args.recipientEmailIds ?? [],
                  answer: args.answer,
                  expirationDateRequired: args.expirationDateRequired,
                  expirationDate: args.expirationDate,
                },
              });
              setTaskManagerModalOpen(false);
            }
            if (args.taskType === "reference" && args.templateUuid) {
              await upsertExternalSignerTask({
                caseId,
                task: {
                  ...args,
                  taskGroupId:
                    currentGroupAddingTask?.id || args.taskGroupId || "",
                  status: args.status ?? "notStarted",
                  title: args.title,
                  caseId,
                  question: args.question,
                  answer: args.answer,
                  taskType: "reference",
                  templateUuid: args.templateUuid,
                  recipientEmailIds: args.recipientEmailIds ?? [],
                  isPrivate: args.isPrivate ?? true,
                  expirationDateRequired: args.expirationDateRequired,
                  expirationDate: args.expirationDate,
                },
              });
              setTaskManagerModalOpen(false);
            }
            if (args.taskType === "fileUpload") {
              try {
                const result = await upsertFileUploadTask({
                  caseId,
                  task: {
                    ...args,
                    status: args.status ?? "notStarted",
                    question: args.question,
                    title: args.title,
                    taskGroupId: args.taskGroupId ?? "",
                    caseId,
                    answer: args.answer,
                    taskType: "fileUpload",
                    recipientEmailIds: args.recipientEmailIds ?? [],
                    isPrivate: args.isPrivate ?? false,
                    expirationDateRequired: args.expirationDateRequired,
                    expirationDate: args.expirationDate,
                  },
                });
                logger
                  .child(result)
                  .info("TaskManagerModal > upsertFileUploadTask result");
                setTaskManagerModalOpen(false);
                return true;
              } catch (err: any) {
                logger
                  .child(err)
                  .info("TaskManagerModal > upsertFileUploadTask error");
                return false;
              }
            }

            return false;
          }}
          isOpen={isTaskManagerModalOpen}
          onClose={() => {
            setTaskManagerModalOpen(false);
            setCurrentTaskBeingManaged(undefined);
          }}
        />

        <p className="text-body-1 my-4">
          <strong>{t("familyChecklist.title")}</strong>
        </p>

        <Box mb={2}>
          <Button
            color="warning"
            variant="outlined"
            onClick={() =>
              setCreateTaskGroupModalState({ isOpen: true, isProvider: false })
            }
          >
            {t("familyChecklist.createTaskGroup")}
          </Button>
        </Box>
        {familyTaskGroups && (
          <AdminTaskGroupList
            refreshTask={refreshTask}
            caseId={caseId}
            setTaskAssignment={setTaskAssignment}
            onTaskReorder={reorderTasks}
            taskAssignmentStatus={taskAssignmentStatus}
            caseParticipants={caseParticipants}
            deleteTaskAssignment={deleteTaskAssignment}
            deleteTask={deleteTask}
            issueDocument={issueDocument}
            onEditTask={(task) => {
              setCurrentTaskBeingManaged(task);
              task.TaskGroup && setCurrentGroupAddingTask(task.TaskGroup);
              setTaskManagerModalOpen(true);
            }}
            deleteTaskGroup={deleteTaskGroup}
            onEditTaskGroup={(taskGroup: TaskGroup) => {
              setCreateTaskGroupModalState({ isOpen: true, isProvider: false });
              setCurrentTaskGroupBeingManaged(taskGroup);
            }}
            onAddTask={(taskGroup: TaskGroup) => {
              setCurrentGroupAddingTask(taskGroup);
              setTaskManagerModalOpen(true);
            }}
            upsertTaskGroup={upsertTaskGroup}
            taskGroups={familyTaskGroups}
            onTaskGroupReorder={reorderTaskGroups}
            tasksRoles={tasksWithRoles ?? []}
            getRequiredRoles={getRequiredRoles}
            isLoadingRoles={isLoadingRoles}
            caseLoginUsers={caseLoginUsers as CaseLoginUser[]}
            refreshElectronicDocumentSession={refreshElectronicDocumentSession}
          />
        )}

        <p className="text-body-1 my-4">
          <strong>{t("professionalChecklist.title")}</strong>
        </p>

        <Box mb={2}>
          <Button
            color="warning"
            variant="outlined"
            onClick={() =>
              setCreateTaskGroupModalState({ isOpen: true, isProvider: true })
            }
          >
            {t("professionalChecklist.createTaskGroup")}
          </Button>
        </Box>

        {providerTaskGroups && (
          <AdminTaskGroupList
            refreshTask={refreshTask}
            caseId={caseId}
            setTaskAssignment={setTaskAssignment}
            onTaskReorder={reorderTasks}
            taskAssignmentStatus={taskAssignmentStatus}
            caseParticipants={caseParticipants}
            deleteTaskAssignment={deleteTaskAssignment}
            deleteTask={deleteTask}
            issueDocument={issueDocument}
            onEditTask={(task) => {
              setCurrentTaskBeingManaged(task);
              task.TaskGroup && setCurrentGroupAddingTask(task.TaskGroup);
              setTaskManagerModalOpen(true);
            }}
            deleteTaskGroup={deleteTaskGroup}
            onEditTaskGroup={(taskGroup: TaskGroup) => {
              setCreateTaskGroupModalState({ isOpen: true, isProvider: true });
              setCurrentTaskGroupBeingManaged(taskGroup);
            }}
            onAddTask={(taskGroup: TaskGroup) => {
              setCurrentGroupAddingTask(taskGroup);
              setTaskManagerModalOpen(true);
            }}
            upsertTaskGroup={upsertTaskGroup}
            taskGroups={providerTaskGroups}
            onTaskGroupReorder={reorderTaskGroups}
            tasksRoles={tasksWithRoles ?? []}
            getRequiredRoles={getRequiredRoles}
            isLoadingRoles={isLoadingRoles}
            caseLoginUsers={caseLoginUsers as CaseLoginUser[]}
            refreshElectronicDocumentSession={refreshElectronicDocumentSession}
          />
        )}
      </div>

      <ToggleCaseVisibilityModal
        isOpen={isHideCaseModalOpen}
        setIsOpen={setIsHideCaseModalOpen}
        caseId={caseId}
        isCurrentCaseHidden={currentCase.hidden}
        currentCaseId={currentCase.id}
        showHiddenCases={showHiddenCases}
        cases={cases}
      />

      <Modal
        open={isCloneCaseModalOpened}
        title={t("cloneCaseModal.title")}
        submitButton={{
          label: t("cloneCaseModal.action"),
          onClick: async () => {
            if (selectedCase) {
              const caseResult = await cloneCase(selectedCase);
              if (caseResult.ok) {
                setCaseId(caseResult.content.id);
                setIsCloneCaseModalOpened(false);
                return null;
              } else {
                return t("cloneCaseModal.messages.issue");
              }
            } else {
              return t("cloneCaseModal.messages.emptyCases");
            }
          },
        }}
        onClose={() => setIsCloneCaseModalOpened(false)}
      >
        <div className="w-full max-w-[600px]">
          <p className="mb-8">
            <Trans
              t={t}
              i18nKey={"cloneCaseModal.warning"}
              values={{ familyName: getFamilyName(selectedCase) }}
              components={[<strong key="0" />]}
            />
          </p>
        </div>
      </Modal>
    </ConcurrentLoader>
  );
}
