import { Button, Modal } from "@pairtreefamily/ui";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "next-i18next";
import { Task } from "@_types/*";
import { Box } from "@mui/material";

type IssueReissueDocumentsProps = {
  issueDocumentsForCaseIsLoading: boolean;
  issueDocumentsForCaseIsIdle: boolean;
  setShowIssuedErrors: (showIssuedErrors: boolean) => void;
  tasksWithIssueStatus: Task[];
  issueStandardDocuments: () => Promise<void>;
};

export const IssueReissueDocuments = ({
  issueStandardDocuments,
  issueDocumentsForCaseIsLoading,
  issueDocumentsForCaseIsIdle,
  setShowIssuedErrors,
  tasksWithIssueStatus,
}: IssueReissueDocumentsProps) => {
  const { t } = useTranslation("admin");

  const [isConfirmationReissueAllOpen, setIsConfirmationReissueAllOpen] =
    useState(false);

  const [isConfirmationIssueAllOpen, setIsConfirmationIssueAllOpen] =
    useState(false);

  const handleIssueButtonClick = useCallback(async () => {
    if (!issueDocumentsForCaseIsLoading && !issueDocumentsForCaseIsIdle) {
      setIsConfirmationReissueAllOpen(true);
    } else {
      setIsConfirmationIssueAllOpen(true);
    }
  }, [
    issueDocumentsForCaseIsLoading,
    issueDocumentsForCaseIsIdle,
    setIsConfirmationReissueAllOpen,
  ]);

  const issueButtonContent = useMemo(() => {
    if (issueDocumentsForCaseIsLoading) {
      return t("actions.issueButtonContent.issuing");
    }

    if (issueDocumentsForCaseIsIdle) {
      return t("actions.issueButtonContent.issueDocuments");
    }

    return t("actions.issueButtonContent.reissueDocuments");
  }, [issueDocumentsForCaseIsLoading, issueDocumentsForCaseIsIdle, t]);

  return (
    <>
      <Button
        onClick={handleIssueButtonClick}
        color="secondary"
        variant="contained"
        isLoading={issueDocumentsForCaseIsLoading}
      >
        {issueButtonContent}
      </Button>

      <Modal
        title={t("reissueDocumentsModal.title")}
        open={isConfirmationReissueAllOpen}
        submitButton={{
          label: t("reissueDocumentsModal.proceedLabel"),
          onClick: async () => {
            setIsConfirmationReissueAllOpen(false);
            await issueStandardDocuments();
          },
        }}
        onClose={() => {
          setIsConfirmationReissueAllOpen(false);
        }}
      >
        <Box maxWidth="400px">{t("reissueDocumentsModal.warningMessage")}</Box>
      </Modal>

      <Modal
        title={t("issueDocumentsModal.title")}
        open={isConfirmationIssueAllOpen}
        submitButton={{
          label: t("issueDocumentsModal.proceedLabel"),
          onClick: async () => {
            await issueStandardDocuments();
            setIsConfirmationIssueAllOpen(false);

            if (tasksWithIssueStatus.length > 0) {
              setShowIssuedErrors(true);
            }
          },
        }}
        onClose={() => {
          setIsConfirmationIssueAllOpen(false);
        }}
      >
        {t("issueDocumentsModal.content")}
      </Modal>
    </>
  );
};
