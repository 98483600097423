import { flexRender, Table } from "@tanstack/react-table";
import { TableHeader } from "@shared/components";
import { DashboardRow } from "../../types";
import {
  TableBody,
  Table as MuiTable,
  TableRow,
  TableCell,
} from "@mui/material";

interface TableProps {
  table: Table<DashboardRow>;
}

export const FamilyListTable = ({ table }: TableProps) => {
  return (
    <MuiTable sx={{ mb: 2 }}>
      <TableHeader table={table} />

      <TableBody>
        {table.getRowModel().rows &&
          table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </MuiTable>
  );
};
