import { DragAndDrop, Handle, SortableItem } from "@shared/components";
import { useCallback, useMemo } from "react";
import { useTranslation } from "next-i18next";
import {
  AccordionContainer,
  AccordionDetails,
  AccordionSummary,
  Button,
  Invisible,
  Tooltip,
  Visible,
} from "@pairtreefamily/ui";
import { AdminTask } from "./AdminTask";
import { AdminTools } from "../hooks/useAdminTools";
import { TaskGroupHeader } from "@shared/components";

import type {
  ElectronicDocumentTask,
  ExtendedTaskGroup,
  Task,
  TaskGroup,
  CaseLoginUser,
} from "@_types/*";
import type { TasksWithTemplateRoles } from "../types";
import { IconButton } from "@mui/material";

export type AdminTaskGroupProps = {
  taskGroup: ExtendedTaskGroup;
  caseId: string;
  setTaskAssignment: AdminTools["setTaskAssignment"];
  taskAssignmentStatus: AdminTools["taskAssignmentStatus"];
  caseParticipants: AdminTools["caseParticipants"];
  deleteTaskAssignment: AdminTools["deleteTaskAssignment"];
  deleteTask: AdminTools["deleteTask"];
  issueDocument: AdminTools["issueDocument"];
  onTaskReorder: (
    newOrder: string[],
    group: TaskGroup | ExtendedTaskGroup
  ) => void;
  onEditTask: (task: Task) => void;
  deleteTaskGroup: AdminTools["deleteTaskGroup"];
  onEditTaskGroup: () => void;
  onAddTask: () => void;
  upsertTaskGroup: AdminTools["upsertTaskGroup"];
  getRequiredRoles: (task: ElectronicDocumentTask[]) => void;
  tasksRoles: TasksWithTemplateRoles[];
  isLoadingRoles: boolean;
  caseLoginUsers: CaseLoginUser[];
  refreshTask: AdminTools["refreshTask"];
  refreshElectronicDocumentSession: AdminTools["refreshElectronicDocumentSession"];
};

export const AdminTaskGroup = (props: AdminTaskGroupProps) => {
  const {
    taskGroup,
    caseId,
    setTaskAssignment,
    onTaskReorder,
    taskAssignmentStatus,
    caseParticipants,
    deleteTaskAssignment,
    deleteTask,
    issueDocument,
    onEditTask,
    deleteTaskGroup,
    onEditTaskGroup,
    onAddTask,
    upsertTaskGroup,
    getRequiredRoles,
    tasksRoles,
    isLoadingRoles,
    caseLoginUsers,
    refreshTask,
    refreshElectronicDocumentSession,
  } = props;

  const { t } = useTranslation("admin");

  const handleUpsertTaskGroup = useCallback(
    async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.stopPropagation();
      await upsertTaskGroup({
        id: taskGroup.id,
        title: taskGroup.title,
        taskGroupInstruction: taskGroup.taskGroupInstruction ?? "",
        isHidden: !taskGroup.isHidden,
        percentComplete: taskGroup.percentComplete,
        providerOnly: taskGroup.providerOnly,
      });
    },
    [upsertTaskGroup, taskGroup]
  );

  const handleOnAddTask = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.stopPropagation();
      onAddTask();
    },
    [onAddTask]
  );

  const handleOnEditTaskGroup = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.stopPropagation();
      onEditTaskGroup();
    },
    [onEditTaskGroup]
  );

  const handleDeleteTaskGroup = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.stopPropagation();
      deleteTaskGroup(taskGroup);
    },
    [deleteTaskGroup, taskGroup]
  );

  const taskGroupStatusKey = useMemo(() => {
    if (taskGroup.providerOnly) {
      return taskGroup.isHidden ? "hiddenProvider" : "visibleProvider";
    }

    return taskGroup.isHidden ? "hiddenFamily" : "visibleFamily";
  }, [taskGroup.providerOnly, taskGroup.isHidden]);

  return (
    <AccordionContainer>
      <AccordionSummary>
        <div className="w-full">
          <div className="flex">
            <TaskGroupHeader>
              <div className="grid w-full grid-cols-3">
                <div className="flex gap-4">
                  <span className="text-body-2 my-auto">
                    <strong>{taskGroup.title}</strong>
                  </span>
                </div>
                <p className="p-4">{taskGroup.id} </p>
                <div className="p-4">
                  <p className="text-body-2">
                    <strong>
                      {t("adminTaskGroup.tasks.amount", {
                        count: taskGroup.tasks.length,
                      })}
                    </strong>
                  </p>
                </div>
              </div>
            </TaskGroupHeader>
            <div className="flex gap-4 p-4">
              <Tooltip
                title={t(`adminTaskGroup.taskGroupType.${taskGroupStatusKey}`)}
              >
                <IconButton color="primary" onClick={handleUpsertTaskGroup}>
                  {taskGroup.isHidden ? <Invisible /> : <Visible />}
                </IconButton>
              </Tooltip>

              <Button onClick={handleOnAddTask}>
                {t("adminTaskGroup.actions.addTask")}
              </Button>

              <Button
                color="error"
                variant="outlined"
                onClick={handleOnEditTaskGroup}
              >
                {t("adminTaskGroup.actions.editTaskGroup")}
              </Button>

              <Button color="error" onClick={handleDeleteTaskGroup}>
                {t("adminTaskGroup.actions.deleteTaskGroup")}
              </Button>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="px-16">
          <DragAndDrop
            className="text-body-3"
            onSort={(newOrder) => onTaskReorder(newOrder, taskGroup)}
            itemsList={taskGroup.tasks.map((task: Task) => ({
              id: task.id,
              element: (
                <SortableItem key={task.id} id={task.id}>
                  {(props) => (
                    <div
                      className="text-body-3 group/child mt-16 flex cursor-default"
                      ref={props.setNodeRef}
                      style={props.style}
                      {...props.attributes}
                    >
                      <div className="flex w-full">
                        <span className="m-auto w-[60px]">
                          <button
                            {...props.listeners}
                            className="ml-2 hidden rounded-[12px] border-[1px] border-gray p-2 text-gray group-hover/child:block"
                          >
                            <Handle />
                          </button>
                        </span>
                        <div className="w-full">
                          <AdminTask
                            refreshTask={refreshTask}
                            key={task.id}
                            task={task}
                            caseId={caseId}
                            onEditTask={onEditTask}
                            setTaskAssignment={setTaskAssignment}
                            taskAssignmentStatus={taskAssignmentStatus}
                            caseParticipants={caseParticipants}
                            deleteTaskAssignment={deleteTaskAssignment}
                            deleteTask={deleteTask}
                            issueDocument={issueDocument}
                            tasksRoles={tasksRoles}
                            getRequiredRoles={getRequiredRoles}
                            isLoadingRoles={isLoadingRoles}
                            caseLoginUsers={caseLoginUsers}
                            refreshElectronicDocumentSession={
                              refreshElectronicDocumentSession
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </SortableItem>
              ),
            }))}
          />
        </div>
      </AccordionDetails>
    </AccordionContainer>
  );
};
