import { z } from "zod";
import {
  phoneNumberValidation,
  requiredListValidation,
  requiredString,
  websiteValidation,
} from "@shared/validation-schemas";

export const validationSchema = z.object({
  name: requiredString,
  website: websiteValidation,
  contactEmail: z.string().email(),
  contactPhone: phoneNumberValidation,
  contactPhoneExt: z.string().optional(),
  states: requiredListValidation,
  orgType: z.string(),
  services: requiredListValidation,
  description: z.string().optional(),
});

export type FormValues = z.infer<typeof validationSchema>;
