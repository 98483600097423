import { flexRender, Table } from "@tanstack/react-table";
import { Box, IconButton, TableCell, TableHead, TableRow } from "@mui/material";

import ArrowIcon from "@pairtreefamily/ui/src/components/icons/Arrow";

import { getIconButtonStyles, styles } from "./styles";

export type TableProps<T> = {
  table: Table<T>;
};

export function TableHeader<T>({ table }: TableProps<T>) {
  return (
    <TableHead>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableCell
              sx={styles.tableCell}
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
            >
              <Box component="span" sx={styles.wrapper}>
                <span>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </span>

                {header.column.getCanSort() && (
                  <IconButton
                    sx={getIconButtonStyles({
                      isSorted: header.column.getIsSorted(),
                    })}
                  >
                    <ArrowIcon />
                  </IconButton>
                )}
              </Box>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
}
