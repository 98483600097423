import { useCallback, useState } from "react";
import { useTranslation } from "next-i18next";
import { IssuerElectronicDocumentRow, TaskItem } from "@shared/components";
import { TaskAssignmentModal } from "./task-assignment-modal";
import { TaskTitle } from "@shared/components";
import { Button } from "@pairtreefamily/ui";
import { AdminTaskAssignment } from "./AdminTaskAssignment";
import { AdminTools } from "../hooks/useAdminTools";
import { useExpirationStatus } from "@shared/components";

import type { ElectronicDocumentTask, Task, CaseLoginUser } from "@_types/*";
import type { TasksWithTemplateRoles } from "../types";

export type AdminTaskProps = {
  task: Task;
  caseId: string;
  onEditTask: (task: Task) => void;
  setTaskAssignment: AdminTools["setTaskAssignment"];
  taskAssignmentStatus: AdminTools["taskAssignmentStatus"];
  caseParticipants: AdminTools["caseParticipants"];
  deleteTaskAssignment: AdminTools["deleteTaskAssignment"];
  deleteTask: AdminTools["deleteTask"];
  issueDocument: AdminTools["issueDocument"];
  getRequiredRoles: (task: ElectronicDocumentTask[]) => void;
  tasksRoles: TasksWithTemplateRoles[];
  isLoadingRoles: boolean;
  caseLoginUsers: CaseLoginUser[];
  refreshTask: AdminTools["refreshTask"];
  refreshElectronicDocumentSession: AdminTools["refreshElectronicDocumentSession"];
};

export const AdminTask = (props: AdminTaskProps) => {
  const {
    task,
    onEditTask,
    setTaskAssignment,
    taskAssignmentStatus,
    caseParticipants,
    deleteTaskAssignment,
    deleteTask,
    issueDocument,
    getRequiredRoles,
    tasksRoles,
    isLoadingRoles,
    caseLoginUsers,
    refreshTask,
    refreshElectronicDocumentSession,
  } = props;

  const { t } = useTranslation("admin");

  const [isTaskAssignmentModalOpen, setIsTaskAssignmentModalOpen] =
    useState(false);

  const expiredStatus = useExpirationStatus(task.expirationDate);

  const [isIssueDocumentLoading, setIsIssueDocumentLoading] = useState(false);

  const issueBtnLabel =
    task.taskAssignments.some((a) => a.pandadocSession) ||
    task.status === "completed" ||
    task.status === "sent"
      ? t("adminTask.actions.reissue")
      : t("adminTask.actions.issue");

  const handleIssueTask = useCallback(async () => {
    setIsIssueDocumentLoading(true);
    await issueDocument({ task });
    setIsIssueDocumentLoading(false);
  }, [issueDocument, task]);

  const handleOpenTaskAssigmentModal = useCallback(async () => {
    setIsTaskAssignmentModalOpen(true);
    getRequiredRoles(task.taskType === "electronicDocument" ? [task] : []);
  }, [task, getRequiredRoles]);

  return (
    <>
      <div className="grid grid-cols-3 border-b-2 border-lightgray pb-8">
        <div className="col-span-2">
          <p className="flex pb-2">
            <span className="mr-4 inline-block w-[98px] text-gray">
              {t("adminTask.rows.title")}
            </span>
            <strong>
              <TaskTitle task={task} />
            </strong>
          </p>
          <p className="pb-2">
            <span className="mr-4 inline-block w-[98px] text-gray">
              {" "}
              {t("adminTask.rows.id")}
            </span>
            <strong>{task.id}</strong>
          </p>
          <p className="pb-2">
            <span className="mr-4 inline-block w-[98px] text-gray">
              {t("adminTask.rows.taskType")}
            </span>
            <strong>{task.taskType}</strong>
          </p>
          <div>
            {task.taskType === "fileUpload" && (
              <div className="flex">
                <span className="mr-4 inline-block w-[98px] text-gray">
                  {t("adminTask.rows.uploadedFiles")}
                </span>
                <strong>{task.taskFiles.length}</strong>
              </div>
            )}

            <div className="flex">
              <span className="mr-4 inline-block w-[98px] text-gray">
                {task.taskType === "reference"
                  ? t("adminTask.rows.referenceSentTo")
                  : t("adminTask.rows.referenceAssignments")}
              </span>
              {task.taskAssignments.length === 0 && (
                <strong>{t("adminTask.emptyTaskAssignments")}</strong>
              )}
              <div>
                {task.taskAssignments.map((a) => (
                  <AdminTaskAssignment
                    key={a.id}
                    taskAssignment={a}
                    deleteTaskAssignment={() => {
                      deleteTaskAssignment({ taskAssignmentId: a.id });
                    }}
                  />
                ))}
              </div>
            </div>

            {task.documentIssueStatus != null && (
              <div className="flex">
                <span className="mr-4 inline-block w-[98px] text-gray">
                  {t("adminTask.rows.error")}
                </span>
                <div className="max-w-[850px]">
                  <strong>{task.documentIssueError}</strong>
                </div>
              </div>
            )}

            <div className="flex">
              <span className="mr-4 inline-block w-[98px] text-gray">
                {t("adminTask.rows.status")}
              </span>
              <strong>{task.status}</strong>
              {expiredStatus && <strong>&nbsp;({expiredStatus})</strong>}
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-wrap gap-4">
            {task.taskType === "electronicDocument" && (
              <>
                <Button
                  color="warning"
                  variant="contained"
                  isLoading={taskAssignmentStatus === "loading"}
                  onClick={handleOpenTaskAssigmentModal}
                >
                  {t("adminTask.actions.assign")}
                </Button>

                <Button
                  onClick={handleIssueTask}
                  isLoading={isIssueDocumentLoading}
                >
                  {issueBtnLabel}
                </Button>
              </>
            )}

            <Button
              color="error"
              variant="outlined"
              onClick={() => onEditTask(task)}
            >
              {t("adminTask.actions.edit")}
            </Button>

            <Button
              color="error"
              onClick={async () => {
                await deleteTask(task);
              }}
            >
              {t("adminTask.actions.delete")}
            </Button>
          </div>
        </div>

        <TaskAssignmentModal
          tasks={[task]}
          parents={caseParticipants?.ok ? caseParticipants.content.parents : []}
          setTaskAssignment={async (args) => {
            const res = await setTaskAssignment({
              caseId: props.caseId,
              taskId: props.task.id,
              person: args.person,
              role: args.role,
            });
            return res.ok;
          }}
          tasksRoles={tasksRoles}
          currentAssignees={task.taskAssignments}
          isOpen={isTaskAssignmentModalOpen}
          onClose={() => setIsTaskAssignmentModalOpen(false)}
          isLoadingRoles={isLoadingRoles}
          caseLoginUsers={caseLoginUsers}
        />
      </div>
      {/* TODO - using entire IssuerElectronicDocumentRow here; it's likely that we only want a subset for admin issuers */}
      {task.taskType === "electronicDocument" &&
        task.TaskGroup?.providerOnly && (
          <div className="w-full border-2 border-darkblue p-2">
            <p className="text-overline-2">
              {t("adminTask.rows.electronicDocument")}
            </p>
            <TaskItem
              task={task}
              row={() =>
                IssuerElectronicDocumentRow({
                  refreshTask,
                  task,
                  issueDocument: (task) => issueDocument({ task }),
                  // TODO intentionally misusing this to try and resolve merge
                  // conflict. Update asap
                  setTaskManagerModalOpen: (val) => {
                    val;
                  },
                  setCurrentTaskBeingManaged: (task) => onEditTask(task),
                  refreshElectronicDocumentSession:
                    refreshElectronicDocumentSession,
                })
              }
              idx={0}
            />
          </div>
        )}
    </>
  );
};
