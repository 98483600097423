import {
  Button,
  ErrorCard,
  Loading,
  useBooleanState,
} from "@pairtreefamily/ui";
import { Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";
import { NewThread } from "../new-thread/NewThread";
import { memo, useCallback, useState } from "react";
import {
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { initialPagination, initialSorting } from "./constants";
import { useColumns, useTableData } from "./hooks";
import { Table } from "../table";
import { SearchBox, TablePagination } from "@shared/components";
import { useGetSearchThreadsCount, useGetThreads } from "@api";
import { ThreadListingRow } from "./types";
import { ThreadDetails } from "../thread-details";
import { styles } from "./styles";

export const Threads = memo(() => {
  const { t } = useTranslation("safetyCenter");
  const newThreadState = useBooleanState();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedThreadId, setSelectedThreadId] = useState<string | null>(null);

  const {
    data: threadsCountData,
    isLoading: isLoadingCount,
    refetch: refetchThreadsCount,
  } = useGetSearchThreadsCount(searchQuery);

  const handleSearchChange = useCallback((value: string) => {
    setPagination(initialPagination);
    setSearchQuery(value);
  }, []);

  const [sorting, setSorting] = useState<SortingState>(initialSorting);
  const [pagination, setPagination] =
    useState<PaginationState>(initialPagination);

  const {
    data: threadsData,
    isLoading,
    isError,
    refetch: refetchTheads,
  } = useGetThreads({
    query: {
      limit: pagination.pageSize,
      page: pagination.pageIndex,
    },
    body: { searchQuery },
  });

  const columns = useColumns();

  const threadsList = threadsData?.items;

  const tableData = useTableData(threadsList || []);

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: true,
    enableSortingRemoval: false,
    enableMultiSort: false,
    manualSorting: true,
    manualPagination: true,
    manualFiltering: true,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
  });

  const refetch = useCallback(async () => {
    await refetchTheads();
    await refetchThreadsCount();
  }, [refetchTheads, refetchThreadsCount]);

  const handlePageChange = useCallback((pageIndex: number) => {
    setPagination((prevState) => ({ ...prevState, pageIndex }));
  }, []);

  const handleRowClick = useCallback(({ id }: ThreadListingRow) => {
    setSelectedThreadId(id);
  }, []);

  const handleThreadDetailsClose = useCallback(() => {
    setSelectedThreadId(null);
  }, []);

  const threadsCount = threadsCountData?.count || 0;

  return (
    <Box sx={styles.mainWrapper}>
      <Box sx={styles.topActionsWrapper}>
        <Typography variant="input">
          {searchQuery && !isLoadingCount && (
            <Trans
              t={t}
              i18nKey={"resultsFound"}
              values={{ count: threadsCount }}
              components={[
                <Typography
                  component="span"
                  key={0}
                  variant="body3"
                  color="primary.main"
                />,
              ]}
            />
          )}
        </Typography>

        <Box display="flex" gap={1.5}>
          <Box width={320}>
            <SearchBox
              searchQuery={searchQuery}
              setSearchQuery={handleSearchChange}
              placeholder={t("searchPlaceholder")}
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={newThreadState.turnOn}
          >
            {t("newThreadButton")}
          </Button>
        </Box>
      </Box>

      {!isError && (
        <Box mt={3}>
          <Table onRowClick={handleRowClick} table={table} />
        </Box>
      )}

      {isError && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box mt={6} maxWidth={400}>
            <ErrorCard error={"Failed to receive threads list"} />
          </Box>
        </Box>
      )}

      {isLoading && <Loading />}

      {threadsData && threadsCount === 0 && (
        <Box sx={styles.noResultsWrapper}>
          <Typography variant="head6">{t("threadsTable.noResults")}</Typography>

          {!searchQuery && (
            <Typography variant="body6">
              {t("threadsTable.noThreads")}
            </Typography>
          )}

          {!!searchQuery && (
            <Typography
              textAlign="center"
              whiteSpace="pre-line"
              variant="body6"
            >
              {t("threadsTable.noThreadsFound")}
            </Typography>
          )}
        </Box>
      )}

      {threadsData && !!threadsCount && (
        <Box sx={styles.paginationWrapper}>
          <TablePagination
            pageIndex={pagination.pageIndex}
            pageSize={pagination.pageSize}
            totalCount={threadsCount}
            onPageChange={handlePageChange}
          />
        </Box>
      )}

      <NewThread
        open={newThreadState.isOn}
        onClose={newThreadState.turnOff}
        refetchThreads={refetch}
      />

      <ThreadDetails
        threadId={selectedThreadId || ""}
        open={!!selectedThreadId}
        onClose={handleThreadDetailsClose}
      />
    </Box>
  );
});

Threads.displayName = "Threads";
