import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import type { ExpiringTask } from "@api";
import { getFullName } from "@pairtreefamily/utils";
import Link from "next/link";
import { isPast } from "date-fns";
import { Typography } from "@mui/material";
import { taskTypeLabelsMap } from "@shared/configs";
import { formatDateToView } from "@shared/utils";

const columnHelper = createColumnHelper<ExpiringTask>();

export const useColumns = () => {
  const { t } = useTranslation(["professional", "components"]);

  return useMemo(() => {
    return [
      columnHelper.accessor("caseId", {
        header: t("professional:taskExpirationTable.headers.familyName"),
        cell: (cell) => {
          return (
            <Link
              className="group text-onyx hover:text-teal"
              href={cell.row.original.caseId || ""}
              target="_blank"
            >
              <div className="font-extrabold">
                {cell.row.original.firstParentName}
              </div>

              <div className="text-onyx-light group-hover:text-teal">
                {cell.row.original.secondParentName}
              </div>
            </Link>
          );
        },
        sortingFn: (a, b) => {
          const fullNameA = getFullName(
            a.original.firstParentName,
            a.original.secondParentName
          );
          const fullNameB = getFullName(
            b.original.firstParentName,
            b.original.secondParentName
          );

          if (fullNameA > fullNameB) {
            return 1;
          }

          return -1;
        },
      }),
      columnHelper.accessor("taskGroupName", {
        header: t("professional:taskExpirationTable.headers.taskGroup"),
        cell: (cell) => <>{cell.getValue()}</>,
        enableSorting: false,
      }),
      columnHelper.accessor("taskName", {
        header: t("professional:taskExpirationTable.headers.taskName"),
        cell: (cell) => <>{cell.getValue()}</>,
        enableSorting: false,
      }),
      columnHelper.accessor("taskType", {
        header: t("professional:taskExpirationTable.headers.taskType"),
        cell: (cell) => <>{taskTypeLabelsMap[cell.getValue()] || "-"}</>,
        enableSorting: false,
      }),
      columnHelper.accessor("expiration", {
        header: t("professional:taskExpirationTable.headers.expiration"),
        cell: (cell) => {
          const expirationDate = cell.getValue();
          const isExpired = isPast(expirationDate || new Date());

          return (
            <Typography
              component="div"
              variant="body6"
              color={isExpired ? "error.main" : "text.secondary"}
            >
              {cell.getValue() ? formatDateToView(cell.getValue()) : "-"}
            </Typography>
          );
        },
        enableSorting: true,
      }),
      columnHelper.accessor("status", {
        header: t("professional:taskExpirationTable.headers.status"),
        cell: (cell) => (
          <>{t(`components:statusElement.statuses.${cell.getValue()}`)}</>
        ),
        enableSorting: false,
      }),
    ];
  }, [t]);
};
