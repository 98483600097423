import { Box, Typography } from "@mui/material";
import {
  Button,
  Form,
  TextField,
  useSnackbarNotification,
} from "@pairtreefamily/ui";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { FormValues, validationSchema } from "./validation-schema";
import { useSendOrganizationInvite } from "@api";
import { useCallback } from "react";

export function NewInvitation() {
  const sendOrganizationInvite = useSendOrganizationInvite();
  const { callSnackbar } = useSnackbarNotification();
  const { t } = useTranslation("organization");

  const formMethods = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const handleSubmit = useCallback(
    async ({ email }: FormValues) => {
      try {
        await sendOrganizationInvite({
          email,
          isFamily: false,
          isAdmin: false,
        });

        formMethods.reset();
      } catch (error) {
        callSnackbar({
          message: t("membersTab.invitation.notifications.failedToInvite"),
          type: "error",
        });
      }
    },
    [callSnackbar, formMethods, sendOrganizationInvite, t]
  );

  return (
    <Box mt={6}>
      <Typography variant="body3" component="h3">
        {t("membersTab.invitation.newInvitation.title")}
      </Typography>
      <Typography variant="input" component="p">
        {t("membersTab.invitation.newInvitation.caption")}
      </Typography>

      <Box mt={2} maxWidth="720px">
        <Form<FormValues> formMethods={formMethods} onSubmit={handleSubmit}>
          <Box display="flex" gap={3}>
            <TextField
              name="email"
              placeholder={t(
                "membersTab.invitation.newInvitation.emailPlaceholder"
              )}
            ></TextField>

            <Box flexShrink={0}>
              <Button
                type="submit"
                isLoading={formMethods.formState.isSubmitting}
                disabled={formMethods.formState.isSubmitting}
              >
                {t("membersTab.invitation.newInvitation.button")}
              </Button>
            </Box>
          </Box>
        </Form>
      </Box>
    </Box>
  );
}
