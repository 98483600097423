import { State } from "@_types/*";

export enum FamilyListingField {
  Slug = "slug",
  FamName = "famName",
  State = "state",
  ConnectStatus = "connectStatus",
  HomeStudyVerified = "homeStudyVerified",
  ConnectProfileLink = "connectProfileLink",
  ProfileBook = "profileBook",
  VideoUrl = "videoUrl",
  Actions = "actions",
  Uuid = "uuid",
  IsOrgAssociated = "isOrgAssociated",
  Email = "email",
}

export type FamilyListingRow = {
  [FamilyListingField.Slug]: string;
  [FamilyListingField.FamName]: string[];
  [FamilyListingField.State]: State;
  [FamilyListingField.ConnectStatus]: boolean;
  [FamilyListingField.HomeStudyVerified]: boolean;
  [FamilyListingField.ConnectProfileLink]: string;
  [FamilyListingField.ProfileBook]: string;
  [FamilyListingField.VideoUrl]: string;
  [FamilyListingField.Actions]?: string;
  [FamilyListingField.Uuid]: string;
  [FamilyListingField.IsOrgAssociated]: boolean;
  [FamilyListingField.Email]: string;
};

export type FamilyFilters = {
  archetype: string[];
  education: string[];
  familyStructure: string[];
  raceEthnicity: string[];
  religion: string[];
  politicalAffiliation: string[];
  state: string[];
  openTo: string[];
  adoptionRelationshipPreference: string[];
  approvedHomeStudy: boolean;
  hasProfileBook: boolean;
};

export type FamilyFiltersFields = Partial<FamilyFilters>;
