import { Avatar } from "../../../../../avatar/Avatar";
import {
  Form,
  SendIcon,
  TextAreaField,
  useSnackbarNotification,
} from "@pairtreefamily/ui";
import { useForm } from "react-hook-form";
import { useCallback, useMemo } from "react";
import { Box, IconButton } from "@mui/material";
import { useSendComment } from "@api";
import { zodResolver } from "@hookform/resolvers/zod";
import { NewCommentFormValues, validationSchema } from "./validation-schema";
import CircularProgress from "@mui/material/CircularProgress";
import { styles } from "./styles";
import { useTranslation } from "next-i18next";
import { useOrganizationsData, useUserData } from "@shared/components";

type NewCommentProps = {
  threadId: string;
  onCommentAdded: () => void;
};

export const NewComment = ({ threadId, onCommentAdded }: NewCommentProps) => {
  const { callSnackbar } = useSnackbarNotification();
  const { t } = useTranslation("safetyCenter");
  const formMethods = useForm({
    defaultValues: {
      text: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const sendComment = useSendComment(threadId);

  const { userOrganizationData } = useOrganizationsData();
  const { userData } = useUserData();

  const userInfo = useMemo(() => {
    const orgUuid = userOrganizationData?.id;
    const org = userData?.proDetails?.org_memberships.find(
      (membership) => membership.org_uuid === orgUuid
    );

    return {
      firstName: org?.first_name ?? "",
      lastName: org?.last_name ?? "",
    };
  }, [userData?.proDetails?.org_memberships, userOrganizationData?.id]);

  const handleSubmit = useCallback(
    async ({ text }: NewCommentFormValues) => {
      try {
        await sendComment({ description: text.trim() });

        onCommentAdded();

        formMethods.reset({ text: "" });
      } catch (e) {
        callSnackbar({
          message: t("threadDetails.comments.errorNewComment"),
          type: "error",
        });
      }
    },
    [callSnackbar, formMethods, sendComment, t, onCommentAdded]
  );

  const { isSubmitting, isValid } = formMethods.formState;

  return (
    <Box sx={styles.wrapper}>
      <Avatar {...userInfo} url={""} />

      <Form sx={styles.form} formMethods={formMethods} onSubmit={handleSubmit}>
        <TextAreaField
          name="text"
          maxRows={2}
          sx={styles.textArea}
          placeholder={t("threadDetails.comments.placeholder")}
          InputProps={{
            endAdornment: (
              <IconButton
                type="submit"
                disabled={isSubmitting || !isValid}
                sx={styles.iconButton}
                size="small"
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <SendIcon />
                )}
              </IconButton>
            ),
          }}
        />
      </Form>
    </Box>
  );
};
