import { useMemo } from "react";
import { useRouter } from "next/router";

export const useGetCaseId = () => {
  const router = useRouter();

  return useMemo(() => {
    const urlCaseId = router.query.caseId;

    return Array.isArray(urlCaseId) ? urlCaseId[0] : urlCaseId;
  }, [router.query.caseId]);
};
