import { useCallback, useMemo } from "react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  TextField,
  SelectField,
  AutocompleteField,
  FormModal,
} from "@pairtreefamily/ui";

import { useGetTemplateFolders } from "@api";
import { appConfig } from "@lib";

import { useGetTemplateFoldersOptions } from "./hooks";
import { defaultCaseValues } from "./constants";
import { updateFormValues } from "./utils";
import { validationSchema, FormValues } from "./validation-schema";

import type { Case } from "@_types/*";
import { states } from "@shared/constants";

type CaseToUpsert = Omit<Case, "id"> & { id?: string };

export type UpsertCaseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  upsertCase: (args: CaseToUpsert) => Promise<boolean>;
  currentCase?: Case; // Only exists when editing
};

export function UpsertCaseModal({
  currentCase,
  upsertCase,
  isOpen,
  onClose,
}: UpsertCaseModalProps) {
  const { t } = useTranslation(["admin", "common"]);

  const { data: templateFoldersResult } = useGetTemplateFolders({
    caseId: currentCase?.id ?? "",
    rootFolderId: appConfig.pandaDocRootTemplateFolder,
  });

  const templateFolderOptions = useGetTemplateFoldersOptions(
    templateFoldersResult?.ok ? templateFoldersResult?.content : undefined
  );

  const formMethods = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: defaultCaseValues,
    values: updateFormValues(currentCase),
    resolver: zodResolver(validationSchema),
  });

  const { watch, reset } = formMethods;

  const defaultTemplatesFolder = watch("defaultTemplatesFolder");

  const handleModalClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await upsertCase({
        ...(currentCase?.id && { id: currentCase.id }),
        ...values,
        expiresAt: null,
        approvedAt: null,
        completedAt: null,
        quickNote: currentCase?.quickNote ?? "",
        hidden: currentCase?.hidden ?? false,
        active: currentCase?.active ?? true,
      });

      handleModalClose();
    },
    [
      currentCase?.id,
      currentCase?.quickNote,
      currentCase?.hidden,
      currentCase?.active,
      handleModalClose,
      upsertCase,
    ]
  );

  const modalTitle = t(
    currentCase
      ? "admin:upsertCaseModal.titles.editCase"
      : "admin:upsertCaseModal.titles.createCase"
  );

  const isSelectedTemplateFolderHasItems = useMemo(() => {
    return (
      templateFoldersResult?.ok &&
      templateFoldersResult.content.some(
        (folder) => folder.uuid === defaultTemplatesFolder && folder.hasItems
      )
    );
  }, [defaultTemplatesFolder, templateFoldersResult]);

  return (
    <FormModal
      submitLabel={t("common:actions.submit")}
      formMethods={formMethods}
      onSubmit={handleSubmit}
      title={modalTitle}
      open={isOpen}
      onClose={handleModalClose}
    >
      <SelectField
        name="state"
        options={states}
        label={t("admin:upsertCaseModal.fields.state.label")}
        placeholder={t("admin:upsertCaseModal.fields.state.placeholder")}
      />

      <TextField
        name="parentFirstName"
        label={t("admin:upsertCaseModal.fields.parentFirstName.label")}
      />

      <TextField
        name="parentLastName"
        label={t("admin:upsertCaseModal.fields.parentLastName.label")}
      />

      <TextField
        name="secondParentFirstName"
        label={t("admin:upsertCaseModal.fields.secondParentFirstName.label")}
      />

      <TextField
        name="secondParentLastName"
        label={t("admin:upsertCaseModal.fields.secondParentLastName.label")}
      />

      <TextField
        name="familyEmail"
        label={t("admin:upsertCaseModal.fields.familyEmail.label")}
      />

      <TextField
        name="completedDocumentsLocation"
        label={t(
          "admin:upsertCaseModal.fields.completedDocumentsLocation.label"
        )}
      />

      <TextField
        name="privateDocumentsLocation"
        label={t("admin:upsertCaseModal.fields.privateDocumentsLocation.label")}
      />

      <TextField
        name="referenceTemplateUuid"
        label={t("admin:upsertCaseModal.fields.referenceTemplateUuid.label")}
      />

      <AutocompleteField
        name="defaultTemplatesFolder"
        options={templateFolderOptions}
        label={t("admin:upsertCaseModal.fields.defaultTemplatesFolder.label")}
      />

      {!isSelectedTemplateFolderHasItems && (
        <p className="max-w-[550px] text-left text-rust">
          {t("admin:upsertCaseModal.errors.providerFolderEmpty")}
        </p>
      )}

      <TextField
        name="referenceAuthorPandadocRole"
        label={t(
          "admin:upsertCaseModal.fields.referenceAuthorPandadocRole.label"
        )}
      />
    </FormModal>
  );
}
