import { SyntheticEvent, useCallback, useContext } from "react";
import { useTranslation } from "next-i18next";

import { Box, Tab, Tabs, Typography } from "@mui/material";

import { ConnectTabEnum } from "../../types";
import { palette } from "@pairtreefamily/ui";
import { FamilyPendingInvitationsContext } from "../pending-invitations";

type OrganizationTabsProps = {
  value: number;
  onChange: (newValue: ConnectTabEnum) => void;
};

export const ConnectTabs = ({ value, onChange }: OrganizationTabsProps) => {
  const { t } = useTranslation("connect");

  const { familyInvitations } = useContext(FamilyPendingInvitationsContext);

  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: ConnectTabEnum) => {
      onChange(newValue);
    },
    [onChange]
  );

  const invitationsTabLabel = (
    <Box>
      <Typography variant="body4" component="span">
        {t("tabs.pendingInvitations")}
      </Typography>
      <Typography
        variant="caption2"
        border={`1px solid ${palette.divider}`}
        ml={1}
        component="span"
        py={0.5}
        px={1}
        borderRadius="3px"
      >
        {familyInvitations.length}
      </Typography>
    </Box>
  );

  return (
    <Tabs value={value} onChange={handleChange}>
      <Tab
        label={t("tabs.familyListings")}
        id={`tab-${ConnectTabEnum.FamilyListings}`}
      />
      <Tab
        label={invitationsTabLabel}
        id={`tab-${ConnectTabEnum.PendingInvitations}`}
      />
    </Tabs>
  );
};
