import { Autocomplete, SelectOption } from "@pairtreefamily/ui";
import { useMemo } from "react";
import { useTranslation } from "next-i18next";
import { Case } from "@_types/*";

type FamilySelectorDropDownProps = {
  cases: Case[];
  selectedCase?: Case;
  onCaseChange: (id: string | null) => void;
};

export function FamilySelectorDropDown(props: FamilySelectorDropDownProps) {
  const { cases, selectedCase, onCaseChange } = props;

  const { t } = useTranslation("components");

  const caseOptions: SelectOption[] = useMemo(() => {
    return cases
      .map((c) => {
        const name: string[] = [];
        name.push(`${c.parentLastName}, ${c.parentFirstName}`);
        if (c.secondParentFirstName) {
          name.push(`${c.secondParentLastName}, ${c.secondParentFirstName}`);
        }
        return {
          id: c.id,
          label: name.join(t("familySelectorDropdown.separator")),
        };
      })
      .sort((a, b) => {
        const nameA = a.label.toLowerCase(),
          nameB = b.label.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
  }, [cases, t]);

  return (
    <Autocomplete
      value={selectedCase?.id ?? null}
      options={caseOptions}
      onChange={onCaseChange}
      placeholder={t("familySelectorDropdown.placeholder")}
    />
  );
}
