import { useTranslation } from "react-i18next";
import { PendingInvitation } from "@api";
import { useCallback, useState } from "react";
import { PortalPendingInvitations } from "@shared/components";
import { Box, Typography } from "@mui/material";

type PendingInvitationsProps = {
  invitations?: PendingInvitation[];
  onResend: (uuid: string) => Promise<void>;
  onRevoke: (uuid: string) => Promise<void>;
};

export function PendingInvitations({
  invitations,
  onResend,
  onRevoke,
}: PendingInvitationsProps) {
  const { t } = useTranslation("organization");
  const [resendingInviteUuid, setResendingInviteUuid] = useState<string | null>(
    null
  );

  const [revokingInviteUuid, setRevokingInviteUuid] = useState<string | null>(
    null
  );

  const handleResend = useCallback(
    async (invitation: PendingInvitation) => {
      setResendingInviteUuid(invitation.uuid);
      await onResend(invitation.uuid);
      setResendingInviteUuid(null);
    },
    [onResend]
  );

  const handleRevoke = useCallback(
    async (invitation: PendingInvitation) => {
      setRevokingInviteUuid(invitation.uuid);
      await onRevoke(invitation.uuid);
      setRevokingInviteUuid(null);
    },
    [onRevoke]
  );

  return (
    <Box mt={7}>
      <Typography variant="head6">
        {t("membersTab.invitation.pendingInvitations.title")}
      </Typography>
      <PortalPendingInvitations
        invitations={invitations}
        onResend={handleResend}
        onRevoke={handleRevoke}
        resendingInviteUuid={resendingInviteUuid}
        revokingInviteUuid={revokingInviteUuid}
      />
    </Box>
  );
}
