import { Modal } from "@pairtreefamily/ui";
import { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useAdminTools } from "../hooks/useAdminTools";
import { Case } from "@_types/*";

interface ToggleCaseVisibilityModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  caseId: string;
  isCurrentCaseHidden: boolean;
  currentCaseId: string;
  showHiddenCases: boolean;
  cases: Case[];
}

export const ToggleCaseVisibilityModal = ({
  isOpen,
  setIsOpen,
  caseId,
  isCurrentCaseHidden,
  currentCaseId,
  showHiddenCases,
  cases,
}: ToggleCaseVisibilityModalProps) => {
  const { hideCase } = useAdminTools(caseId);
  const router = useRouter();
  const { t } = useTranslation("admin");

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const submitButton = useMemo(() => {
    return {
      label: t("toggleCaseVisibilityModal.action"),
      onClick: async () => {
        setIsOpen(false);
        handleClose();
        const res = await hideCase(!isCurrentCaseHidden);
        if (res.ok) {
          const value = cases.find((item) => item.id === currentCaseId);
          if (value) value.hidden = !isCurrentCaseHidden;
          if (!showHiddenCases) {
            router.replace(
              `/admin/${
                cases.filter(
                  (item) =>
                    item.hidden ===
                    (localStorage.getItem("showHiddenCases") === "true")
                )[0].id
              }`,
              undefined,
              { shallow: true }
            );
          }
        }
      },
    };
  }, [
    cases,
    currentCaseId,
    handleClose,
    hideCase,
    isCurrentCaseHidden,
    router,
    setIsOpen,
    showHiddenCases,
    t,
  ]);

  return (
    <Modal
      title={t("toggleCaseVisibilityModal.title")}
      open={isOpen}
      onClose={handleClose}
      submitButton={submitButton}
    >
      <div className="w-full max-w-[600px]">
        <p className="mb-8">
          <Trans
            t={t}
            i18nKey={
              isCurrentCaseHidden
                ? "toggleCaseVisibilityModal.warningUnhide"
                : "toggleCaseVisibilityModal.warningHide"
            }
            components={[<strong key="0" />]}
          />
        </p>
      </div>
    </Modal>
  );
};
