import { useMemo } from "react";
import { newProviderKey } from "./constants";
import { Candidate } from "./types";
import { sortByKey } from "@shared/utils";
import { useTranslation } from "next-i18next";

export const useGetProviderOptions = (candidates: Candidate[]) => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    if (!candidates?.length) {
      return [];
    }

    const providersList = candidates.map((p) => ({
      id: p.email,
      label: `${p.firstName} ${p.lastName} (${p.email})`,
    }));

    return [
      {
        id: newProviderKey,
        label: t("providerAssignmentModal.newProviderPlaceholder"),
      },
      ...sortByKey(providersList, "label"),
    ];
  }, [candidates, t]);
};
