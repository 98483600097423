import { forwardRef, Fragment } from "react";
import { useTranslation } from "next-i18next";

import { Box, Typography } from "@mui/material";

import { styles } from "./styles";
import { DefaultTableRowRenderer } from "./RowRenderers";

export type TableRowData = {
  key: string;
  values?: any[];
  renderValue?: (value: any) => JSX.Element;
};

export type ContentTableProps = {
  title?: string;
  rows: TableRowData[];
};

export const TableWrapper = forwardRef<HTMLDivElement, ContentTableProps>(
  ({ title, rows }, ref) => {
    const { t } = useTranslation("connect");

    const renderValues = (row: TableRowData) => {
      if (!row.values || !Boolean(row.values.length)) {
        return (
          <Typography variant="body6" sx={styles.tableValueItem}>
            {t("familyListing.familyDetails.notSpecifiedLabel")}
          </Typography>
        );
      }

      return row.values.map((value, idx) => (
        <Fragment key={idx}>
          {row.renderValue ? (
            row.renderValue(value)
          ) : (
            <DefaultTableRowRenderer value={value} />
          )}
        </Fragment>
      ));
    };

    return (
      <Box ref={ref} sx={styles.wrapper}>
        {title && (
          <Typography variant="head6" sx={styles.title}>
            {title}
          </Typography>
        )}

        <Box sx={styles.table}>
          {rows.map((row) => (
            <Box key={row.key} sx={styles.tableRow}>
              <Typography variant="body4" sx={styles.tableKey}>
                {row.key}
              </Typography>

              <Box sx={styles.tableValueList}>{renderValues(row)}</Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
);

TableWrapper.displayName = "TableWrapper";
