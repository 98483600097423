import { useTranslation } from "next-i18next";

import { Typography } from "@mui/material";
import { TableWrapper } from "../table-wrapper";

import type { ProfessionalBase, FamilyStructure, State } from "@_types/*";

export type AboutProps = {
  adoptionParentsAmount: number;
  adoptionProfessionals: ProfessionalBase[];
  familyStructure?: FamilyStructure[];
  isProPairTreePartner: boolean;
  state: State;
};

export const About = (props: AboutProps) => {
  const {
    adoptionParentsAmount,
    adoptionProfessionals,
    familyStructure,
    isProPairTreePartner,
    state,
  } = props;

  const { t } = useTranslation("connect");

  const titleKey = t(
    `familyListing.familyDetails.about.title.${
      adoptionParentsAmount > 1 ? "aboutUs" : "aboutMe"
    }`
  );

  const detailsGroup = [
    {
      key: t(
        "familyListing.familyDetails.about.table.rows.adoptionProfessionals"
      ),
      values: adoptionProfessionals,
      renderValue: (item: ProfessionalBase) => (
        <>
          {isProPairTreePartner && (
            <Typography key={`org-${item.uuid}`} variant="body9">
              {item.organizationName}
            </Typography>
          )}

          {!isProPairTreePartner && item.organizationName && (
            <Typography key={`org-${item.uuid}`} variant="body9">
              {t(
                "familyListing.familyDetails.about.table.rows.noPairTreePartner"
              )}
            </Typography>
          )}
        </>
      ),
    },
    {
      key: t("familyListing.familyDetails.about.table.rows.familyStructure"),
      values: familyStructure || [],
    },
    {
      key: t("familyListing.familyDetails.about.table.rows.state"),
      values: [state],
    },
  ];

  return <TableWrapper title={titleKey} rows={detailsGroup} />;
};
