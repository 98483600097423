import { OrganizationMember } from "@api";

export const getDefaultValues = (member: OrganizationMember | null) => ({
  proUuid: member?.proUuid || "",
  firstName: member?.firstName || "",
  lastName: member?.lastName || "",
  title: member?.title || "",
  contactEmail: member?.contactEmail || "",
  contactPhone: member?.contactPhone || "",
  contactPhoneExt: member?.contactPhoneExt || "",
});
