import type { FormValues } from "./validation-schema";

export const defaultCaseValues: FormValues = {
  state: "TX",
  parentFirstName: "",
  parentLastName: "",
  secondParentFirstName: "",
  secondParentLastName: "",
  completedDocumentsLocation: "",
  privateDocumentsLocation: "",
  familyEmail: "",
  referenceTemplateUuid: "",
  referenceAuthorPandadocRole: "",
  defaultTemplatesFolder: "",
};
