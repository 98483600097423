import { useMemo } from "react";
import { transformPersonName } from "../utils";
import { useTranslation } from "next-i18next";
import { newPersonId } from "../constants";

import type { Person, Task, CaseLoginUser } from "@_types/*";

type UseGetPersonOptionsArgs = {
  caseLoginUsers: CaseLoginUser[];
  providerTasks: Task[];
  parents: Person[];
};

export const useGetPersonOptions = ({
  caseLoginUsers,
  providerTasks,
  parents,
}: UseGetPersonOptionsArgs) => {
  const { t } = useTranslation("admin");
  const hasProviderTasks = !!providerTasks?.length;

  return useMemo(() => {
    const options = [];

    if (hasProviderTasks) {
      options.push(
        ...caseLoginUsers.map((item) => {
          return { id: item.id, label: transformPersonName(item) };
        })
      );
    } else {
      options.push(
        ...[
          {
            id: newPersonId,
            label: t("taskAssignmentModal.personSelect.newPerson"),
          },
          ...parents.map((p, i) => ({
            id: `parent-${i + 1}`,
            label: t("taskAssignmentModal.personSelect.existingPerson", {
              person: transformPersonName(p),
            }),
          })),
        ]
      );
    }
    return options;
  }, [caseLoginUsers, hasProviderTasks, parents, t]);
};
