import { Box, Tab, Tabs, Typography } from "@mui/material";
import { SyntheticEvent } from "react";
import { TabWithCounter } from "../tab-with-counter";
import { useTranslation } from "next-i18next";
import { ContentWrapper } from "@shared/components";
import { styles } from "./styles";
import { Switch } from "@pairtreefamily/ui";

export enum TabEnum {
  Families,
  ExpiringTasks,
}

type DashboardTabsProps = {
  selectedTab: TabEnum;
  onTabChange: (event: SyntheticEvent, newValue: TabEnum) => void;
  expiringTasksCount: number;
  showInactive: boolean;
  onShowInactiveChange: (newValue: boolean) => void;
};

export const DashboardTabs = ({
  selectedTab,
  onTabChange,
  expiringTasksCount,
  showInactive,
  onShowInactiveChange,
}: DashboardTabsProps) => {
  const { t } = useTranslation("professional");

  return (
    <ContentWrapper background={styles.wrapperBackground}>
      <Box sx={styles.page}>
        <Tabs value={selectedTab} onChange={onTabChange}>
          <Tab
            label={t("dashboardTable.tabs.adoptiveFamilies")}
            id={`tab-${TabEnum.Families}`}
          />

          <TabWithCounter
            label={t("dashboardTable.tabs.expiringDocuments")}
            id={`tab-${TabEnum.ExpiringTasks}`}
            count={expiringTasksCount}
          />
        </Tabs>

        <Switch
          label={
            <Box display="flex" gap={3} alignItems="center">
              <Typography variant="body4">
                {t("showInactiveFamilies")}
              </Typography>

              <Typography variant="input">
                {t(`showInactiveFamiliesStatus.${showInactive ? "on" : "off"}`)}
              </Typography>
            </Box>
          }
          labelPlacement="start"
          checked={showInactive}
          onChange={onShowInactiveChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Box>
    </ContentWrapper>
  );
};
