import { useCallback } from "react";
import { useTranslation } from "next-i18next";
import { useFamilyTools } from "@shared/hooks";
import { useCase } from "@shared/hooks";
import { useFileUpload } from "@shared/hooks";
import { ExtendedTaskGroup, Task } from "@_types/tasks";
import { getCompletedAndTotalTaskFromTaskGroups } from "@shared/utils";
import { Button } from "@pairtreefamily/ui";
import { TaskGroupView, Page } from "@shared/components";
import { AdoptionTeam } from "../adoption-team/AdoptionTeam";
import { TaskRow } from "../task-row/TaskRow";
import { RadialProgressBar } from "@shared/components";

type FamilyPageViewProps = {
  caseId: string;
};

export function FamilyPageView({ caseId }: FamilyPageViewProps) {
  const { t } = useTranslation("family");

  const {
    taskGroups,
    caseProviders,
    sendReferenceRequest,
    createReferenceTask,
    updateTaskQuestionAnswer,
    getFileUrl,
    getFileData,
    deleteDocument,
    uploadDocument,
    refreshElectronicDocumentSession,
  } = useFamilyTools({
    caseId,
  });
  const { documentHomeLink, isCaseCompleted } = useCase({ caseId });

  const { getFileName, uploadFile, deleteFile } = useFileUpload({
    caseId: caseId,
    getFileData,
    deleteDocument,
    uploadDocument,
  });

  const completedAndTotalTasks = getCompletedAndTotalTaskFromTaskGroups(
    (taskGroups as ExtendedTaskGroup[]) ?? []
  );

  let progress = 0;

  if (completedAndTotalTasks) {
    progress = Math.floor(
      (completedAndTotalTasks?.completed * 100) / completedAndTotalTasks?.total
    );
  }

  const taskRow = useCallback(
    (task: Task) => {
      return TaskRow({
        task,
        caseId,
        updateTaskQuestionAnswer,
        getFileName,
        uploadFile,
        deleteFile,
        documentHomeLink,
        getFileUrl,
        sendReferenceRequest,
        refreshElectronicDocumentSession,
      });
    },
    [
      caseId,
      deleteFile,
      documentHomeLink,
      getFileName,
      getFileUrl,
      sendReferenceRequest,
      updateTaskQuestionAnswer,
      uploadFile,
      refreshElectronicDocumentSession,
    ]
  );

  return (
    <Page>
      <h1 className="text-head-1 py-6 text-center">
        <em>{t("caption")}</em>
      </h1>

      <div className="flex items-center justify-center">
        <AdoptionTeam people={caseProviders} />
      </div>

      <div className="grid grid-cols-2 gap-4 pt-6 pb-12">
        {documentHomeLink?.ok && (
          <div className="flex justify-center">
            <div className="m-auto">
              <a
                target="_blank"
                rel="noreferrer"
                href={documentHomeLink.content}
              >
                <Button variant="outlined">
                  {t("actions.viewCompletedDocuments")}
                </Button>
              </a>
            </div>
          </div>
        )}
        <div className="flex justify-center">
          <div className="max-w-[200px]">
            <RadialProgressBar percentComplete={progress} />
          </div>
        </div>
      </div>

      {isCaseCompleted?.ok && isCaseCompleted.content && (
        <h3 className="mb-8">{t("lockedTasksMessage")}</h3>
      )}

      <TaskGroupView
        createReferenceTask={(taskGroupId) =>
          createReferenceTask({ taskGroupId })
        }
        taskGroups={(taskGroups ?? []) as ExtendedTaskGroup[]}
        row={taskRow}
      />
    </Page>
  );
}
