import { flexRender, Table as ReactTable } from "@tanstack/react-table";

import {
  TableBody,
  Table as MuiTable,
  TableRow,
  TableCell,
} from "@mui/material";
import { TableHeader } from "../table-header";

import type { FamilyListingRow } from "../../types";

interface TableProps {
  table: ReactTable<FamilyListingRow>;
}

export const Table = ({ table }: TableProps) => {
  return (
    <MuiTable>
      <TableHeader table={table} />

      <TableBody>
        {table.getRowModel().rows?.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  );
};
