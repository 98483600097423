import { useTranslation } from "next-i18next";
import { Card } from "@shared/components";
import { Box, Divider, Typography } from "@mui/material";
import { Switch, palette } from "@pairtreefamily/ui";
import { Case } from "@_types/*";

type FamilyInfoCardProps = {
  state: string;
  providerFirstLastName: string;
  familyContactEmail: string;
  selectedCase: Case | undefined;
  setOpenFamilyStatusModal: (value: boolean) => void;
  changeStatusLoading: boolean;
};

export function FamilyInfoCard({
  state,
  providerFirstLastName,
  familyContactEmail,
  selectedCase,
  setOpenFamilyStatusModal,
  changeStatusLoading,
}: FamilyInfoCardProps) {
  const { t } = useTranslation("professionalCase");

  return (
    <>
      <Card>
        <Typography variant="head6" component="p" mb={1}>
          {t("header.familyStatusCaption")}
        </Typography>
        <Switch
          checked={selectedCase?.active ?? false}
          onChange={() => setOpenFamilyStatusModal(true)}
          inputProps={{ "aria-label": "controlled" }}
          disabled={changeStatusLoading}
          label={t("header.activeSwitch")}
          sx={{ mr: 1 }}
        />
        <Divider style={{ margin: "20px 0 16px" }} flexItem />
        <Box>
          <Typography variant="head6" component="p" mb={1}>
            {t("header.familyInfoCaption")}
          </Typography>
          <Box>
            <Typography variant="body4" component="p">
              {t("familyInfoCard.state")}
            </Typography>
            <Typography
              variant="body6"
              component="p"
              mb={2}
              color={palette.success.light}
            >
              {state}
            </Typography>
          </Box>
          <Typography variant="body4" component="p">
            {t("familyInfoCard.provider")}
          </Typography>
          <Typography
            variant="body6"
            component="p"
            mb={2}
            color={palette.success.light}
          >
            {providerFirstLastName}
          </Typography>
          <Typography variant="body4" component="p">
            {t("familyInfoCard.contact")}
          </Typography>
          <Typography
            variant="body4"
            component="p"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            <a
              style={{ fontWeight: 700 }}
              href={`mailto:${familyContactEmail}`}
            >
              {familyContactEmail}
            </a>
          </Typography>
        </Box>
      </Card>
    </>
  );
}
