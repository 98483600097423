import { useMemo } from "react";
import { TasksWithTemplateRoles } from "../../../types";

export const useGetRequiredAssigneesAmount = (
  tasksRoles: TasksWithTemplateRoles[]
) => {
  return useMemo(() => {
    return tasksRoles
      .flatMap((item) => item.templateRoles)
      .filter((item) => !!item.name).length;
  }, [tasksRoles]);
};
