export const newProviderKey = "new-provider";

export const defaultValues = {
  provider: newProviderKey,
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  profilePictureUrl: "",
  isAdmin: false,
  showContactInfo: false,
};
