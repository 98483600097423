import { Box, Tab, TabProps } from "@mui/material";
import { forwardRef } from "react";
import { styles } from "./styles";

type TabWithCounterProps = TabProps & {
  count: number;
};

export const TabWithCounter = forwardRef<HTMLDivElement, TabWithCounterProps>(
  ({ label, count, ...props }, ref) => {
    return (
      <Tab
        ref={ref}
        label={
          <Box sx={styles.labelWrapper}>
            {label}
            <Box sx={styles.counter}>{count}</Box>
          </Box>
        }
        {...props}
      />
    );
  }
);

TabWithCounter.displayName = "TabWithCounter";
