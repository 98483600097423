import { Box } from "@mui/material";
import { Button } from "@pairtreefamily/ui";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Task } from "@_types/*";
import { IssueReissueDocuments } from "./components/IssueReissueDocuments";

interface ActionsProps {
  setEditCurrentCase: (editCurrentCase: boolean) => void;
  setIsCaseModalOpen: (isCaseModalOpen: boolean) => void;
  setIsCloneCaseModalOpened: (isCloneCaseModalOpened: boolean) => void;
  setIsHideCaseModalOpen: (isHideCaseModalOpen: boolean) => void;
  setIsAssignCaseUserModalOpen: (isAssignCaseUserModalOpen: boolean) => void;
  setIsReplaceTasksTitleModalOpen: (
    isReplaceTasksTitleModalOpen: boolean
  ) => void;
  setAssignTasksModalOpen: (assignTasksModalOpen: boolean) => void;
  setShowIssuedErrors: (showIssuedErrors: boolean) => void;
  isCurrentCaseHidden: boolean;
  issueDocumentsForCaseIsLoading: boolean;
  issueDocumentsForCaseIsIdle: boolean;
  tasksWithIssueStatus: Task[];
  issueStandardDocuments: () => Promise<void>;
}

export const Actions = ({
  isCurrentCaseHidden,
  setEditCurrentCase,
  setIsCloneCaseModalOpened,
  setIsCaseModalOpen,
  setIsHideCaseModalOpen,
  setIsAssignCaseUserModalOpen,
  setAssignTasksModalOpen,
  setIsReplaceTasksTitleModalOpen,
  issueDocumentsForCaseIsLoading,
  issueDocumentsForCaseIsIdle,
  setShowIssuedErrors,
  tasksWithIssueStatus,
  issueStandardDocuments,
}: ActionsProps) => {
  const router = useRouter();
  const { t } = useTranslation("admin");

  return (
    <Box display="flex" flexWrap="wrap" gap={1}>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          setEditCurrentCase(false);
          setIsCaseModalOpen(true);
        }}
      >
        {t("actions.buttons.newCase")}
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          setEditCurrentCase(true);
          setIsCaseModalOpen(true);
        }}
      >
        {t("actions.buttons.editCase")}
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          setIsCloneCaseModalOpened(true);
        }}
      >
        {t("actions.buttons.cloneCase")}
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          setIsHideCaseModalOpen(true);
        }}
      >
        {isCurrentCaseHidden
          ? t("actions.buttons.showCase")
          : t("actions.buttons.hideCase")}
      </Button>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => {
          setIsAssignCaseUserModalOpen(true);
        }}
      >
        {t("actions.buttons.assignUser")}
      </Button>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => {
          setIsReplaceTasksTitleModalOpen(true);
        }}
      >
        {t("actions.buttons.replaceTasksTitle")}
      </Button>

      <Button
        color="secondary"
        variant="outlined"
        onClick={() => {
          setAssignTasksModalOpen(true);
        }}
      >
        {t("actions.buttons.assignTasks")}
      </Button>

      <Box marginRight={2}>
        <IssueReissueDocuments
          issueDocumentsForCaseIsIdle={issueDocumentsForCaseIsIdle}
          tasksWithIssueStatus={tasksWithIssueStatus}
          issueStandardDocuments={issueStandardDocuments}
          issueDocumentsForCaseIsLoading={issueDocumentsForCaseIsLoading}
          setShowIssuedErrors={setShowIssuedErrors}
        />
      </Box>

      <Box display="flex" gap={1}>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            window.open(router.asPath.replace("admin", "family"));
          }}
        >
          {t("actions.buttons.switchToFamView")}
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            window.open(router.asPath.replace("admin", "professional"));
          }}
        >
          {t("actions.buttons.switchToProView")}
        </Button>
      </Box>
    </Box>
  );
};
