import { UseFormReturn } from "react-hook-form";
import { FormValues } from "../validation-schema";
import { useEffect } from "react";
import { newPersonId } from "../constants";
import { getPersonById } from "../utils";

import type { Person, Task, CaseLoginUser } from "@_types/*";

type Args = {
  caseLoginUsers: CaseLoginUser[];
  providerTasks: Task[];
  parents: Person[];
  formMethods: UseFormReturn<FormValues>;
};

export const useSetValuesForSelectedPerson = ({
  formMethods,
  parents,
  caseLoginUsers,
  providerTasks,
}: Args) => {
  const personId = formMethods.watch("personId");

  useEffect(() => {
    if (personId !== newPersonId) {
      const person = getPersonById({
        id: personId,
        parents,
        caseLoginUsers,
        providerTasks,
      });

      formMethods.setValue("email", person?.email);
      formMethods.setValue("firstName", person?.firstName);
      formMethods.setValue("lastName", person?.lastName);
    } else {
      formMethods.reset();
    }
  }, [personId, formMethods, parents, caseLoginUsers, providerTasks]);
};
