import { UserRole } from "@api";
import { z } from "zod";

export const validationSchema = z.object({
  role: z.nativeEnum(UserRole),
  email: z.string().email(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
});

export type FormValues = z.infer<typeof validationSchema>;
