import { Grid } from "@mui/material";
import { palette } from "@pairtreefamily/ui";
import { AuthorizationLanding } from "./LandingSection";

const landingStyle = {
  background: `linear-gradient(to left, ${palette.primary.main}, ${palette.primary.gradientMain})`,
};
export function Layout({ children }: { children: JSX.Element }) {
  return (
    <Grid container height="100vh">
      <Grid item xs={6} px={3} sx={landingStyle}>
        <AuthorizationLanding />
      </Grid>
      <Grid
        item
        xs={6}
        px={3}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {children}
      </Grid>
    </Grid>
  );
}
