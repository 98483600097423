import { palette } from "@pairtreefamily/ui";

export const styles = {
  wrapper: {
    "&:hover": {
      "& .MuiTypography-root": {
        color: `${palette.primary.main} !important`,
      },
    },
  },
};
