import type { PaginationState, SortingState } from "@tanstack/react-table";
import { ThreadsTableField } from "./types";

export const initialSorting: SortingState = [
  {
    id: ThreadsTableField.LastUpdated,
    desc: false,
  },
];

export const initialPagination: PaginationState = {
  pageIndex: 0,
  pageSize: 20,
};
