import { Box, Grid, Typography } from "@mui/material";
import { Layout } from "./Layout";
import { useTranslation } from "react-i18next";
import { ForgotPasswordForm, ResetPasswordProps } from "@shared/components";

export function ForgotPasswordPage({
  app,
  onCancel,
  onSuccess,
  onError,
}: ResetPasswordProps) {
  const { t } = useTranslation("professional");

  return (
    <Layout>
      <Box display="flex">
        <Box maxWidth={500} mx="auto" width="100%">
          <Typography variant="head5" component="h1" textAlign="center" mb={3}>
            {t("authorization.forgotPassword.title")}
          </Typography>
          <Typography variant="input" component="p" textAlign="center" mb={3}>
            {t("authorization.forgotPassword.subtitle")}
          </Typography>
          <ForgotPasswordForm
            app={app}
            onCancel={onCancel}
            onSuccess={onSuccess}
            onError={onError}
          />
        </Box>
      </Box>
    </Layout>
  );
}

export default ForgotPasswordPage;
