import { useEffect, useMemo } from "react";
import { useTranslation } from "next-i18next";
import { Link } from "@mui/material";
import { AutocompleteField, SidePanel } from "@pairtreefamily/ui";
import { useForm } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import { states } from "@shared/constants";
import { initialFilters } from "../../constants";

import { useGetArchetypeList } from "@api";
import {
  useAdoptionRelationshipPreferencesOptions,
  useEducationsOptions,
  useFamilyStructureOptions,
  useHomeStudyOptions,
  useOpenToOptions,
  usePoliticalAffiliation,
  useProfileBookOptions,
  useRaceEthnicityOptions,
  useReligionOptions,
} from "./hooks";

import { styles } from "./styles";

import type { FilterSidePanelProps } from "./types";
import type { FamilyFiltersFields } from "../../types";

export const FilterSidePanel = (props: FilterSidePanelProps) => {
  const { selectedFilters, totalCount, isOpen, onClose, onChange } = props;

  const { t } = useTranslation("connect");

  const formMethods = useForm<FamilyFiltersFields>({
    mode: "onChange",
    defaultValues: initialFilters,
    values: selectedFilters,
  });

  const { reset, watch } = formMethods;

  useEffect(() => {
    const subscription = watch((value) =>
      onChange(value as FamilyFiltersFields)
    );
    return () => subscription.unsubscribe();
  }, [watch, onChange]);

  const handleReset = () => {
    reset(initialFilters);
    onChange(initialFilters);
  };

  const { data: archetypeList } = useGetArchetypeList();

  const familyStructureOptions = useFamilyStructureOptions();
  const educationsOptions = useEducationsOptions();
  const raceEthnicityOptions = useRaceEthnicityOptions();
  const religionOptions = useReligionOptions();
  const politicalAffiliationOptions = usePoliticalAffiliation();
  const openToOptions = useOpenToOptions();
  const adoptionRelationshipPreferencesOptions =
    useAdoptionRelationshipPreferencesOptions();
  const homeStudyOptions = useHomeStudyOptions();
  const profileBookOptions = useProfileBookOptions();

  const archetypeOptions = useMemo(() => {
    if (!archetypeList) return [];

    return archetypeList.map((archetype) => ({
      id: archetype.uuid,
      label: archetype.name,
    }));
  }, [archetypeList]);

  const showClearButton: boolean = useMemo(() => {
    return Object.values(selectedFilters).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }

      return value !== undefined && value !== null;
    });
  }, [selectedFilters]);

  return (
    <SidePanel<FamilyFiltersFields>
      open={isOpen}
      title={t("familyListing.filters.title")}
      onClose={onClose}
      formMethods={formMethods}
      onSubmit={() => {}}
    >
      {showClearButton && (
        <Box sx={styles.filters}>
          <Typography variant="body9">
            {t("familyListing.filters.filteredResults", {
              amount: totalCount,
            })}
          </Typography>

          <Link onClick={handleReset}>
            {t("familyListing.filters.clearFilters")}
          </Link>
        </Box>
      )}

      <Box sx={styles.fields}>
        <AutocompleteField
          name="archetype"
          multiple
          options={archetypeOptions}
          label={t("familyListing.filters.fields.archetype.label")}
          placeholder={t("familyListing.filters.fields.archetype.placeholder")}
        />

        <AutocompleteField
          name="education"
          multiple
          options={educationsOptions}
          label={t("familyListing.filters.fields.education.label")}
          placeholder={t("familyListing.filters.fields.education.placeholder")}
        />

        <AutocompleteField
          name="familyStructure"
          multiple
          options={familyStructureOptions}
          label={t("familyListing.filters.fields.familyStructure.label")}
          placeholder={t(
            "familyListing.filters.fields.familyStructure.placeholder"
          )}
        />

        <AutocompleteField
          name="raceEthnicity"
          multiple
          options={raceEthnicityOptions}
          label={t("familyListing.filters.fields.raceEthnicity.label")}
          placeholder={t(
            "familyListing.filters.fields.raceEthnicity.placeholder"
          )}
        />

        <AutocompleteField
          name="religion"
          multiple
          options={religionOptions}
          label={t("familyListing.filters.fields.religion.label")}
          placeholder={t("familyListing.filters.fields.religion.placeholder")}
        />

        <AutocompleteField
          name="politicalAffiliation"
          multiple
          options={politicalAffiliationOptions}
          label={t("familyListing.filters.fields.politicalAffiliation.label")}
          placeholder={t(
            "familyListing.filters.fields.politicalAffiliation.placeholder"
          )}
        />

        <AutocompleteField
          name="state"
          multiple
          options={states}
          label={t("familyListing.filters.fields.state.label")}
          placeholder={t("familyListing.filters.fields.state.placeholder")}
        />

        <AutocompleteField
          name="openTo"
          multiple
          options={openToOptions}
          label={t("familyListing.filters.fields.openTo.label")}
          placeholder={t("familyListing.filters.fields.openTo.placeholder")}
        />

        <AutocompleteField
          name="adoptionRelationshipPreference"
          multiple
          options={adoptionRelationshipPreferencesOptions}
          label={t(
            "familyListing.filters.fields.adoptionRelationshipPreference.label"
          )}
          placeholder={t(
            "familyListing.filters.fields.adoptionRelationshipPreference.placeholder"
          )}
        />

        <AutocompleteField
          name="approvedHomeStudy"
          options={homeStudyOptions}
          label={t("familyListing.filters.fields.homeStudy.label")}
          placeholder={t("familyListing.filters.fields.homeStudy.placeholder")}
        />

        <AutocompleteField
          name="hasProfileBook"
          options={profileBookOptions}
          label={t("familyListing.filters.fields.profileBook.label")}
          placeholder={t(
            "familyListing.filters.fields.profileBook.placeholder"
          )}
        />
      </Box>
    </SidePanel>
  );
};
