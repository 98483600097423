import { useMemo } from "react";
import { useTranslation } from "next-i18next";

export const useHomeStudyOptions = () => {
  const { t } = useTranslation("connect");

  return useMemo(
    () => [
      {
        id: true,
        label: t("familyListing.filters.fields.homeStudy.options.yes"),
      },
      {
        id: false,
        label: t("familyListing.filters.fields.homeStudy.options.no"),
      },
    ],
    [t]
  );
};
