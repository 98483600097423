import { Box, Typography } from "@mui/material";
import { TruncableText } from "@shared/components";

export type NoteCellProps = {
  note: string;
  maxLines: number;
};

export const NoteCell = ({ note }: NoteCellProps) => {
  return (
    <Box py={2} maxWidth={300}>
      <TruncableText>
        <Typography component="div" variant="caption1">
          {note}
        </Typography>
      </TruncableText>
    </Box>
  );
};
