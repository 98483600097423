import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "next-i18next";
import {
  DotActive,
  DotApproved,
  useSnackbarNotification,
} from "@pairtreefamily/ui";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import { DashboardField, DashboardRow } from "../../types";
import { FamilyNameCell } from "./components/family-name-cell/FamilyNameCell";
import { IconButton, Typography } from "@mui/material";
import { NoteCell } from "./components/note-cell/NoteCell";

function getStatusIcon(cell: any) {
  switch (cell.getValue()) {
    case "Approved":
      return <DotApproved />;
    case "In Progress":
      return <DotActive />;
  }
}

const columnHelper = createColumnHelper<DashboardRow>();

export const useColumns = (searchQuery: string) => {
  const { t } = useTranslation("professional");
  const { callSnackbar } = useSnackbarNotification();

  return useMemo(
    () => [
      columnHelper.accessor(DashboardField.FamilyName, {
        header: t("dashboardTable.headers.familyName"),
        cell: (cell) => (
          <>
            {cell.row.original.hidden && (
              <span className="text-gray-tint">
                {t("dashboardTable.cells.hidden")}
              </span>
            )}
            <FamilyNameCell cell={cell} searchQuery={searchQuery} />
          </>
        ),
      }),
      columnHelper.accessor(DashboardField.State, {
        header: t("dashboardTable.headers.state"),
        cell: (cell) => <div>{cell.getValue()}</div>,
      }),
      columnHelper.accessor(DashboardField.ActivityStatus, {
        header: t("dashboardTable.headers.status"),
        cell: (cell) => (
          <div className="flex flex-nowrap items-center gap-4 font-semibold">
            {cell.getValue() ? (
              <Typography variant="body4">
                {t("dashboardTable.activityStatus.active")}
              </Typography>
            ) : (
              <Typography variant="body7">
                {t("dashboardTable.activityStatus.inactive")}
              </Typography>
            )}
          </div>
        ),
      }),
      columnHelper.accessor(DashboardField.HomeStudyStatus, {
        header: () => t("dashboardTable.headers.homeStudyStatus"),
        cell: (cell) => (
          <div className="flex flex-nowrap items-center gap-4 font-semibold">
            {getStatusIcon(cell)}
            {cell.getValue()}
          </div>
        ),
      }),
      columnHelper.accessor(DashboardField.ExpiredDate, {
        header: () => t("dashboardTable.headers.expiredDate"),
        cell: (cell) => <em>{cell.getValue()}</em>,
      }),
      columnHelper.accessor(DashboardField.Contact, {
        header: t("dashboardTable.headers.familyContact"),
        cell: (cell) =>
          cell.getValue() ? (
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(cell.getValue());
                callSnackbar({
                  message: t("dashboardTable.emailCopied", {
                    email: cell.getValue(),
                  }),
                  duration: 3000,
                });
              }}
            >
              <EmailOutlinedIcon />
            </IconButton>
          ) : (
            ""
          ),
      }),
      columnHelper.accessor(DashboardField.Notes, {
        header: t("dashboardTable.headers.notes"),
        cell: (cell) =>
          cell.getValue() ? (
            <NoteCell note={cell.getValue()} maxLines={3} />
          ) : (
            ""
          ),
      }),
    ],
    [callSnackbar, searchQuery, t]
  );
};
