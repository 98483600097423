import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import { AdoptionRelationshipPreference } from "@_types/*";

export const useAdoptionRelationshipPreferencesOptions = () => {
  const { t } = useTranslation("connect");

  return useMemo(
    () => [
      {
        id: AdoptionRelationshipPreference.VeryOpen,
        label: t(
          "familyListing.filters.fields.adoptionRelationshipPreference.options.veryOpen"
        ),
      },
      {
        id: AdoptionRelationshipPreference.ModeratelyOpen,
        label: t(
          "familyListing.filters.fields.adoptionRelationshipPreference.options.moderatelyOpen"
        ),
      },
      {
        id: AdoptionRelationshipPreference.Closed,
        label: t(
          "familyListing.filters.fields.adoptionRelationshipPreference.options.closed"
        ),
      },
    ],
    [t]
  );
};
