import { Box, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";

import { styles } from "./styles";

export const ConnectHeading = () => {
  const { t } = useTranslation("connect");

  return (
    <Box sx={styles.wrapper}>
      <Typography component="h1" variant="head4">
        {t("title")}
      </Typography>

      <Typography variant="input" component="p" sx={styles.description}>
        {t("description")}
      </Typography>
    </Box>
  );
};
