import {
  Button,
  SidePanel,
  TextAreaField,
  TextField,
  useSnackbarNotification,
  useUnsavedChanges,
} from "@pairtreefamily/ui";
import { useForm } from "react-hook-form";
import { useTranslation } from "next-i18next";
import { useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { NewThreadFormData, validationSchema } from "./validation-schema";
import { useCreateThread } from "@api";

type NewThreadProps = {
  open: boolean;
  onClose: () => void;
  refetchThreads: () => void;
};

export const NewThread = ({
  onClose,
  open,
  refetchThreads,
}: NewThreadProps) => {
  const { t } = useTranslation("safetyCenter");
  const { detectUnsavedChanges } = useUnsavedChanges();
  const createNewThread = useCreateThread();
  const { callSnackbar } = useSnackbarNotification();

  const formMethods = useForm<NewThreadFormData>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      title: "",
      description: "",
    },
  });

  // Update form when side panel opened - closed
  useEffect(() => {
    formMethods.reset();
  }, [formMethods, open]);

  const handleSubmit = useCallback(
    async (values: NewThreadFormData) => {
      try {
        await createNewThread(values);
        onClose();

        callSnackbar({
          message: t("newThread.successMessage"),
          type: "info",
        });
      } catch (e) {
        callSnackbar({
          message: t("newThread.errorMessage"),
          type: "error",
        });
      }

      await refetchThreads();
    },
    [callSnackbar, createNewThread, onClose, refetchThreads, t]
  );

  return (
    <SidePanel<NewThreadFormData>
      shouldAskForUnsavedChanges
      open={open}
      formMethods={formMethods}
      onClose={detectUnsavedChanges(onClose)}
      onSubmit={handleSubmit}
      title={t("newThread.title")}
      actions={
        <>
          <Button variant="outlined" onClick={detectUnsavedChanges(onClose)}>
            {t("newThread.form.cancel")}
          </Button>

          <Button
            type="submit"
            isLoading={formMethods.formState.isSubmitting}
            disabled={formMethods.formState.isSubmitting}
          >
            {t("newThread.form.submit")}
          </Button>
        </>
      }
    >
      <TextField name="title" required label={t("newThread.form.title")} />

      <Box mt={1}>
        <TextAreaField
          name="description"
          required
          label={t("newThread.form.description")}
          rows={5}
        />
      </Box>
    </SidePanel>
  );
};
