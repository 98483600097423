import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { MemberTable, useColumns } from "./useColumns";
import { TableHeader } from "@shared/components";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { palette } from "@pairtreefamily/ui";
import { useCallback, useMemo } from "react";
import { OrganizationMember } from "@api";

type MembersTableProps = {
  members: OrganizationMember[];
  onMemberClick: (member: OrganizationMember) => void;
};

const tableStyle = {
  tableLayout: "fixed",

  "& .MuiTableCell-root:hover": {
    backgroundColor: palette.background.light50,
  },
  "& .MuiTableRow-root:hover:not(.MuiTableRow-head)": {
    backgroundColor: palette.background.light50,
    cursor: "pointer",
  },
};

export function MembersTable({ members, onMemberClick }: MembersTableProps) {
  const columns = useColumns();

  const getMembersTable = useMemo(() => {
    const formattedMembers = members.map((member) => ({
      id: member.proUuid,
      name: `${member.firstName ?? ""} ${member.lastName ?? ""}`.trim(),
      email: member.contactEmail,
      position: member.title,
      phone: member.contactPhone,
    }));

    formattedMembers.sort((a, b) => a.email.localeCompare(b.email));

    return formattedMembers;
  }, [members]);

  const table = useReactTable({
    data: getMembersTable,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
  });

  const handleMemberClick = useCallback(
    (member: MemberTable) => {
      onMemberClick(
        members.find((m) => m.proUuid === member.id) as OrganizationMember
      );
    },
    [members, onMemberClick]
  );

  return (
    <Table sx={tableStyle}>
      <TableHeader<MemberTable> table={table} />
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow
            key={row.id}
            onClick={() => handleMemberClick(row.original)}
          >
            {row.getVisibleCells().map((cell) => (
              <TableCell
                sx={{
                  maxWidth: cell.column.columnDef.maxSize,
                  width: cell.column.columnDef.maxSize,
                  py: 1,
                  wordWrap: "break-word",
                }}
                key={cell.id}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
