import { Button, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ConnectLinkIcon,
  DisconnectLinkIcon,
  DropdownItem,
  DropdownMenu,
  EmailOutlineIcon,
  Modal,
  palette,
  useSnackbarNotification,
} from "@pairtreefamily/ui";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSendOrganizationInvite, useUnlinkFamFromOrg } from "@api";

const actionIconStyle = {
  color: palette.text.light,
};

const actionButtonStyle = {
  "&:hover": {
    backgroundColor: "transparent",
  },
};

export type ActionCellProps = {
  familyUuid: string;
  familyName: string;
  familyEmail: string;
  isOrgAssociated: boolean;
  onContactClick: () => void;
  onUnlinkSuccess: () => void;
};

export function ActionCell({
  familyUuid,
  familyName,
  isOrgAssociated,
  familyEmail,
  onUnlinkSuccess,
  onContactClick,
}: ActionCellProps) {
  const [modalOpen, setModalOpen] = useState(false);

  const { t } = useTranslation("connect");

  const unlikFamFromOrg = useUnlinkFamFromOrg();
  const { callSnackbar } = useSnackbarNotification();
  const sendOrganizationInvite = useSendOrganizationInvite();

  const handleSubmit = useCallback(async () => {
    if (isOrgAssociated) {
      try {
        await unlikFamFromOrg(familyUuid);
        setModalOpen(false);
        callSnackbar({
          message: t("familyListing.table.cells.actions.modal.success"),
          type: "info",
        });
        onUnlinkSuccess();
      } catch (error) {
        console.log("error", error);
        callSnackbar({
          message: t("familyListing.table.cells.actions.modal.error"),
          type: "error",
        });
      }
    }
  }, [
    isOrgAssociated,
    unlikFamFromOrg,
    familyUuid,
    callSnackbar,
    t,
    onUnlinkSuccess,
  ]);

  const handleInviteFamily = useCallback(async () => {
    //TODO: current endpoint to invitation only works for non existing accounts. BE needs to handle existing accounts
    try {
      await sendOrganizationInvite({
        email: familyEmail,
        isFamily: true,
        isAdmin: false,
      });
      callSnackbar({
        message: t("familyListing.inviteFamily.famSuccessAssociated", {
          email: familyEmail,
        }),
        type: "info",
      });
    } catch (error) {
      callSnackbar({
        message: t("familyListing.inviteFamily.famErrorAssociated", {
          email: familyEmail,
        }),
        type: "error",
      });
    }
  }, [callSnackbar, familyEmail, sendOrganizationInvite, t]);

  return (
    <>
      {/*@ts-ignore*/}
      <DropdownMenu
        triggerElement={
          <Button
            sx={actionButtonStyle}
            variant="text"
            endIcon={<ExpandMoreIcon sx={actionIconStyle} />}
          ></Button>
        }
      >
        <DropdownItem onClick={onContactClick}>
          <Box display="flex" gap={1}>
            <Box m="auto" width="18px">
              <EmailOutlineIcon />
            </Box>
            <Typography component="span">
              {t("familyListing.table.cells.actions.contact")}
            </Typography>
          </Box>
        </DropdownItem>
        {/*{!isOrgAssociated && (*/}
        {/*  <DropdownItem onClick={() => handleInviteFamily()}>*/}
        {/*    <Box display="flex" gap={1}>*/}
        {/*      <Box m="auto" width="18px">*/}
        {/*        <ConnectLinkIcon />*/}
        {/*      </Box>*/}
        {/*      <Typography component="span">*/}
        {/*        {t("familyListing.table.cells.actions.inviteFamily")}*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*  </DropdownItem>*/}
        {/*)}*/}
        {/*{isOrgAssociated && (*/}
        {/*  <DropdownItem onClick={() => setModalOpen(true)}>*/}
        {/*    <Box display="flex" gap={1} sx={{ color: palette.error.dark }}>*/}
        {/*      <Box m="auto" width="18px">*/}
        {/*        <DisconnectLinkIcon />*/}
        {/*      </Box>*/}
        {/*      <Typography component="span">*/}
        {/*        {t("familyListing.table.cells.actions.disconnectFamily")}*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*  </DropdownItem>*/}
        {/*)}*/}
      </DropdownMenu>

      <Modal
        title={t("familyListing.table.cells.actions.modal.title")}
        open={modalOpen}
        submitButton={{
          label: t("familyListing.table.cells.actions.modal.submitLabel"),
          onClick: handleSubmit,
        }}
        onClose={() => setModalOpen(false)}
      >
        <Typography variant="caption1" component="p" mb={1}>
          {t("familyListing.table.cells.actions.modal.body1")}
          <strong>&nbsp;{familyName}</strong>?
        </Typography>
        <Typography variant="caption1" component="p">
          {t("familyListing.table.cells.actions.modal.body2")}
        </Typography>
      </Modal>
    </>
  );
}
