import React, { useCallback, useState } from "react";
import {
  UseCaseReturn,
  useFileUpload,
  useHandleSetAnswer,
} from "@shared/hooks";
import {
  ElectronicDocumentTask,
  FileUploadTask,
  PandadocSession,
  Task,
} from "@_types/*";
import {
  IssueeElectronicDocumentRow,
  IssuerElectronicDocumentRow,
  PandaDocIframe,
  TaskStatusElement,
  TaskDescription,
  TaskRefreshDateAndFilesView,
  isSessionValid,
} from "@shared/components";
import { Button, Modal } from "@pairtreefamily/ui";
import { ReferenceRow } from "@shared/components";
import { FileUploadRow } from "../file-upload-row";
import { UseProfessionalPageTools } from "@features";
import { useTranslation } from "next-i18next";
import { Box, Typography } from "@mui/material";
import { useIncrementSaveCount } from "@api";
import { TaskAssignment } from "@_types/*";

export interface TaskRowProps {
  task: Task;
  caseId: string;
  updateTaskQuestionAnswer: UseProfessionalPageTools["updateTaskQuestionAnswer"];
  getFileData: UseProfessionalPageTools["getFileData"];
  uploadDocument: UseProfessionalPageTools["uploadDocument"];
  deleteDocument: UseProfessionalPageTools["deleteDocument"];
  issueDocument: UseProfessionalPageTools["issueDocument"];
  getFileUrl: UseProfessionalPageTools["getFileUrl"];
  sendReferenceRequest: UseProfessionalPageTools["sendReferenceRequest"];
  refreshElectronicDocumentSession: UseProfessionalPageTools["refreshElectronicDocumentSession"];
  deleteTask: UseProfessionalPageTools["deleteTask"];
  providerDocumentsLink: UseCaseReturn["privateDocumentLink"];
  famDocumentsLink: UseCaseReturn["documentHomeLink"];
  setTaskManagerModalOpen: (isOpen: boolean) => void;
  setCurrentTaskBeingManaged: (
    task: Task | ElectronicDocumentTask | FileUploadTask
  ) => void;
  isAdmin?: boolean;
  refreshTask: UseProfessionalPageTools["refreshTask"];
}

type ModalState =
  | {
      isOpen: true;
      sessionId: string;
    }
  | { isOpen: false };

// This looks like a mix between a component and a function. Need to refactor it to make component or function
export const TaskRow = ({
  task,
  updateTaskQuestionAnswer,
  caseId,
  uploadDocument,
  deleteDocument,
  getFileData,
  providerDocumentsLink,
  setTaskManagerModalOpen,
  setCurrentTaskBeingManaged,
  famDocumentsLink,
  getFileUrl: getFileUrlRequest,
  issueDocument,
  sendReferenceRequest,
  refreshElectronicDocumentSession,
  deleteTask,
  isAdmin,
  refreshTask,
}: TaskRowProps) => {
  const referenceAuthor = task.taskAssignments[0]?.assignee;
  const [answer, setAnswer] = useState<boolean | null>(null);
  const [modalState, setModalState] = useState<ModalState>({ isOpen: false });
  const [completedReferenceLoading, setCompletedReferenceLoading] =
    useState<boolean>(false);

  const { t } = useTranslation("professionalCase");

  const handleReferenceModal = useCallback(() => {
    const taskAssignment = task.taskAssignments.reduce(
      (acc: TaskAssignment | null, cur) =>
        cur.pandadocSession !== null ? cur : acc,
      null
    );

    if (!taskAssignment) {
      return;
    }

    if (isSessionValid(taskAssignment.pandadocSession)) {
      setModalState({
        isOpen: true,
        sessionId: taskAssignment.pandadocSession?.sessionId ?? "",
      });
      return;
    }
    setCompletedReferenceLoading(true);
    refreshElectronicDocumentSession(taskAssignment).then((res) => {
      setCompletedReferenceLoading(false);
      if (!res.ok) {
        /*
        callSnackbar({
          message: t("electronicDocumentRow.errors.sessionIssue"),
          type: "error",
        });
         */
        return;
      }
      const ta = res.content;
      setModalState({
        isOpen: true,
        sessionId: ta.pandadocSession?.sessionId ?? "",
      });
    });
  }, [refreshElectronicDocumentSession, task.taskAssignments]);

  const handleSetAnswer = useHandleSetAnswer({
    taskId: task.id,
    caseId,
    updateTaskQuestionAnswer,
    setAnswer,
  });

  const incrementSaveCount = useIncrementSaveCount({
    caseId,
  });

  const { getFileName, uploadFile, deleteFile } = useFileUpload({
    caseId: caseId,
    getFileData,
    uploadDocument,
    deleteDocument,
  });

  const getFileUrl = (fileId: string) => getFileUrlRequest({ caseId, fileId });

  const viewDocumentsLink =
    (task.TaskGroup?.providerOnly
      ? providerDocumentsLink?.ok && providerDocumentsLink.content
      : famDocumentsLink?.ok && famDocumentsLink.content) || null;

  switch (task.taskType) {
    case "fileUpload": {
      const date = new Date().toISOString().slice(0, 10);
      const time = new Date().toLocaleTimeString("en-US", {
        hour12: false,
        timeZoneName: "short",
      });

      return FileUploadRow({
        pageType: "Professional",
        getFileName,
        task: task,
        answer: answer,
        handleSetAnswer: handleSetAnswer,
        uploadFile: (file) => {
          uploadFile({
            file: file,
            taskId: task.id,
            fileName: `${task.title} - ${date} at ${time} - ${file.name}`,
            taskGroupId: task.TaskGroup?.id ?? "",
            isPrivate: task.isPrivate ?? true,
          });
        },
        handleDeleteFile: async (fileId: string) =>
          deleteFile({
            fileId: fileId,
            fileName: (await getFileName(fileId)) ?? "",
            taskId: task.id,
            taskGroupId: task.TaskGroup?.id ?? "",
          }),
        setTaskManagerModalOpen,
        setCurrentTaskBeingManaged,
        viewDocumentsLink,
        getFileUrl,
        refreshTask,
      });
    }
    case "electronicDocument": {
      if (task.TaskGroup?.providerOnly) {
        return IssueeElectronicDocumentRow({
          task,
          answer,
          handleSetAnswer,
          editTask(task) {
            setCurrentTaskBeingManaged(task);
            setTaskManagerModalOpen(true);
          },
          onSave: async () => {
            await incrementSaveCount({ taskId: task.id });
          },
          refreshElectronicDocumentSession: refreshElectronicDocumentSession,
        });
      } else {
        return IssuerElectronicDocumentRow({
          task,
          issueDocument: (task) => issueDocument({ task }),
          setTaskManagerModalOpen,
          setCurrentTaskBeingManaged,
          refreshTask,
          getFileUrl,
          viewDocumentsLink,
          getFileName,
          refreshElectronicDocumentSession,
        });
      }
    }
    case "reference": {
      return {
        column1: (
          <>
            <div>{task.title}</div>

            {modalState.isOpen && (
              <Modal
                title={task.title}
                open={modalState.isOpen}
                onClose={() => setModalState({ isOpen: false })}
              >
                <PandaDocIframe sessionUuid={modalState.sessionId} />
              </Modal>
            )}
            {(task.status === "sent" || task.status === "inProgress") &&
              referenceAuthor && (
                <Typography component="p" variant="body6">
                  {task.status === "sent"
                    ? t("taskRow.taskStatusType.requested", {
                        firstName: referenceAuthor.firstName,
                        lastName: referenceAuthor.lastName,
                        email: referenceAuthor.email,
                      })
                    : t("taskRow.taskStatusType.inProgress", {
                        firstName: referenceAuthor.firstName,
                        lastName: referenceAuthor.lastName,
                        email: referenceAuthor.email,
                      })}
                </Typography>
              )}
            {task.status === "completed" && referenceAuthor && (
              <>
                <Typography component="p" variant="body6">
                  {t("taskRow.taskStatusType.completed", {
                    firstName: referenceAuthor.firstName,
                    lastName: referenceAuthor.lastName,
                  })}
                </Typography>

                <Box mt={1}>
                  <Button
                    isLoading={completedReferenceLoading}
                    onClick={handleReferenceModal}
                  >
                    {t("taskRow.buttons.view")}
                  </Button>
                </Box>
              </>
            )}

            {task.clonedFromId && (
              <TaskRefreshDateAndFilesView
                getFileName={getFileName}
                task={task}
                shouldDisplayViewDocumentsLink={true}
                viewDocumentsLink={viewDocumentsLink}
                getFileUrl={getFileUrl}
              />
            )}
          </>
        ),
        column2: <TaskDescription task={task} />,
        column3: <TaskStatusElement task={task} />,
        column4: (
          <ReferenceRow
            refreshTask={refreshTask}
            task={task}
            issueReferenceRequest={(recipient) =>
              sendReferenceRequest({
                task,
                recipient,
                personalNote: recipient.personalNote,
              })
            }
            deleteTask={isAdmin ? deleteTask : undefined}
            enableViewCompletedReference={true}
            editTask={(task) => {
              setCurrentTaskBeingManaged(task);
              setTaskManagerModalOpen(true);
            }}
          />
        ),
      };
    }
    default: {
      const canary: never = task;
      console.warn("Tried to render malformed task; skipping", canary);
      return {};
    }
  }
};
