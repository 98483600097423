import { useForm } from "react-hook-form";
import { FormValues, validationSchema } from "./validation-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  CorporateIcon,
  Form,
  PhoneNumberField,
  SelectField,
  TextField,
  palette,
} from "@pairtreefamily/ui";
import { Box, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";

const organizationNameStyle = {
  ["& .MuiInputBase-root"]: {
    flexDirection: "row-reverse",
  },
  ["& .MuiIconButton-root"]: {
    paddingRight: 0,
  },
  ["& .MuiInputBase-input"]: {
    paddingLeft: "0 !important",
  },
  "& .MuiInputAdornment-root": {
    margin: "0 16px",
    color: palette.success.light,
  },
};

type ProInfoFormProps = {
  onSubmit: (data: FormValues) => void;
  isLoading: boolean;
};

export function ProInfoForm({ onSubmit, isLoading }: ProInfoFormProps) {
  const { t } = useTranslation("professional");

  const formMethods = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      contactFirstName: "",
      contactLastName: "",
      orgName: "",
      contactPhone: "",
      website: "",
      orgType: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const organizationTypeOptions = [
    {
      label: t("authorization.organization.form.organizationType.agency"),
      id: "Agency",
    },
    {
      label: t("authorization.organization.form.organizationType.attorney"),
      id: "Attorney",
    },
    {
      label: t("authorization.organization.form.organizationType.homeStudy"),
      id: "Home Study Provider",
    },
  ];

  return (
    <Form<FormValues> formMethods={formMethods} onSubmit={onSubmit}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" gap={2}>
          <Box width="100%">
            <TextField
              required
              name="contactFirstName"
              label={t("authorization.organization.form.firstName")}
              placeholder={t(
                "authorization.organization.form.firstNamePlaceholder"
              )}
            />
          </Box>
          <Box width="100%">
            <TextField
              required
              name="contactLastName"
              label={t("authorization.organization.form.lastName")}
              placeholder={t(
                "authorization.organization.form.lastNamePlaceholder"
              )}
            />
          </Box>
        </Box>

        <TextField
          required
          sx={organizationNameStyle}
          name="orgName"
          label={t("authorization.organization.form.organizationName")}
          placeholder={t(
            "authorization.organization.form.organizationNamePlaceholder"
          )}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <CorporateIcon />
              </InputAdornment>
            ),
          }}
        />

        <PhoneNumberField
          required
          name="contactPhone"
          label={t("authorization.organization.form.organizationPhone")}
          mask="+1 000-000-0000"
          placeholder={t(
            "authorization.organization.form.organizationPhonePlaceholder"
          )}
          unmask
        />

        <TextField
          required
          name="website"
          label={t("authorization.organization.form.organizationWebsite")}
          placeholder={t(
            "authorization.organization.form.organizationWebsitePlaceholder"
          )}
        />

        <Box mt={3}>
          <SelectField
            required
            label={t("authorization.organization.form.organizationType.title")}
            placeholder={t(
              "authorization.organization.form.organizationTypePlaceholder"
            )}
            name="orgType"
            options={organizationTypeOptions}
          ></SelectField>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={3} mt={3}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ width: "100%" }}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {t("authorization.organization.form.button")}
        </Button>
      </Box>
    </Form>
  );
}
