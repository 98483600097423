import { palette } from "@pairtreefamily/ui";

export const styles = {
  table: {
    tableLayout: "fixed",

    "& .MuiTableCell-root:hover": {
      backgroundColor: palette.background.light50,
    },
    "& .MuiTableRow-root:hover:not(.MuiTableRow-head)": {
      backgroundColor: palette.background.light50,
      cursor: "pointer",
    },
  },
};
