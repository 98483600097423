import { Box, Typography } from "@mui/material";
import { MyFamilyTasksIcon } from "@pairtreefamily/ui";
import { TaskGroupRow, TaskGroupView } from "@shared/components";
import { ExtendedTaskGroup, TaskGroup } from "@_types/*";
import React, { memo } from "react";
import { useTranslation } from "next-i18next";
import { useReorderTaskGroups, useReorderTasks } from "@shared/hooks";
import { UseProfessionalPageTools } from "@features";

type FamilyTasksProps = {
  familyTaskGroups: ExtendedTaskGroup[];
  taskRow: TaskGroupRow;
  reorderTasks: ReturnType<typeof useReorderTasks>;
  reorderTaskGroups: ReturnType<typeof useReorderTaskGroups>;
  createReferenceTask: UseProfessionalPageTools["createReferenceTask"];
  taskGroupsError: UseProfessionalPageTools["taskGroupsError"];
  setTaskManagerModalOpen: (open: boolean) => void;
  setCurrentGroupAddingTask: (taskGroup: TaskGroup) => void;
  setTaskGroupToEdit: (taskGroup: TaskGroup | null) => void;
};

export const FamilyTasks = memo(
  ({
    taskRow,
    familyTaskGroups,
    setTaskGroupToEdit,
    reorderTaskGroups,
    reorderTasks,
    taskGroupsError,
    setCurrentGroupAddingTask,
    createReferenceTask,
    setTaskManagerModalOpen,
  }: FamilyTasksProps) => {
    const { t } = useTranslation("professionalCase");

    return (
      <>
        <Box display="flex" gap={1} mb={4}>
          <MyFamilyTasksIcon />

          <Typography component="h2" variant="head2">
            {t("familyChecklistCaption")}
          </Typography>
        </Box>

        {taskGroupsError?.message && (
          <Typography variant="caption2" color="error">
            {taskGroupsError?.data?.code === "NOT_FOUND"
              ? t("errors.noTasks")
              : taskGroupsError?.message}
          </Typography>
        )}

        {familyTaskGroups && (
          <TaskGroupView
            allowReorder
            updatedTasksOrder={reorderTasks}
            updatedTaskGroupsOrder={reorderTaskGroups}
            createReferenceTask={(taskGroupId) =>
              createReferenceTask({ taskGroupId })
            }
            onOpenTaskManagerModal={(taskGroup) => {
              setCurrentGroupAddingTask(taskGroup);
              setTaskManagerModalOpen(true);
            }}
            taskGroups={familyTaskGroups as ExtendedTaskGroup[]}
            openTaskGroupModal={(open, taskGroup) => {
              setTaskGroupToEdit(taskGroup);
            }}
            row={taskRow}
          />
        )}
      </>
    );
  }
);

FamilyTasks.displayName = "FamilyTasks";
