import {
  AutocompleteField,
  Button,
  Form,
  PhoneNumberField,
  SelectField,
  TextAreaField,
  TextField,
  useSnackbarNotification,
} from "@pairtreefamily/ui";
import { useTranslation } from "next-i18next";
import { FormValues, validationSchema } from "./validation-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { memo, useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import { ContentWrapper, TextAreaWithMaxCharacters } from "@shared/components";
import { styles } from "./styles";
import { states } from "@shared/constants";
import { useOrganizationServices, useOrganizationTypes } from "./constants";
import { Organization, useUpdateOrganization } from "@api";
import { getDefaultValues, removeProtocol } from "./utils";

export const Overview = memo((props: { data: Organization }) => {
  const { t } = useTranslation(["common", "organization"]);
  const organizationTypes = useOrganizationTypes();
  const organizationServices = useOrganizationServices();
  const updateOrganization = useUpdateOrganization();
  const { callSnackbar } = useSnackbarNotification();

  const { data } = props;

  const formMethods = useForm<FormValues>({
    defaultValues: getDefaultValues(),
    resolver: zodResolver(validationSchema),
  });

  const descriptionValue = formMethods.watch("description");

  useEffect(() => {
    formMethods.reset(getDefaultValues(data));
  }, [data, formMethods]);

  const handleFormSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const websiteWithoutProtocol = removeProtocol(formValues.website);
        const organization = {
          ...data,
          ...(formValues as Organization),
          website: websiteWithoutProtocol,
        };

        await updateOrganization(organization);

        formMethods.reset(getDefaultValues(organization));

        callSnackbar({
          message: t("organization:organizationForm.saveSuccess"),
          type: "info",
        });
      } catch (error) {
        callSnackbar({
          message: t("organization:organizationForm.saveError"),
          type: "error",
        });
        console.error(error);
      }
    },
    [data, updateOrganization, formMethods, callSnackbar, t]
  );

  return (
    <Form<FormValues>
      sx={styles.form}
      formMethods={formMethods}
      onSubmit={handleFormSubmit}
      shouldAskForUnsavedChanges={true}
    >
      <Box sx={styles.scrollableWrapper}>
        <ContentWrapper>
          <Box mt={-2} maxWidth={720} pb={10}>
            <TextField
              required
              name="name"
              label={t("organization:organizationForm.organizationName")}
            />

            <TextField
              required
              name="website"
              label={t("organization:organizationForm.website")}
            />

            <TextField
              required
              name="contactEmail"
              label={t("organization:organizationForm.email")}
            />

            <Box display="flex" gap={2}>
              <PhoneNumberField
                name="contactPhone"
                label={t("organization:organizationForm.phone")}
                mask="+1 000-000-0000"
                placeholder="+1 XXX-XXX-XXXX"
                required
                unmask
              />
              <TextField
                name="contactPhoneExt"
                label={t("organization:organizationForm.phoneExt")}
              />
            </Box>

            <AutocompleteField
              required
              placeholder={t("organization:organizationForm.statesPlaceholder")}
              name="states"
              label={t("organization:organizationForm.states")}
              options={states}
              multiple
            />

            <Box mt={2}>
              <SelectField
                required
                placeholder={t(
                  "organization:organizationForm.organizationType.placeholder"
                )}
                name="orgType"
                label={t(
                  "organization:organizationForm.organizationType.label"
                )}
                options={organizationTypes}
              />
            </Box>

            <AutocompleteField
              required
              name="services"
              placeholder={t(
                "organization:organizationForm.organizationServices.placeholder"
              )}
              label={t(
                "organization:organizationForm.organizationServices.label"
              )}
              options={organizationServices}
              multiple
            />

            <TextAreaWithMaxCharacters
              maxLength={400}
              value={descriptionValue ?? ""}
              renderComponent={(props) => (
                <TextAreaField
                  rows={4}
                  name="description"
                  label={t(
                    "organization:organizationForm.organizationDescription"
                  )}
                  {...props}
                />
              )}
            />
          </Box>
        </ContentWrapper>
      </Box>

      <Box sx={styles.actionsWrapper}>
        <Button
          variant="outlined"
          disabled={
            formMethods.formState.isSubmitting || !formMethods.formState.isDirty
          }
          onClick={() => {
            formMethods.reset();
          }}
        >
          {t("organization:organizationForm.cancelButton")}
        </Button>

        <Button
          type="submit"
          isLoading={formMethods.formState.isSubmitting}
          disabled={
            formMethods.formState.isSubmitting || !formMethods.formState.isDirty
          }
        >
          {t("common:actions.save")}
        </Button>
      </Box>
    </Form>
  );
});

Overview.displayName = "Overview";
