import { theme } from "@pairtreefamily/ui";

export const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  actionsWrapper: {
    py: 3,
    display: "flex",
    gap: 3,
    borderTop: `1px solid ${theme.palette.divider}`,
    justifyContent: "center",
  },
  scrollableWrapper: {
    height: "100%",
    maxHeight: "100%",
    overflowY: "auto",
  },
  checkboxesWrapper: {
    display: "flex",
    gap: "2",
    mt: 2,
  },
};
