import { ContactFamilyFormData } from "./validation-schema";

export const getDefaultValues = ({
  familyName,
  organizationEmail,
  organizationPhone,
}: {
  familyName?: string;
  organizationEmail?: string;
  organizationPhone?: string;
} = {}): ContactFamilyFormData => {
  return {
    subject: familyName || "",
    senderAddress: organizationEmail || "",
    phoneNumber: organizationPhone || "",
    message: "",
  };
};
