import {
  useAssignProviderToCase,
  useAssignUserToCase,
  useCloneCase,
  useDeleteTask,
  useDeleteTaskAssignment,
  useDeleteTaskGroup,
  useGetCaseLoginUsers,
  useGetCaseParticipants,
  useGetCaseProviders,
  useGetProviderCandidates,
  useGetTaskGroups,
  useGetTemplateRolesById,
  useHideCase,
  useInvalidateAllCases,
  useInvalidateCaseLoginUsers,
  useInvalidateGetCaseProviders,
  useInvalidateTaskList,
  useIssueDocumentForCase,
  useIssueDocumentForTask,
  useRemoveAssigneeFromCase,
  useSetTaskAssignment,
  useToggleProviderContactInfo,
  useUpdateTaskGroupOrder,
  useUpdateTasksOrder,
  useUpsertCase,
  useUpsertElectronicDocumentTask,
  useUpsertExternalSignerTask,
  useUpsertFileUploadTask,
  useUpsertTaskGroup,
  useReplaceTaskTitles,
  useInvalidateProviderCandidates,
  useRefreshTask,
} from "@api";
import { ExtendedTaskGroup, Task } from "@_types/*";
import { useCallback, useMemo } from "react";
import { useRefreshElectronicDocumentSession } from "src/api/trpc/electronicDocuments/refreshSession";

export type AdminTools = ReturnType<typeof useAdminTools>;

export const useAdminTools = (caseId: string) => {
  const invalidateTaskList = useInvalidateTaskList({ caseId });
  const invalidateCaseList = useInvalidateAllCases();
  const invalidateCaseLoginUsers = useInvalidateCaseLoginUsers();
  const invalidateGetCaseProviders = useInvalidateGetCaseProviders({ caseId });
  const invalidateProviderCandidates = useInvalidateProviderCandidates();

  const { data: taskGroups } = useGetTaskGroups({ caseId });

  const { data: caseParticipants } = useGetCaseParticipants({ caseId });
  const upsertCase = useUpsertCase({ onSuccess: invalidateTaskList });

  const _deleteTaskAssignment = useDeleteTaskAssignment({
    caseId,
    onSuccess: invalidateTaskList,
  });

  const deleteTaskAssignment = useCallback(
    ({ taskAssignmentId }: { taskAssignmentId: string }) => {
      if (
        confirm(
          `do you really want to delete assignment id: ${taskAssignmentId}`
        )
      ) {
        _deleteTaskAssignment({ taskAssignmentId: taskAssignmentId });
      }
    },
    [_deleteTaskAssignment]
  );

  const { setTaskAssignment, taskAssignmentStatus } = useSetTaskAssignment({
    onSuccess: invalidateTaskList,
  });

  const upsertTaskGroup = useUpsertTaskGroup({
    caseId,
    onSuccess: invalidateTaskList,
  });

  const _deleteTaskGroup = useDeleteTaskGroup({
    caseId,
    onSuccess: invalidateTaskList,
  });
  const deleteTaskGroup = useCallback(
    (group: ExtendedTaskGroup) => {
      if (group.tasks?.length) {
        alert(`to delete this task group, please remove all the tasks first`);
      } else {
        if (
          confirm(`do you really want to delete task group: ${group.title}`)
        ) {
          _deleteTaskGroup(group);
        }
      }
    },
    [_deleteTaskGroup]
  );

  const _deleteTask = useDeleteTask({ caseId, onSuccess: invalidateTaskList });
  const deleteTask = useCallback(
    async (task: Task) => {
      if (
        confirm(
          (task.taskType === "fileUpload" && task.taskFiles.length
            ? "This task has uploaded files. By deleting this task, you will also delete the files from Google Drive. "
            : "") + `Do you really want to delete task: ${task.title}?`
        )
      ) {
        await _deleteTask(task);
      }
    },
    [_deleteTask]
  );

  const cloneCase = useCloneCase({
    onSuccess: () => {
      invalidateCaseList();
      invalidateTaskList();
    },
  });

  const { data: caseLoginUsersRes } = useGetCaseLoginUsers({ caseId });
  const caseLoginUsers = useMemo(
    () => caseLoginUsersRes?.content ?? [],
    [caseLoginUsersRes]
  );

  const assignUserToCase = useAssignUserToCase({
    caseId,
    onSuccess: (_, variables) => {
      invalidateCaseLoginUsers();
      if (variables.role === "family") {
        invalidateCaseList();
      }
    },
  });

  const upsertElectronicDocumentTask = useUpsertElectronicDocumentTask({
    onSuccess: invalidateTaskList,
  });

  const upsertFileUploadTask = useUpsertFileUploadTask({
    onSuccess: invalidateTaskList,
  });

  const upsertExternalSignerTask = useUpsertExternalSignerTask({
    onSuccess: invalidateTaskList,
  });

  const updateTaskGroupOrder = useUpdateTaskGroupOrder({
    onSuccess: invalidateTaskList,
  });

  const updateTaskOrder = useUpdateTasksOrder({
    onSuccess: invalidateTaskList,
  });

  const { data: providerCandidatesRes } = useGetProviderCandidates();
  const providerCandidates = useMemo(
    () => (providerCandidatesRes?.ok ? providerCandidatesRes.content : []),
    [providerCandidatesRes]
  );

  const caseProvider = useGetCaseProviders({ caseId });

  const assignProviderToCase = useAssignProviderToCase({
    caseId,
    onSuccess: () => {
      invalidateGetCaseProviders();
      invalidateProviderCandidates();
      invalidateCaseLoginUsers({ caseId });
    },
  });

  const toggleProviderContactInfo = useToggleProviderContactInfo({
    caseId,
    onSuccess: () => {
      invalidateCaseLoginUsers({ caseId });
    },
  });

  const removeAssigneeFromCase = useRemoveAssigneeFromCase({
    caseId,
    onSuccess: () => {
      invalidateCaseLoginUsers({ caseId });
    },
  });

  const {
    issueDocumentsForCaseIsLoading,
    issueDocumentsForCase: _issueDocumentsForCase,
    issueDocumentsForCaseIsIdle,
  } = useIssueDocumentForCase({ caseId, onSuccess: invalidateTaskList });

  const issueDocumentsForCase = useCallback(
    (onlyFailed?: boolean) => {
      _issueDocumentsForCase(onlyFailed);
    },
    [_issueDocumentsForCase]
  );

  const hideCase = useHideCase({
    caseId,
    onSuccess: () => invalidateCaseList(),
  });

  const issueDocument = useIssueDocumentForTask({
    caseId,
    onSuccess: invalidateTaskList,
  });

  const getTemplateRoles = useGetTemplateRolesById();

  const replaceTasksTitle = useReplaceTaskTitles({
    caseId: caseId,
    onSuccess: invalidateTaskList,
  });

  const refreshTask = useRefreshTask({ caseId, onSuccess: invalidateTaskList });

  const refreshElectronicDocumentSession = useRefreshElectronicDocumentSession({
    caseId: caseId,
    onSuccess: invalidateTaskList,
  });

  return {
    taskGroups,
    caseParticipants,
    cloneCase,
    setTaskAssignment,
    taskAssignmentStatus,
    upsertCase,
    upsertTaskGroup,
    deleteTaskAssignment,
    deleteTaskGroup,
    deleteTask,
    assignUserToCase,
    upsertElectronicDocumentTask,
    upsertExternalSignerTask,
    upsertFileUploadTask,
    updateTaskGroupOrder,
    updateTaskOrder,
    caseLoginUsers,
    invalidateCaseLoginUsers,
    providerCandidates,
    caseProvider,
    assignProviderToCase,
    issueDocumentsForCase,
    issueDocumentsForCaseIsLoading,
    issueDocumentsForCaseIsIdle,
    removeAssigneeFromCase,
    toggleProviderContactInfo,
    hideCase,
    issueDocument,
    getTemplateRoles,
    replaceTasksTitle,
    refreshTask,
    refreshElectronicDocumentSession,
  };
};
