import { z } from "zod";
import {
  phoneNumberValidation,
  requiredString,
} from "@shared/validation-schemas";

export const validationSchema = z.object({
  subject: z.string().optional(),
  senderAddress: z.string().email(),
  phoneNumber: phoneNumberValidation,
  message: requiredString,
});

export type ContactFamilyFormData = z.infer<typeof validationSchema>;
