import { useEffect } from "react";
import { useTranslation } from "next-i18next";
import { Loading, useAuthStatus } from "@pairtreefamily/ui";
import { useGetAuthUserData } from "@api";
import { FamilyPageView } from "./components/family-page-view/FamilyPageView";
import { UnauthorizedMessage } from "@shared/components";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";

export const Family = () => {
  const { t } = useTranslation();
  const { t: tFamily } = useTranslation("family");

  useEffect(() => {
    z.setErrorMap(makeZodI18nMap({ t }));
  }, [t]);

  const { authedUser, isLoading: isAuthStatusLoading } = useAuthStatus();
  const { data: userData, isLoading: isUserDataLoading } = useGetAuthUserData();

  if (isAuthStatusLoading || isUserDataLoading) {
    return <Loading />;
  }

  if (!authedUser?.email || !userData || !userData.ok) {
    return <UnauthorizedMessage />;
  }

  const { cases } = userData.content;
  const firstCase = cases[0];

  if (!firstCase) {
    return <p>{tFamily("casesNotFound")}</p>;
  }

  return <FamilyPageView caseId={firstCase.id} />;
};
