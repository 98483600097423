import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";
import { Loading } from "@pairtreefamily/ui";
import { Layout } from "../Layout";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";

import { Routes } from "@features/routing";
import { useProRequestOrganization } from "@api";
import { FormValues } from "./validation-schema";
import { useUserData } from "@shared/components";

export function ProInfo() {
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [hasSuccessQueryParam, setHasSuccessQueryParam] = useState(false);

  const { t } = useTranslation("professional");
  const router = useRouter();

  const proRequestOrganization = useProRequestOrganization();

  // Clear all query params on page init
  useEffect(() => {
    if (Object.keys(router.query).length > 0) {
      router.replace(
        {
          pathname: router.pathname,
          query: {},
        },
        undefined,
        { shallow: true }
      );
    }
  }, [router]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchStatus = searchParams.get("status");

    setHasSuccessQueryParam(searchStatus === "success");
  }, [router.query]);

  const { userData } = useUserData();

  const hasPendingRequest =
    userData?.role === "provider" &&
    userData?.proDetails?.has_pending_org_requests;

  const title = t(
    `authorization.organization.${
      hasSuccessQueryParam || !hasPendingRequest ? "thanks" : "notActive"
    }.title`
  );

  const body = t(
    `authorization.organization.${
      hasSuccessQueryParam || !hasPendingRequest ? "thanks" : "notActive"
    }.subtitle`
  );

  useEffect(() => {
    if (!userData || !userData?.email) return;

    if (userData.role !== "provider") return;

    if (userData.proDetails?.org_memberships?.length) {
      router.replace(Routes.ProfessionalDashboard);
      return;
    }

    hasPendingRequest && setIsSuccess(true);
  }, [userData, router, hasPendingRequest]);

  const handleOnSubmit = useCallback(
    async (data: FormValues) => {
      setIsSuccess(false);

      const res = await proRequestOrganization({
        ...data,
        contactEmail: userData?.email ?? "",
      });

      if (res.ok) {
        router.replace(
          router.pathname,
          { query: { status: "success" } },
          { shallow: true }
        );
      }

      setIsSuccess(res.ok);
    },
    [userData, proRequestOrganization, router]
  );

  return (
    <Layout>
      {!userData ? (
        <Loading />
      ) : (
        <Box display="flex">
          <Box maxWidth={500} mx="auto" width="100%">
            {!isSuccess ? (
              <StepOne
                isLoading={isSuccess !== null}
                onSubmit={handleOnSubmit}
              />
            ) : (
              <StepTwo title={title} body={body} />
            )}
          </Box>
        </Box>
      )}
    </Layout>
  );
}
