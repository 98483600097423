import { palette } from "@pairtreefamily/ui";

export const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  title: {
    color: palette.text.primary,
  },
  table: {
    display: "grid",
    gridTemplateColumns: "152px auto",
    columnGap: 3,
    rowGap: 2,
  },
  tableRow: {
    display: "contents",
  },
  tableKey: {
    color: palette.text.dark,
  },
  tableValueList: {
    display: "flex",
    flexDirection: "column",
  },
  tableValueItem: {
    color: palette.text.primary,
  },
  tableValueSecondaryItem: {
    color: palette.text.primary,
    paddingLeft: 1,
  },
};
