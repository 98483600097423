import {
  Button,
  PhoneNumberField,
  SidePanel,
  TextField,
  useSnackbarNotification,
} from "@pairtreefamily/ui";
import { useForm } from "react-hook-form";
import { FormValues, validationSchema } from "./validation-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import {
  OrganizationMember,
  useUpdateOrganizationMember,
  useDeleteOrganizationMember,
} from "@api";
import { getDefaultValues } from "./utils";

export type MemberForm = Omit<
  OrganizationMember,
  "proUuid" | "orgUuid" | "orgName" | "isAdmin" | "middleInitial" | "title"
>;

type MemberDetailsProps = {
  isSidePanelOpen: boolean;
  onCloseSidePanel: () => void;
  selectedMember: OrganizationMember | null;
};

export function MemberDetails({
  isSidePanelOpen,
  onCloseSidePanel,
  selectedMember,
}: MemberDetailsProps) {
  const { t } = useTranslation(["organization"]);
  const { callSnackbar } = useSnackbarNotification();
  const updateOrganizationMember = useUpdateOrganizationMember();
  const deleteOrganizationMember = useDeleteOrganizationMember();
  const [isRemovingMember, setIsRemovingMember] = useState(false);

  const formMethods = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: getDefaultValues(selectedMember),
    resolver: zodResolver(validationSchema),
  });

  useEffect(() => {
    formMethods.reset(getDefaultValues(selectedMember));
  }, [selectedMember, formMethods]);

  const handleSubmit = useCallback(
    async (data: MemberForm) => {
      const member = {
        ...data,
        proUuid: selectedMember?.proUuid ?? "",
        orgUuid: selectedMember?.orgUuid ?? "",
        isAdmin: !!selectedMember?.isAdmin,
      };
      try {
        await updateOrganizationMember(member as OrganizationMember);
        callSnackbar({
          message: t("membersTab.memberDetailsForm.saveMemberSuccess"),
          type: "info",
        });
        onCloseSidePanel();
      } catch (error) {
        callSnackbar({
          message: t("membersTab.memberDetailsForm.saveMemberError"),
          type: "error",
        });
        console.log(error);
      }
    },
    [
      selectedMember,
      updateOrganizationMember,
      callSnackbar,
      onCloseSidePanel,
      t,
    ]
  );

  const handleRemove = useCallback(async () => {
    if (selectedMember?.isAdmin) {
      callSnackbar({
        message: t("membersTab.memberDetailsForm.deleteAdminError"),
        type: "error",
      });
      return;
    }
    try {
      setIsRemovingMember(true);
      await deleteOrganizationMember({
        proUuid: selectedMember?.proUuid ?? "",
        orgUuid: selectedMember?.orgUuid ?? "",
      });
      callSnackbar({
        message: t("membersTab.memberDetailsForm.deleteMemberSuccess"),
        type: "info",
      });
      onCloseSidePanel();
      setIsRemovingMember(false);
    } catch (error) {
      callSnackbar({
        message: t("membersTab.memberDetailsForm.deleteMemberError"),
        type: "error",
      });
      setIsRemovingMember(false);
    }
  }, [
    callSnackbar,
    deleteOrganizationMember,
    onCloseSidePanel,
    selectedMember,
    t,
  ]);

  const isButtonDisabled = useMemo(() => {
    return formMethods.formState.isSubmitting || isRemovingMember;
  }, [formMethods.formState, isRemovingMember]);

  return (
    <SidePanel<FormValues>
      formMethods={formMethods}
      onSubmit={handleSubmit}
      title={t("membersTab.memberDetailsForm.title")}
      open={isSidePanelOpen}
      onClose={onCloseSidePanel}
      actions={
        <>
          <Button
            color="primary"
            variant="outlined"
            type="button"
            onClick={onCloseSidePanel}
          >
            {t("membersTab.memberDetailsForm.buttons.cancel")}
          </Button>
          <Button
            type="submit"
            isLoading={formMethods.formState.isSubmitting}
            disabled={isButtonDisabled}
          >
            {t("membersTab.memberDetailsForm.buttons.save")}
          </Button>
          <Button
            color="error"
            isLoading={isRemovingMember}
            disabled={isButtonDisabled}
            type="button"
            onClick={handleRemove}
          >
            {t("membersTab.memberDetailsForm.buttons.remove")}
          </Button>
        </>
      }
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <TextField
          required
          name="firstName"
          label={t("membersTab.memberDetailsForm.firstName")}
        />
        <TextField
          required
          name="lastName"
          label={t("membersTab.memberDetailsForm.lastName")}
        />
        <TextField
          required
          name="title"
          label={t("membersTab.memberDetailsForm.position")}
        />
        <TextField
          required
          disabled
          name="contactEmail"
          label={t("membersTab.memberDetailsForm.email")}
        />

        <Box display="flex" gap={2}>
          <PhoneNumberField
            required
            name="contactPhone"
            label={t("membersTab.memberDetailsForm.phone")}
            mask="+1 000-000-0000"
            placeholder="+1 XXX-XXX-XXXX"
            unmask
          />
          <TextField
            name="contactPhoneExt"
            label={t("membersTab.memberDetailsForm.phoneExt")}
          />
        </Box>
      </Box>
    </SidePanel>
  );
}
