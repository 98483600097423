import { ApprovedBadgeIcon, Button, palette } from "@pairtreefamily/ui";
import { format } from "date-fns";
import { useTranslation } from "next-i18next";
import { Card } from "@shared/components";
import RotateRightRounded from "@mui/icons-material/RotateRightRounded";
import { Typography, Box } from "@mui/material";

export type HomeStudyStatusCardProps = {
  approvedAt: Date | null;
  expiresAt: Date | null;
  onComplete: () => void;
};

export function HomeStudyStatusCard({
  approvedAt,
  expiresAt,
  onComplete,
}: HomeStudyStatusCardProps) {
  const { t } = useTranslation("professionalCase");

  return (
    <Card>
      <>
        <Typography variant="head6" component="h3">
          {t("statusCard.caption")}
        </Typography>
        <Box display={"flex"} gap={1} mt={2} mb={2}>
          <Typography component="span" color={palette.secondary.main}>
            {approvedAt ? <ApprovedBadgeIcon /> : <RotateRightRounded />}
          </Typography>
          <Typography
            component="span"
            color={palette.secondary.main}
            variant="body2"
            my="auto"
          >
            <strong>
              {approvedAt
                ? t("statusCard.approvedStatuses.approved")
                : t("statusCard.approvedStatuses.inProgress")}
            </strong>
          </Typography>
        </Box>
        {approvedAt && (
          <Box>
            <Box>
              <Typography variant="body4" component="p">
                <strong>{t("statusCard.dates.approved")}</strong>
              </Typography>
              <Typography variant="body6" component="p">
                {format(approvedAt, "LLLL")} {format(approvedAt, "d")},{" "}
                {format(approvedAt, "yyyy")}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="body4" component="p">
                <strong>{t("statusCard.dates.expiration")}</strong>
              </Typography>
              {expiresAt && (
                <Typography variant="body6" component="p">
                  {format(expiresAt, "LLLL")} {format(expiresAt, "d")},{" "}
                  {format(expiresAt, "yyyy")}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {!approvedAt && (
          <Box>
            <Typography
              variant="body6"
              component="p"
              mb={2}
              color={palette.success.light}
            >
              {t("statusCard.isApproved")}
            </Typography>
            <Button onClick={onComplete}>
              <Typography
                variant="body6"
                color={palette.primary.contrastText}
                component="span"
              >
                {t("statusCard.markComplete")}
              </Typography>
            </Button>
          </Box>
        )}
      </>
    </Card>
  );
}
