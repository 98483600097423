import type { FamilyProfileDetails } from "@api";
import { useMemo } from "react";
import { getFamilyName } from "@pairtreefamily/utils";

export const useGetFullFamilyName = (familyDetails?: FamilyProfileDetails) => {
  return useMemo(() => {
    if (!familyDetails?.adoptiveParents.length) return "";

    const { adoptiveParents } = familyDetails;

    return getFamilyName({
      parentFirstName: adoptiveParents[0]?.firstName,
      parentLastName: adoptiveParents[0]?.lastName,
      secondParentFirstName: adoptiveParents[1]?.firstName,
      secondParentLastName: adoptiveParents[1]?.lastName,
    });
  }, [familyDetails]);
};
