import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import { FamilyStructure } from "@_types/*";

export const useFamilyStructureOptions = () => {
  const { t } = useTranslation("connect");

  return useMemo(
    () => [
      {
        id: FamilyStructure.StatAtHomeParent,
        label: t(
          "familyListing.filters.fields.familyStructure.options.stayAtHomeParent"
        ),
      },
      {
        id: FamilyStructure.WithChildren,
        label: t(
          "familyListing.filters.fields.familyStructure.options.withChildren"
        ),
      },
      {
        id: FamilyStructure.Married,
        label: t(
          "familyListing.filters.fields.familyStructure.options.married"
        ),
      },
      {
        id: FamilyStructure.SingleParentFamily,
        label: t(
          "familyListing.filters.fields.familyStructure.options.singleParentFamily"
        ),
      },
      {
        id: FamilyStructure.LgbtFamily,
        label: t(
          "familyListing.filters.fields.familyStructure.options.lgbtFamily"
        ),
      },
    ],
    [t]
  );
};
