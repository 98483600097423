import { Box, Typography } from "@mui/material";
import { palette } from "@pairtreefamily/ui";
import { Card, RadialProgressBar } from "@shared/components";
import { useTranslation } from "react-i18next";

export function ProgressCard(props: { percentComplete: number }) {
  const { t } = useTranslation("professionalCase");

  return (
    <Card>
      <Box mb={4} display="flex" gap={3}>
        <div>
          <Typography pb={0.5} component="h3" variant="head5">
            {t("progressCard.caption")}
          </Typography>

          <Typography
            component="p"
            variant="body6"
            color={palette.success.light}
          >
            {t("progressCard.description")}
          </Typography>
        </div>

        <Box display="flex" justifyItems="center">
          <Box maxWidth={200}>
            <RadialProgressBar percentComplete={props.percentComplete} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
