export const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  actionsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionsBackground: `linear-gradient(180deg, rgba(247, 247, 247, 0.5) 0%, #FCFCFC 43.75%, rgba(246, 246, 246, 0.8) 91.67%, #F0F0F0 100%)`,
  familyListings: {
    paddingTop: 3,
    paddingBottom: 3,
  },
};
