import {
  AutocompleteField,
  CheckboxField,
  FormModal,
  TextField,
  PhoneNumberField,
} from "@pairtreefamily/ui";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { useGetProviderOptions } from "./hooks";
import { defaultValues, newProviderKey } from "./constants";
import { Candidate } from "./types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormValues, validationSchema } from "./validation-schema";
import { Box } from "@mui/material";
import { Result } from "@pairtreefamily/utils";

export type ProviderAssignmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  assignProvider: (args: {
    email: string;
    firstName: string;
    lastName: string;
    isAdmin: boolean;
    profilePictureUrl: string;
    phoneNumber: string;
    showContactInfo: boolean;
  }) => Promise<Result<unknown, { code: string; message: unknown }>>;
  candidates: Candidate[];
};

export function ProviderAssignmentModal(props: ProviderAssignmentModalProps) {
  const { candidates, assignProvider, isOpen, onClose } = props;
  const { t } = useTranslation("admin");
  const providerOptions = useGetProviderOptions(candidates);

  const formMethods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const provider = formMethods.watch("provider");

  const [error, setError] = useState<unknown | null>(null);

  useEffect(() => {
    if (provider !== newProviderKey) {
      const person = candidates.find((c) => c.email === provider);

      formMethods.reset({
        provider,
        // email is provider id
        email: provider,
        firstName: person?.firstName ?? "",
        lastName: person?.lastName ?? "",
        phoneNumber: person?.phoneNumber ?? "",
        profilePictureUrl: person?.profilePicture ?? "",
        isAdmin: person?.isAdmin ?? false,
        showContactInfo: false,
      });
    } else {
      formMethods.reset(defaultValues);
    }
  }, [candidates, formMethods, provider]);

  const handleClose = useCallback(() => {
    setError(null);
    formMethods.reset(defaultValues);
    onClose();
  }, [formMethods, onClose]);

  const handleSubmit = useCallback(
    async ({ provider, ...otherValues }: FormValues) => {
      const res = await assignProvider({
        ...otherValues,
      });
      if (!res.ok) {
        setError(res.error.message);
      } else {
        handleClose();
      }
    },
    [assignProvider, handleClose]
  );

  return (
    <FormModal
      submitLabel={t("providerAssignmentModal.action")}
      formMethods={formMethods}
      onSubmit={handleSubmit}
      title={t("providerAssignmentModal.title")}
      open={isOpen}
      onClose={handleClose}
    >
      <AutocompleteField
        name="provider"
        options={providerOptions}
        label={t("providerAssignmentModal.fields.provider")}
      />
      <TextField
        name="email"
        label={t("providerAssignmentModal.fields.email")}
      />
      <TextField
        name="firstName"
        label={t("providerAssignmentModal.fields.firstName")}
      />
      <TextField
        name="lastName"
        label={t("providerAssignmentModal.fields.lastName")}
      />
      <PhoneNumberField
        name="phoneNumber"
        label={t("providerAssignmentModal.fields.phoneNumber")}
        mask="+1 000-000-0000"
        placeholder="+1 XXX-XXX-XXXX"
        unmask
      />

      <TextField
        name="profilePictureUrl"
        label={t("providerAssignmentModal.fields.imageURL")}
      />

      <Box mt={2}>
        <CheckboxField
          name="showContactInfo"
          label={t("providerAssignmentModal.fields.showContactInfo")}
        />

        <CheckboxField
          name="isAdmin"
          label={t("providerAssignmentModal.fields.isAdmin")}
        />
      </Box>
      {error?.toString() && (
        <Box my={4} color="error.main">
          {error.toString()}
        </Box>
      )}
    </FormModal>
  );
}
