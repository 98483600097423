import { useCallback, useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Loading, useAuthStatus } from "@pairtreefamily/ui";
import { useGetAllCases, useGetAuthUserData } from "@api";
import { AdminView } from "./components/AdminView";
import { UnauthorizedMessage } from "@shared/components";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";

export const AdminPage = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { t: tAdmin } = useTranslation("admin");

  useEffect(() => {
    z.setErrorMap(makeZodI18nMap({ t }));
  }, [t]);

  const { authedUser, isLoading: isAuthStatusLoading } = useAuthStatus();
  const { data: userData, isLoading: isUserDataLoading } = useGetAuthUserData();

  const {
    data: caseData,
    isLoading: isCaseDataLoading,
    refetch: refetchAllCases,
  } = useGetAllCases();

  const changeCase = useCallback(
    (caseId: string | null) => {
      caseId && router.replace(`/admin/${caseId}`);
    },
    [router]
  );

  const currentCase = useMemo(() => {
    if (!caseData || !caseData.ok || !caseData.content) {
      return null;
    }

    const urlCaseId = router.query.caseId;
    const caseId = Array.isArray(urlCaseId) ? urlCaseId[0] : urlCaseId;
    if (!caseId) {
      // if no caseid is specified, use the first one that is not hidden
      const c = caseData.content.filter(
        (item) =>
          item.hidden === (localStorage.getItem("showHiddenCases") === "true")
      )[0];
      if (!c) {
        router.replace(`/admin/${caseData.content[0].id}`, undefined, {
          shallow: true,
        });
        return c;
      }
      // update the url to include caseId
      router.replace(`/admin/${c?.id}`, undefined, { shallow: true });
      return c;
    }

    return caseData.content.find((c) => c?.id === caseId) ?? null;
  }, [router, caseData]);

  const prefixedCases = useMemo(() => {
    if (!caseData || !caseData.ok || !caseData.content) {
      return [];
    }
    return caseData.content.map((item) => {
      return {
        ...item,
        parentLastName: `${item.hidden ? "(hidden) " : ""}${
          item.parentLastName
        }`,
      };
    });
    // TODO: prefixCases variable is recalculated when AdminAuthWrapper rerenders AND showHiddenCases / caseData was changed.
    // This looks like it can be simplified and may produce issues if we got localStorage changed, but AdminAuthWrapper is not rerendered
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseData, localStorage.getItem("showHiddenCases")]);

  if (isAuthStatusLoading || isUserDataLoading || isCaseDataLoading) {
    return <Loading />;
  }

  if (!authedUser?.email || !userData || !userData.ok) {
    return <UnauthorizedMessage />;
  }

  if (!caseData?.ok) {
    return <p>{tAdmin("errors.emptyCases")}</p>;
  }

  if (!currentCase) {
    return null;
  }

  return (
    <AdminView
      refetchAllCases={refetchAllCases}
      cases={prefixedCases}
      currentCase={currentCase}
      setCaseId={changeCase}
    />
  );
};
