import { useRouter } from "next/router";
import Head from "next/head";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { appConfig } from "@lib";

export const NotFoundPage = () => {
  const { query } = useRouter();
  const { t } = useTranslation("notFound");

  return (
    <>
      <Head>
        <title>
          {t("caption", {
            companyName: appConfig.companyName,
            appName: appConfig.appName,
          })}
        </title>
      </Head>

      <div className="fixed top-0 flex h-screen w-full items-center justify-center">
        <div className="flex items-center gap-2">
          <h1 className="text-head-2 text-gray-tint">{t("errorCode")}</h1>
          <div className="h-[40px] w-[1px] bg-darkblue-shade"></div>

          <div>
            <div>{t("description")}</div>

            {query.redirectLink && (
              <Link className="font-bold" href={query.redirectLink as string}>
                {query.text}
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
