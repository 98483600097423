import { Tab, Tabs } from "@mui/material";
import { useTranslation } from "next-i18next";
import { SyntheticEvent, useCallback } from "react";

export enum ProfessionalTabEnum {
  FamilyTasks,
  MyTasks,
}

export type ProfessionalTabsProps = {
  value: number;
  onChange: (newValue: ProfessionalTabEnum) => void;
};

export const ProfessionalPageTabs = ({
  onChange,
  value,
}: ProfessionalTabsProps) => {
  const { t } = useTranslation("professionalCase");

  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: ProfessionalTabEnum) => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <Tabs value={value} onChange={handleChange}>
      <Tab
        label={t("tabs.familyTasks")}
        id={`tab-${ProfessionalTabEnum.FamilyTasks}`}
      />

      <Tab
        label={t("tabs.myTasks")}
        id={`tab-${ProfessionalTabEnum.MyTasks}`}
      />
    </Tabs>
  );
};
