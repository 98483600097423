import { Box, Fade, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { Loading, palette, Switch } from "@pairtreefamily/ui";
import { usePublishStatus } from "./hooks/use-publish-status";
import { styles } from "./styles";
import { memo, useState, useEffect } from "react";
import { ContentWrapper } from "@shared/components";
import {
  OrganizationTabEnum,
  OrganizationTabs,
  Overview,
  Members,
} from "./components";
import { useGetOrganization } from "@api";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";
import { useUnsavedChanges } from "@pairtreefamily/ui";

export const Organization = memo(() => {
  const { t: tMain } = useTranslation();
  const { t } = useTranslation("organization");
  const {
    handleStatusChange,
    currentStatus,
    isLoaded: isPublishStatusLoaded,
  } = usePublishStatus();

  const { detectUnsavedChanges } = useUnsavedChanges();

  const { error, data } = useGetOrganization();

  useEffect(() => {
    z.setErrorMap(makeZodI18nMap({ t: tMain }));
  }, [tMain]);

  const [currentTab, setCurrentTab] = useState(OrganizationTabEnum.Overview);

  const handleTabChange = async (newValue: OrganizationTabEnum) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={styles.mainWrapper}>
      <ContentWrapper background={palette.background.light50}>
        <Box sx={styles.headingWrapper}>
          <Typography variant="head4">{t("title")}</Typography>

          <Box sx={styles.description}>
            <Typography maxWidth={590} variant="input">
              {t("description")}
            </Typography>

            <Box sx={styles.toggleWrapper}>
              <Typography variant="body4">
                {t("publishStatus.title")}
              </Typography>

              <Fade unmountOnExit in={isPublishStatusLoaded}>
                <div>
                  <Switch
                    disabled={Boolean(error)}
                    checked={Boolean(currentStatus)}
                    labelPlacement="start"
                    label={t(`publishStatus.${currentStatus ? "on" : "off"}`)}
                    onChange={handleStatusChange}
                  />
                </div>
              </Fade>
            </Box>
          </Box>
        </Box>
      </ContentWrapper>

      <ContentWrapper background={styles.tabsBackground}>
        <OrganizationTabs
          value={currentTab}
          onChange={detectUnsavedChanges(handleTabChange)}
        />
      </ContentWrapper>

      {!data ? (
        <Loading />
      ) : (
        <>
          <Fade
            exit={false}
            in={currentTab === OrganizationTabEnum.Overview}
            unmountOnExit
          >
            <Box sx={{ overflowY: "auto" }} pt={5}>
              <Overview data={data} />
            </Box>
          </Fade>

          <Fade
            exit={false}
            in={currentTab === OrganizationTabEnum.Members}
            unmountOnExit
          >
            <Box pt={5} height="100%">
              <Members />
            </Box>
          </Fade>
        </>
      )}
    </Box>
  );
});

Organization.displayName = "Organization";
