import { useCallback, useEffect, useState } from "react";
import { useSnackbarNotification } from "@pairtreefamily/ui";
import { useGetOrganization, useUpdateOrganizationPublishStatus } from "@api";

export const usePublishStatus = () => {
  const { data: organizationData, error: organizationError } =
    useGetOrganization();
  const [currentStatus, setCurrentStatus] = useState<boolean | null>(
    organizationData?.isPublished || null
  );
  const { callSnackbar } = useSnackbarNotification();
  const updateStatus = useUpdateOrganizationPublishStatus();

  useEffect(() => {
    if (organizationError) {
      callSnackbar({
        message: "Error fetching organization data",
        type: "error",
      });
    }
  }, [callSnackbar, organizationError]);

  useEffect(() => {
    if (organizationData) {
      setCurrentStatus(organizationData.isPublished);
    }
  }, [organizationData]);

  const handleStatusChange = useCallback(
    async (isChecked: boolean) => {
      setCurrentStatus(isChecked);

      try {
        await updateStatus({ isPublished: isChecked });
      } catch (error) {
        callSnackbar({
          message: "Error updating status",
          type: "error",
        });

        setCurrentStatus(!isChecked);
      }
    },
    [callSnackbar, updateStatus]
  );

  return {
    currentStatus,
    handleStatusChange,
    isLoaded: !!organizationData && currentStatus !== null,
  };
};
