import { useState, useCallback, ChangeEvent, useRef, useMemo } from "react";
import { useTranslation } from "next-i18next";
import { Box, InputBaseComponentProps, SxProps, Theme } from "@mui/material";
import { palette, TextArea, theme, typography } from "@pairtreefamily/ui";

import { debounce } from "@pairtreefamily/utils";
import { TextAreaWithMaxCharacters } from "@shared/components";

const textAreaRows = 15;

const containerStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
};

const textAreaStyle: SxProps<Theme> = {
  flexGrow: 1,
  "& .MuiInputBase-root": {
    height: "100%",
    padding: `0 ${theme.spacing(1)} 0 ${theme.spacing(3)}`,
    borderRadius: "15px",
    backgroundColor: palette.background.default,
    boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.02)",
  },
  "& .MuiInputBase-input::placeholder": {
    ...typography.body7,
  },
  "& .MuiOutlinedInput-input.MuiInputBase-inputMultiline": {
    padding: `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(
      3
    )} 0!important`,
  },
};

const textAreaInputProps: InputBaseComponentProps = {
  style: {
    boxSizing: "border-box",
    height: "100%",
    borderRadius: 0,
    ...typography.body6,
  },
};

export type CaseNotesCardProps = {
  initialValue?: string | null;
  debounceDelay?: number;
  maxLength?: number;
  onUpdate: (value: string) => void;
};

export const CaseNotesCard = ({
  initialValue = "",
  debounceDelay = 1500,
  maxLength = 400,
  onUpdate,
}: CaseNotesCardProps) => {
  const textAreaRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState(initialValue ?? "");
  const lastUpdatedValueRef = useRef(initialValue ?? "");

  const { t } = useTranslation("professionalCase");

  const debouncedSetValue = useMemo(() => {
    const debounced = debounce((newValue: string) => {
      onUpdate(newValue);
      lastUpdatedValueRef.current = newValue;
    }, debounceDelay);

    return {
      call: debounced,
      cancel: () => debounced.cancel(),
    };
  }, [onUpdate, debounceDelay]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const inputText = event.target.value;

      setValue(inputText);
      debouncedSetValue.call(inputText);
    },
    [debouncedSetValue]
  );

  const handleBlur = () => {
    debouncedSetValue.cancel();

    if (value !== lastUpdatedValueRef.current) {
      onUpdate(value);
      lastUpdatedValueRef.current = value;
    }
  };

  return (
    <Box sx={containerStyle} style={{ height: "calc(100% - 28px)" }}>
      <TextAreaWithMaxCharacters
        value={value}
        maxLength={400}
        renderComponent={({ onBlur, ...props }) => (
          <TextArea
            inputRef={textAreaRef}
            value={value}
            onChange={handleChange}
            placeholder={t("caseNotesCard.placeholder")}
            onBlur={() => {
              handleBlur();
              onBlur();
            }}
            rows={textAreaRows}
            sx={textAreaStyle}
            inputProps={{
              maxLength,
              ...textAreaInputProps,
            }}
            {...props}
          />
        )}
      />
    </Box>
  );
};
