import { ThreadListItem } from "@api";
import { ThreadListingRow, ThreadsTableField } from "../types";
import { getFullName } from "@pairtreefamily/utils";

export const useTableData = (threads: ThreadListItem[]): ThreadListingRow[] => {
  const mappedData = threads.map((thread) => {
    return {
      [ThreadsTableField.Id]: thread.uuid,
      [ThreadsTableField.Title]: thread.title,
      [ThreadsTableField.AuthorFullName]: getFullName(
        thread.author.firstName,
        thread.author.lastName
      ),
      [ThreadsTableField.AuthorFirstName]: thread.author.firstName,
      [ThreadsTableField.AuthorLastName]: thread.author.lastName,
      [ThreadsTableField.AuthorOrganization]: thread.orgName,
      [ThreadsTableField.AuthorAvatar]: thread.author.avatar,
      [ThreadsTableField.Comments]: thread.commentsCount,
      [ThreadsTableField.LastUpdated]: thread.updatedAt,
    };
  });

  return [...mappedData].sort((a, b) => {
    return (
      new Date(b[ThreadsTableField.LastUpdated]).getTime() -
      new Date(a[ThreadsTableField.LastUpdated]).getTime()
    );
  });
};
