export enum DashboardField {
  FamilyName = "familyName",
  State = "state",
  ActivityStatus = "active",
  HomeStudyStatus = "status",
  ApprovedDate = "approvedDate",
  ExpiredDate = "expiredDate",
  Contact = "familyContact",
  Notes = "quickNote",
  CaseId = "caseId",
  Hidden = "hidden",
}

export type DashboardRow = {
  [DashboardField.FamilyName]: string[];
  [DashboardField.State]: string;
  [DashboardField.ActivityStatus]: boolean;
  [DashboardField.HomeStudyStatus]?: string;
  [DashboardField.ApprovedDate]: string | undefined | null;
  [DashboardField.ExpiredDate]?: string | undefined | null;
  [DashboardField.Contact]: string;
  [DashboardField.Notes]: string;
  [DashboardField.CaseId]: string;
  [DashboardField.Hidden]: boolean;
};
