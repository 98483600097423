import { PandadocRole, TasksWithTemplateRoles } from "../../types";
import { ElectronicDocumentTask } from "@_types/*";
import { AdminTools } from "../../hooks/useAdminTools";

// Added caching mechanism to reduce amount of Pandadoc requests
const templateRolesMap = new Map<string, PandadocRole[]>();

export function getTemplateRole(
  role: Omit<PandadocRole, "templateUuid"> | null,
  templateUuid: string | null
) {
  return {
    name: role?.name ?? "",
    id: role?.id ?? "",
    signing_order: role?.signing_order ?? "",
    preassigned_person: role?.preassigned_person ?? "",
    templateUuid: templateUuid ?? "",
  };
}

export async function getTaskRolesRequestPromise(
  task: ElectronicDocumentTask,
  getTemplateRoles: AdminTools["getTemplateRoles"]
) {
  return new Promise<TasksWithTemplateRoles>(async (resolve) => {
    let roles: PandadocRole[] = [getTemplateRole(null, task.templateUuid)];

    if (typeof task.templateUuid !== "string") {
      return resolve({
        ...task,
        templateRoles: roles,
      });
    }

    // If we already have the roles in Map for this template, return them
    if (templateRolesMap.has(task.templateUuid)) {
      return resolve({
        ...task,
        templateRoles: templateRolesMap.get(task.templateUuid)!,
      });
    }

    const response = await getTemplateRoles({
      templateId: task.templateUuid,
    });

    if (response.ok) {
      roles = response.content.map((item) => {
        return getTemplateRole(item, task.templateUuid ?? "");
      });

      templateRolesMap.set(task.templateUuid, roles);
    }

    return resolve({
      ...task,
      templateRoles: roles,
    });
  });
}
