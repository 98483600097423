import { Box, Typography } from "@mui/material";
import { Button, useAuthStatus } from "@pairtreefamily/ui";
import { useTranslation } from "react-i18next";
import { Routes } from "@features/routing";
import { useRouter } from "next/router";
import { useCallback } from "react";

type StepTwoProps = {
  title: string;
  body: string;
};

export function StepTwo({ title, body }: StepTwoProps) {
  const { t } = useTranslation("professional");
  const { signOut } = useAuthStatus();
  const router = useRouter();

  const handleSignOut = useCallback(async () => {
    signOut();

    await router.replace(Routes.ProAccountSignIn);
  }, [router, signOut]);

  return (
    <>
      <Typography variant="head5" component="h1" textAlign="center" mb={2}>
        {title}
      </Typography>
      <Typography variant="head5" component="h1" textAlign="center" mb={4}>
        {body}
      </Typography>
      <Typography variant="input" textAlign="center" component="p" mb={4}>
        {t("authorization.organization.thanks.signature")}
      </Typography>
      <Box display="flex" justifyContent="center">
        <Box maxWidth="228px" width="100%">
          <Button onClick={handleSignOut} fullWidth>
            {t("authorization.organization.thanks.button")}
          </Button>
        </Box>
      </Box>
    </>
  );
}
