import {
  phoneNumberValidation,
  requiredString,
  websiteValidation,
} from "@shared/validation-schemas";
import { z } from "zod";

export const validationSchema = z.object({
  contactFirstName: requiredString,
  contactLastName: requiredString,
  orgName: requiredString,
  contactPhone: phoneNumberValidation,
  website: websiteValidation,
  orgType: requiredString,
});

export type FormValues = z.infer<typeof validationSchema>;
