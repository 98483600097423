export const styles = {
  heading: {
    display: "flex",
    justifyContent: "space-between",
    gap: 2,
  },
  filter: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  filterIcon: {
    width: "24px",
    height: "24px",
  },
  search: {
    width: 320,
  },
  table: {
    mt: 3,
    overflow: "auto",
  },
  pagination: {
    mt: 2,
    display: "flex",
    justifyContent: "flex-end",
  },
};
