import Link from "next/link";
import { Card } from "@shared/components";
import { useTranslation } from "next-i18next";
import { Box, Typography } from "@mui/material";
import { palette } from "@pairtreefamily/ui";

export type FamilyDocumentsCardProps = {
  percentComplete: number;
  urlCompletedDocuments: string;
  urlPrivateDocuments: string;
};

export function FamilyDocumentsCard({
  urlCompletedDocuments,
  urlPrivateDocuments,
}: FamilyDocumentsCardProps) {
  const { t } = useTranslation("professionalCase");

  return (
    <Card>
      <Typography variant="head6" component="h3" mb={2}>
        {t("documentsCard.caption")}
      </Typography>
      <Box gap={2}>
        <Typography component="p" variant="body4">
          {t("documentsCard.completedDocuments.caption")}
        </Typography>

        <Typography component="p" variant="body6" color={palette.success.light}>
          {t("documentsCard.completedDocuments.description")}
        </Typography>

        <p>
          <Link href={urlCompletedDocuments} target="_blank">
            <Typography component="p" variant="body4">
              {t("documentsCard.completedDocuments.viewAll")}
            </Typography>
          </Link>
        </p>
      </Box>

      <Box mt={2} mb={4} gap={2}>
        <Typography component="p" variant="body4">
          {t("documentsCard.privateDocuments.caption")}
        </Typography>

        <Typography component="p" variant="body6" color={palette.success.light}>
          {t("documentsCard.privateDocuments.description")}
        </Typography>

        <p>
          <Link href={urlPrivateDocuments} target="_blank">
            <Typography component="p" variant="body4">
              {t("documentsCard.privateDocuments.viewAll")}
            </Typography>
          </Link>
        </p>
      </Box>
    </Card>
  );
}
