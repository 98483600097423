import { Organization } from "@api";
import { FormValues } from "./validation-schema";

export function removeProtocol(url: string): string {
  return url.replace(/^(https?:\/\/)/, "");
}

export function getDefaultValues(organization?: Organization): FormValues {
  return {
    name: organization?.name || "",
    website: organization?.website || "",
    contactEmail: organization?.contactEmail || "",
    contactPhone: organization?.contactPhone || "",
    contactPhoneExt: organization?.contactPhoneExt || "",
    states: organization?.states || [],
    orgType: organization?.orgType || "",
    description: organization?.description || "",
    services: organization?.services || [],
  };
}
