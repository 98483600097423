import { DateInputField, CheckboxField, FormModal } from "@pairtreefamily/ui";
import { Fragment, useCallback } from "react";
import { Trans, useTranslation } from "next-i18next";
import { appConfig } from "@lib";
import { useForm } from "react-hook-form";
import { FormValues, validationSchema } from "./validation-schema";
import { zodResolver } from "@hookform/resolvers/zod";

export type CompleteCaseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (args: {
    approvalDate: Date;
    expirationDate: Date;
  }) => Promise<void>;
};

export function CompleteCaseModal(props: CompleteCaseModalProps) {
  const { t } = useTranslation(["professionalCase", "common"]);

  const formMethods = useForm<FormValues>({
    defaultValues: {
      approvalDate: undefined,
      expirationDate: undefined,
      confirm: false,
    },
    resolver: zodResolver(validationSchema),
  });

  const handleClose = useCallback(() => {
    props.onClose();
    formMethods.reset();
  }, [formMethods, props]);

  const handleSubmit = useCallback(
    async ({ confirm, ...dates }: FormValues) => {
      await props.onSubmit({
        ...dates,
      });

      handleClose();
    },
    [handleClose, props]
  );

  // there are validation logic inside the DateInput component that fails when we close modal and reset form
  if (!props.isOpen) {
    return null;
  }

  return (
    <FormModal
      formMethods={formMethods}
      onSubmit={handleSubmit}
      submitLabel={t("common:actions.submit")}
      title={t("professionalCase:completeCaseModal.title")}
      open={props.isOpen}
      onClose={handleClose}
    >
      <div className="text-body-3 flex justify-between px-10">
        <DateInputField
          name="approvalDate"
          label={t("professionalCase:completeCaseModal.fields.approvalDate")}
        />

        <DateInputField
          name="expirationDate"
          label={t("professionalCase:completeCaseModal.fields.expirationDate")}
        />
      </div>

      <div className="px-1.5 py-4 pr-2 text-left">
        <CheckboxField
          name="confirm"
          label={
            <Trans
              t={t}
              i18nKey={
                "professionalCase:completeCaseModal.fields.confirmChecked"
              }
              values={{ email: appConfig.companyContactEmail }}
              components={[
                <Fragment key="0" />,
                <strong key="1" />,
                <a key="2" href={`mailto:${appConfig.companyContactEmail}`} />,
              ]}
            />
          }
        />
      </div>
    </FormModal>
  );
}
