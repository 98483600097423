import { useState } from "react";
import { useTranslation } from "next-i18next";
import {
  FamilyTools,
  UseCaseReturn,
  UseFileUpload,
  useHandleSetAnswer,
} from "@shared/hooks";
import {
  TaskDescription,
  TaskTitle,
  IssueeElectronicDocumentRow,
  IssueeFileUploadRow,
  ReferenceRow,
  TaskStatusElement,
  TaskQuestionStatus,
} from "@shared/components";
import { Task } from "@_types/tasks";
import { Typography } from "@mui/material";
import { useIncrementSaveCount } from "@api";

interface TaskRowProps {
  task: Task;
  caseId: string;
  updateTaskQuestionAnswer: FamilyTools["updateTaskQuestionAnswer"];
  getFileName: UseFileUpload["getFileName"];
  uploadFile: UseFileUpload["uploadFile"];
  deleteFile: UseFileUpload["deleteFile"];
  documentHomeLink: UseCaseReturn["documentHomeLink"];
  getFileUrl: FamilyTools["getFileUrl"];
  sendReferenceRequest: FamilyTools["sendReferenceRequest"];
  refreshElectronicDocumentSession: FamilyTools["refreshElectronicDocumentSession"];
}

export const TaskRow = ({
  task,
  caseId,
  updateTaskQuestionAnswer,
  getFileName,
  uploadFile,
  deleteFile,
  documentHomeLink,
  getFileUrl,
  sendReferenceRequest,
  refreshElectronicDocumentSession,
}: TaskRowProps) => {
  const referenceAuthor = task.taskAssignments[0]?.assignee;
  const [answer, setAnswer] = useState<boolean | null>(null);

  const { t } = useTranslation("components");
  const handleSetAnswer = useHandleSetAnswer({
    taskId: task.id,
    caseId,
    updateTaskQuestionAnswer,
    setAnswer,
  });
  const incrementSaveCount = useIncrementSaveCount({
    caseId,
  });

  switch (task.taskType) {
    case "fileUpload": {
      const date = new Date().toISOString().slice(0, 10);
      const time = new Date().toLocaleTimeString("en-US", {
        hour12: false,
        timeZoneName: "short",
      });

      return IssueeFileUploadRow({
        getFileName: getFileName,
        task: task,
        uploadFile: (file) => {
          uploadFile({
            file: file,
            taskId: task.id,
            fileName: t("taskRow.fileName", {
              taskTitle: task.title,
              fileName: file.name,
              date,
              time,
            }),
            taskGroupId: task.TaskGroup?.id ?? "",
            isPrivate: false,
          });
        },
        answer: answer,
        handleSetAnswer: handleSetAnswer,
        handleDeleteFile: (fileId: string, fileName: string) =>
          deleteFile({
            fileId: fileId,
            fileName: fileName,
            taskId: task.id,
            taskGroupId: task.TaskGroup?.id ?? "",
          }),
        viewDocumentsLink:
          (documentHomeLink?.ok && documentHomeLink.content) || null,
        getFileUrl: (fileId) => getFileUrl({ caseId, fileId }),
      });
    }
    case "electronicDocument": {
      return IssueeElectronicDocumentRow({
        task: task,
        answer: answer,
        handleSetAnswer: handleSetAnswer,
        onSave: async () => {
          await incrementSaveCount({ taskId: task.id });
        },
        refreshElectronicDocumentSession: refreshElectronicDocumentSession,
      });
    }
    case "reference": {
      return {
        column1: (
          <>
            <TaskTitle task={task} answer={answer} onAnswer={handleSetAnswer} />
            {(task.status === "sent" || task.status === "inProgress") &&
              referenceAuthor && (
                <Typography component="p" variant="body6">
                  {task.status === "sent"
                    ? t("taskRow.taskStatusType.requested", {
                        firstName: referenceAuthor.firstName,
                        lastName: referenceAuthor.lastName,
                        email: referenceAuthor.email,
                      })
                    : t("taskRow.taskStatusType.inProgress", {
                        firstName: referenceAuthor.firstName,
                        lastName: referenceAuthor.lastName,
                        email: referenceAuthor.email,
                      })}
                </Typography>
              )}
            {task.status === "completed" && referenceAuthor && (
              <Typography component="p" variant="body6">
                {t("taskRow.taskStatusType.completed", {
                  firstName: referenceAuthor.firstName,
                  lastName: referenceAuthor.lastName,
                })}
              </Typography>
            )}
          </>
        ),
        column2: <TaskDescription task={task} />,
        column3: task.question ? (
          <TaskQuestionStatus task={task} isFamily />
        ) : (
          <TaskStatusElement task={task} />
        ),
        column4: (
          <ReferenceRow
            task={task}
            issueReferenceRequest={(recipient) => {
              return sendReferenceRequest({
                task,
                recipient,
                personalNote: recipient.personalNote,
              });
            }}
          />
        ),
      };
    }
    default: {
      const canary: never = task;
      console.warn("Tried to render malformed task; skipping", canary);
      return {};
    }
  }
};
