import { Box, Link, Typography } from "@mui/material";
import { LoginSignUp } from "./components/LoginSignUp";
import { useRouter } from "next/router";
import { Routes } from "@features/routing";
import { LegalSection } from "./components/LegalSection";
import { Layout } from "./components/Layout";
import { appConfig } from "@lib";
import { useTranslation } from "next-i18next";

const boxLayoutStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
};

export function ProfessionalAuthorization() {
  const { t } = useTranslation("professional");

  const router = useRouter();

  return (
    <Layout>
      <Box sx={boxLayoutStyle} justifyContent="space-between">
        <Box sx={boxLayoutStyle} justifyContent="center">
          <div>
            <LoginSignUp />
            {router.route === Routes.ProAccountSignUp ? (
              <LegalSection />
            ) : (
              <Typography
                textAlign="center"
                variant="body4"
                component="p"
                color="textSecondary"
                pt={2}
              >
                {t("authorization.createAccount.notAMember")}{" "}
                <Link underline="none" href={Routes.ProAccountSignUp}>
                  {" "}
                  {t("authorization.createAccount.signUp")}
                </Link>
              </Typography>
            )}
          </div>
        </Box>
        <Box>
          <Typography
            textAlign="center"
            variant="body4"
            component="p"
            color="textSecondary"
            pb={7}
          >
            {t("authorization.createAccount.notAdoptionPro")}
            <Link underline="none" href={appConfig.appPairtree}>
              {" "}
              {t("authorization.createAccount.findLoginPage")}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
}

export default ProfessionalAuthorization;
