import { CellContext } from "@tanstack/react-table";
import Link from "next/link";

import { HighlightedString } from "@shared/components";
import { Box, Typography } from "@mui/material";
import { styles } from "./styles";

import { Routes } from "@features/routing";
import type { DashboardRow } from "../../../../types";

type FamilyNameCellProps = {
  cell: CellContext<DashboardRow, string[]>;
  searchQuery: string;
};

export const FamilyNameCell = ({ cell, searchQuery }: FamilyNameCellProps) => {
  const [firstParentName, secondParentName] = cell.getValue();
  const casePathName = `${Routes.ProfessionalDashboard}/${cell.row.original.caseId}`;

  return (
    <Box sx={styles.wrapper}>
      <Link href={casePathName}>
        <Typography variant="body4" color="text.secondary">
          <HighlightedString
            value={firstParentName}
            searchQuery={searchQuery}
          />
        </Typography>

        {secondParentName && (
          <Typography component="div" variant="body6" color="text.light">
            <HighlightedString
              value={secondParentName}
              searchQuery={searchQuery}
            />
          </Typography>
        )}
      </Link>
    </Box>
  );
};
