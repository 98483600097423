import { customScroll, palette } from "@pairtreefamily/ui";

export const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflowY: "auto",
  },
  commentsListWrapper: {
    overflowY: "auto",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    px: 3,

    ...customScroll,
  },
  divider: {
    backgroundColor: palette.divider,
    height: "1px",
    width: "100%",
    mt: "-1px",
  },
  commentsWrapper: (index: number, length: number) => {
    return {
      borderBottom:
        index === length - 1 ? "none" : `solid 1px ${palette.divider}`,
    };
  },
};
