import { Box, Divider, Link, Typography } from "@mui/material";
import { palette, theme, typography } from "@pairtreefamily/ui";
import { Logo } from "./LogoIcon";
import { appConfig } from "@lib";
import { useTranslation } from "next-i18next";

const linksStyle = {
  color: palette.primary.contrastText,
  textDecoration: "none",
  ...typography.body6,
};

const dividerStyle = {
  borderColor: palette.background.light,
};

export function AuthorizationLanding() {
  const { t } = useTranslation("professional");

  return (
    <>
      <Box display="flex" justifyContent="center" height="100%">
        <Box
          maxWidth="480px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
          >
            <Box display="flex" justifyContent="center">
              <Logo />
            </Box>
            <Typography
              variant="body4"
              color={palette.primary.contrastText}
              component="p"
              textAlign="center"
              my={5}
              textTransform="uppercase"
            >
              {t("authorization.caption")}
            </Typography>
            <Typography
              variant="portalHeadline"
              color={palette.primary.contrastText}
              component="h1"
              textAlign="center"
              mb={5}
            >
              {t("authorization.title")}
            </Typography>
            <Typography
              variant="body1"
              color={palette.primary.contrastText}
              component="h2"
              textAlign="center"
            >
              {t("authorization.subtitle")}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body7"
              color={palette.primary.contrastText}
              component="p"
              textAlign="center"
              mt={theme.spacing(2)}
            >
              {t("authorization.legal")}
            </Typography>
            <Box mt={3} display="flex" justifyContent="center" gap={2} pb={7}>
              <Link sx={linksStyle} href={appConfig.pairtreeTerms}>
                {t("authorization.terms")}
              </Link>
              <Divider sx={dividerStyle} orientation="vertical" flexItem />
              <Link sx={linksStyle} href={appConfig.pairtreePrivacy}>
                {t("authorization.privacy")}
              </Link>
              <Divider sx={dividerStyle} orientation="vertical" flexItem />
              <Link sx={linksStyle} href={`mailto:${appConfig.pairtreeHelp}`}>
                {t("authorization.help")}
              </Link>
              <Divider sx={dividerStyle} orientation="vertical" flexItem />
              <Box component="span" sx={linksStyle}>
                {t("authorization.pairtree", {
                  year: new Date().getFullYear(),
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
