import { useEffect, useMemo, useState } from "react";

import { Box, Fade } from "@mui/material";
import { ContentWrapper, useOrganizationsData } from "@shared/components";
import {
  ConnectHeading,
  ConnectTabs,
  FamilyListings,
  OrganizationFamiliesViewToggle,
  PendingInvitations,
} from "./components";

import { styles } from "./styles";
import { palette } from "@pairtreefamily/ui";

import { ConnectTabEnum } from "./types";
import { useLocalStorage } from "@shared/hooks";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";
import { useTranslation } from "next-i18next";

export const Connect = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(ConnectTabEnum.FamilyListings);
  const [isOrgAssociated, setIsOrgAssociated] = useLocalStorage<boolean>(
    "organization-family-view-toggle",
    true
  );

  useEffect(() => {
    z.setErrorMap(makeZodI18nMap({ t }));
  }, [t]);

  const { userOrganizationData } = useOrganizationsData();

  const handleTabChange = (newValue: ConnectTabEnum) => {
    setCurrentTab(newValue);
  };

  const handleOrgAssociationToggle = () => {
    setIsOrgAssociated(!isOrgAssociated);
  };

  const organizationUuid = useMemo(
    () =>
      isOrgAssociated && userOrganizationData?.id
        ? userOrganizationData?.id
        : null,
    [isOrgAssociated, userOrganizationData?.id]
  );

  return (
    <Box sx={styles.wrapper}>
      <ContentWrapper background={palette.background.light50}>
        <ConnectHeading />
      </ContentWrapper>

      <ContentWrapper background={styles.actionsBackground}>
        <Box sx={styles.actionsWrapper}>
          <ConnectTabs value={currentTab} onChange={handleTabChange} />

          {userOrganizationData && (
            <OrganizationFamiliesViewToggle
              checked={isOrgAssociated}
              onChange={handleOrgAssociationToggle}
            />
          )}
        </Box>
      </ContentWrapper>

      <Fade
        in={currentTab === ConnectTabEnum.FamilyListings}
        unmountOnExit
        exit={false}
      >
        <Box sx={styles.familyListings}>
          <FamilyListings organizationUuid={organizationUuid} />
        </Box>
      </Fade>

      <Fade
        in={currentTab === ConnectTabEnum.PendingInvitations}
        unmountOnExit
        exit={false}
      >
        <Box sx={styles.familyListings}>
          <PendingInvitations />
        </Box>
      </Fade>
    </Box>
  );
};
