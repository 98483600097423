import { DotActive, DotApproved } from "@pairtreefamily/ui";
import { CellContext } from "@tanstack/react-table";
import { FamilyListingRow, type FamilyFiltersFields } from "./types";
import { AdoptiveParent } from "@_types/*";

type GetConnectStatusCellProps = CellContext<FamilyListingRow, boolean>;

type GetHomeStudyStatusCellProps = CellContext<FamilyListingRow, boolean>;

export const getConnectStatusIcon = (cell: GetConnectStatusCellProps) => {
  const cellValue = cell.getValue();
  return cellValue ? <DotApproved /> : <DotActive />;
};

export const getHomeStudyStatusIcon = (cell: GetHomeStudyStatusCellProps) => {
  const cellValue = cell.getValue();
  return cellValue ? <DotApproved /> : <DotActive />;
};

export const formatParentFullName = (parent: AdoptiveParent) => {
  if (!parent.firstName && !parent.lastName) return "";

  let nameParts = [];
  if (parent.lastName) nameParts.push(parent.lastName);
  if (parent.firstName) nameParts.push(parent.firstName);

  return nameParts.join(nameParts.length > 1 ? ", " : "");
};

export const filterEmptyFields = (
  filters: FamilyFiltersFields
): FamilyFiltersFields => {
  return Object.entries(filters).reduce<FamilyFiltersFields>(
    (acc, [key, value]) => {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          acc[key as keyof FamilyFiltersFields] = value as any;
        }
      } else if (value !== undefined && value !== null) {
        acc[key as keyof FamilyFiltersFields] = value as any;
      }

      return acc;
    },
    {}
  );
};

export const getActiveFiltersCount = (filters: FamilyFiltersFields) => {
  return Object.values(filters).reduce((count, filter) => {
    if (Array.isArray(filter)) {
      if (filter.length > 0) {
        return count + filter.length;
      }
    } else if (filter !== undefined && filter !== null) {
      return count + 1;
    }

    return count;
  }, 0);
};
