import { useTranslation } from "next-i18next";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { Box, Link } from "@mui/material";
import {
  Button,
  ProfileLiveIcon,
  ProfileBookIcon,
  ProfileVideoIcon,
} from "@pairtreefamily/ui";

export type ProfileLinksProps = {
  onlineProfile?: string;
  profileBook?: string;
  profileVideo?: string;
  onContactClick?: () => void;
};

export const ProfileLinks = (props: ProfileLinksProps) => {
  const { onlineProfile, profileBook, profileVideo } = props;

  const { t } = useTranslation("connect");

  return (
    <Box display="flex" justifyContent="center" gap={2}>
      {onlineProfile && (
        <Button
          component={Link}
          href={onlineProfile}
          target="_blank"
          variant="iconVertical"
          startIcon={<ProfileLiveIcon />}
        >
          {t("familyListing.familyDetails.profileLinks.onlineProfile")}
        </Button>
      )}

      {profileBook && (
        <Button
          component={Link}
          href={profileBook}
          target="_blank"
          variant="iconVertical"
          startIcon={<ProfileBookIcon />}
        >
          {t("familyListing.familyDetails.profileLinks.profileBook")}
        </Button>
      )}

      {profileVideo && (
        <Button
          component={Link}
          href={profileVideo}
          target="_blank"
          variant="iconVertical"
          startIcon={<ProfileVideoIcon />}
        >
          {t("familyListing.familyDetails.profileLinks.profileVideo")}
        </Button>
      )}

      <Button
        onClick={props.onContactClick}
        variant="iconVertical"
        startIcon={<MailOutlineOutlinedIcon />}
      >
        {t("familyListing.familyDetails.profileLinks.contact")}
      </Button>
    </Box>
  );
};
