import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import { useCaseJwt } from "@shared/hooks";
import { useGetFamilyNameByCaseId, useGetReferenceTask } from "@api";
import { appConfig } from "@lib";
import { Button, Modal } from "@pairtreefamily/ui";
import { PandaDocIframe } from "@shared/components";
import { Box, Link, Typography } from "@mui/material";

interface ReferenceTask {
  id: string;
  title: string;
  isPrivate: boolean | null;
  activeSessionUuid: string;
}

export const ReferencesPage = () => {
  const router = useRouter();
  const { t } = useTranslation("references");

  const [pdfTitle, setPdfTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const taskId = router.query.taskId as string;
  const caseId = router.query.caseId as string;
  const { token } = useCaseJwt();

  const { data: task } = useGetReferenceTask({
    enabled: !!token,
    caseId,
    taskId,
  });

  const activeSessionUuid = task?.taskAssignments[0].pandadocSession?.sessionId;
  const hasSessionId =
    activeSessionUuid !== undefined &&
    activeSessionUuid !== null &&
    activeSessionUuid !== "";
  const taskStatus = task?.status;
  const referenceNotCompleted = taskStatus !== "completed";

  const expiresAt = task?.taskAssignments[0].pandadocSession?.expiresAt;

  const { data: famName } = useGetFamilyNameByCaseId({
    caseId,
    enabled: !!token,
  });

  const familyName = famName?.ok ? famName.content : undefined;
  const isExpired = expiresAt && new Date(expiresAt) < new Date();

  function handleOpenPdfModal(task: ReferenceTask) {
    setPdfTitle(task.title);
    setIsModalOpen(true);
  }

  return (
    <Box px={10} py={10} maxWidth={1440}>
      <Typography variant="head2">
        {t("documentRequest.caption", { familyName })}
      </Typography>

      <Box mt={3}>
        {activeSessionUuid && referenceNotCompleted && !isExpired && (
          <>
            <Typography component="div" variant="input">
              {t("documentRequest.description", { familyName })}
              <Link href={`mailto:${appConfig.companyContactEmail}`}>
                {appConfig.companyContactEmail}
              </Link>
              .
            </Typography>

            <Box mt={5}>
              <Button
                onClick={() =>
                  handleOpenPdfModal({
                    id: task.id,
                    title: task.title || t("referenceRequest.title"),
                    activeSessionUuid,
                    isPrivate: task.isPrivate,
                  })
                }
              >
                {t("actions.completeRequest")}
              </Button>
            </Box>
          </>
        )}
        {!hasSessionId && referenceNotCompleted && (
          <Typography variant="input">
            {t("messages.referenceNotCompleted")}
          </Typography>
        )}

        {isExpired && referenceNotCompleted && (
          <Typography
            component="div"
            mt={3}
            variant="input"
            className="text-body-1 mt-8"
          >
            {t("messages.referenceExpired", { familyName })}
          </Typography>
        )}

        {taskStatus === "completed" && (
          <Typography component="div" mt={3} variant="input">
            {t("messages.taskStatusCompleted")}
          </Typography>
        )}
      </Box>

      {isModalOpen && (
        <Modal
          title={pdfTitle}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <>
            {activeSessionUuid && referenceNotCompleted && (
              <PandaDocIframe sessionUuid={activeSessionUuid} />
            )}

            {taskStatus === "completed" && (
              <Typography component="p" variant="input">
                {t("modal.messages.completed")}
              </Typography>
            )}
          </>
        </Modal>
      )}
    </Box>
  );
};
