import { defaultCaseValues } from "./constants";
import type { Case } from "@_types/*";

export const updateFormValues = (currentCase?: Case) => {
  if (!currentCase) {
    return defaultCaseValues;
  }

  const {
    state,
    parentFirstName,
    parentLastName,
    secondParentFirstName,
    secondParentLastName,
    completedDocumentsLocation,
    privateDocumentsLocation,
    familyEmail,
    referenceTemplateUuid,
    referenceAuthorPandadocRole,
    defaultTemplatesFolder,
  } = currentCase;

  return {
    state,
    parentFirstName,
    parentLastName,
    secondParentFirstName: secondParentFirstName ?? "",
    secondParentLastName: secondParentLastName ?? "",
    completedDocumentsLocation: completedDocumentsLocation ?? "",
    privateDocumentsLocation: privateDocumentsLocation ?? "",
    familyEmail,
    referenceTemplateUuid,
    referenceAuthorPandadocRole,
    defaultTemplatesFolder,
  };
};
