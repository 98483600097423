import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";
import { Box, Typography } from "@mui/material";
import { styles } from "./styles";
import { ContentWrapper } from "@shared/components";
import { palette } from "@pairtreefamily/ui";
import { Threads } from "./components/threads";

export const SafetyCenter = () => {
  const { t: tCommon } = useTranslation();
  const { t } = useTranslation("safetyCenter");

  useEffect(() => {
    z.setErrorMap(makeZodI18nMap({ t: tCommon }));
  }, [tCommon]);

  return (
    <Box sx={styles.wrapper}>
      <ContentWrapper background={palette.background.light50}>
        <Box sx={styles.headingWrapper}>
          <Typography variant="head4">{t("title")}</Typography>

          <Box sx={styles.description}>
            <Typography maxWidth={900} variant="input">
              {t("description")}
            </Typography>
          </Box>
        </Box>
      </ContentWrapper>

      <ContentWrapper>
        <Threads />
      </ContentWrapper>
    </Box>
  );
};
