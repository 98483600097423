import { OrganizationService, OrganizationTypes } from "@api";

// TODO: Move labels to translation file after Organization onboarding is completed
export const useOrganizationTypes = () => {
  return [
    {
      id: OrganizationTypes.Agency,
      label: OrganizationTypes.Agency,
    },
    {
      id: OrganizationTypes.Attorney,
      label: OrganizationTypes.Attorney,
    },
    {
      id: OrganizationTypes.HomeStudyProvider,
      label: OrganizationTypes.HomeStudyProvider,
    },
  ];
};

// TODO: Move labels to translation file after Organization onboarding is completed
export const useOrganizationServices = () => {
  return [
    {
      id: OrganizationService.HomeStudy,
      label: "Home Study",
    },
    {
      id: OrganizationService.Matching,
      label: "Matching",
    },
    {
      id: OrganizationService.Placement,
      label: "Placement",
    },
    {
      id: OrganizationService.Support,
      label: "Support & Education",
    },
  ];
};
