export enum ThreadsTableField {
  Id = "id",
  Title = "title",
  AuthorFullName = "authorFullName",
  AuthorFirstName = "authorFirstName",
  AuthorLastName = "authorLastName",
  AuthorOrganization = "authorOrganization",
  AuthorAvatar = "authorAvatar",
  Comments = "comments",
  LastUpdated = "lastUpdated",
}

export type ThreadListingRow = {
  [ThreadsTableField.Id]: string;
  [ThreadsTableField.Title]: string;
  [ThreadsTableField.AuthorFullName]: string;
  [ThreadsTableField.AuthorFirstName]: string;
  [ThreadsTableField.AuthorLastName]: string;
  [ThreadsTableField.AuthorOrganization]: string;
  [ThreadsTableField.AuthorAvatar]: string | null;
  [ThreadsTableField.Comments]: number;
  [ThreadsTableField.LastUpdated]: string;
};
