import { z } from "zod";
import type { State } from "@_types/*";
import { requiredString } from "@shared/validation-schemas";

const emailSchema = z.union([z.literal(""), z.string().email()]);

const folderIdSchema = z.string().refine(
  (value) => {
    if (!value) {
      return true;
    }

    const regex = /^(?!https:\/\/).*/;
    return regex.test(value);
  },
  {
    params: {
      i18n: "custom.noUrlContain",
    },
  }
);

const specialCharactersSchema = z.string().refine(
  (value) => {
    if (!value) {
      return true;
    }

    const regex = /^[^?=/]*$/;
    return regex.test(value);
  },
  {
    params: {
      i18n: "custom.noSpecialCharacters",
    },
  }
);

export const validationSchema = z.object({
  state: z.custom<State>(),
  parentFirstName: requiredString,
  parentLastName: requiredString,
  secondParentFirstName: requiredString,
  secondParentLastName: requiredString,
  completedDocumentsLocation: folderIdSchema.and(specialCharactersSchema),
  privateDocumentsLocation: folderIdSchema.and(specialCharactersSchema),
  familyEmail: emailSchema,
  referenceTemplateUuid: z.string(),
  referenceAuthorPandadocRole: z.string(),
  defaultTemplatesFolder: requiredString,
});

export type FormValues = z.infer<typeof validationSchema>;
