import { useTranslation } from "next-i18next";
import { palette } from "@pairtreefamily/ui";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Box, Typography } from "@mui/material";

export const ProAssociatedWarning = () => {
  const { t } = useTranslation("connect");

  return (
    <Box
      sx={{
        mt: 5,
        mb: 2,
        backgroundColor: palette.primary.backgroundLight,
        p: 2,
        borderRadius: 1,
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <WarningRoundedIcon sx={{ color: palette.text.light, fontSize: 16 }} />

      <Typography variant="body4">
        {t("familyListing.contactFamily.warningMessage")}
      </Typography>
    </Box>
  );
};
