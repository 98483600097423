import { palette } from "@pairtreefamily/ui";

export const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    width: 48,
    height: 48,
  },
  link: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: palette.text.light,
  },
};
