import { FileUploadTask, Task } from "@_types/*";
import { Result } from "@pairtreefamily/utils";
import { IssuerFileUploadRow } from "../issuer-file-upload-row";
import { IssueeFileUploadRow } from "@shared/components";
import { UseProfessionalPageTools } from "@features";
import { UseFileUpload } from "@shared/hooks";

type FileUploadRowProps = {
  pageType: "Professional" | "Family";
  getFileName: UseFileUpload["getFileName"];
  task: FileUploadTask;
  uploadFile: (file: File) => void;
  answer: boolean | null;
  handleSetAnswer: (e: boolean | null) => void;
  handleDeleteFile: (fileId: string, fileName: string) => void;
  setTaskManagerModalOpen: (open: boolean) => void;
  setCurrentTaskBeingManaged: (currentTask: Task) => void;
  viewDocumentsLink: string | null;
  getFileUrl: (fileId: string) => Promise<Result<string, unknown>>;
  refreshTask: UseProfessionalPageTools["refreshTask"];
};
export function FileUploadRow(props: FileUploadRowProps) {
  const {
    pageType,
    task,
    getFileName,
    uploadFile,
    answer,
    handleSetAnswer,
    handleDeleteFile,
    setCurrentTaskBeingManaged,
    setTaskManagerModalOpen,
    viewDocumentsLink,
    getFileUrl,
    refreshTask,
  } = props;

  if (
    pageType === "Family" ||
    (pageType === "Professional" && task.TaskGroup?.providerOnly)
  ) {
    return IssueeFileUploadRow({
      task,
      getFileName,
      uploadFile,
      answer,
      handleSetAnswer,
      handleDeleteFile,
      viewDocumentsLink,
      getFileUrl,
      editTask(task) {
        setCurrentTaskBeingManaged(task);
        setTaskManagerModalOpen(true);
      },
    });
  }

  return IssuerFileUploadRow({
    task,
    setCurrentTaskBeingManaged,
    setTaskManagerModalOpen,
    viewDocumentsLink,
    getFileName,
    getFileUrl,
    refreshTask,
  });
}
