import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import { OpenTo } from "@_types/*";

export const useOpenToOptions = () => {
  const { t } = useTranslation("connect");

  return useMemo(
    () => [
      {
        id: OpenTo.MultipleChildren,
        label: t(
          "familyListing.filters.fields.openTo.options.multipleChildren"
        ),
      },
      {
        id: OpenTo.SpecialNeeds,
        label: t("familyListing.filters.fields.openTo.options.specialNeeds"),
      },
      {
        id: OpenTo.DrugExposure,
        label: t("familyListing.filters.fields.openTo.options.drugExposure"),
      },
    ],
    [t]
  );
};
