import { FormModal, TextField } from "@pairtreefamily/ui";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { FormValues, validationSchema } from "./validation-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReplacedTask } from "./types";
import { ReplacedTasksList } from "./components/ReplacedTasksList";

export type ReplaceTasksTitleProps = {
  isOpen: boolean;
  onClose: () => void;
  replace: (
    placeholder: string,
    value: string
  ) => Promise<ReplacedTask[] | null>;
};

export function ReplaceTasksTitleModal({
  isOpen,
  onClose,
  replace,
}: ReplaceTasksTitleProps) {
  const { t } = useTranslation("admin");

  const [replacedTasks, setReplacedTasks] = useState<ReplacedTask[] | null>(
    null
  );

  const formMethods = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      placeholder: "",
      value: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const placeholder = formMethods.watch("placeholder");
  const value = formMethods.watch("value");

  useEffect(() => {
    setReplacedTasks(null);
  }, [placeholder, value]);

  const handleModalClose = useCallback(() => {
    onClose();
    formMethods.reset();
    setReplacedTasks(null);
  }, [formMethods, onClose]);

  const handleSubmit = useCallback(
    async ({ placeholder, value }: FormValues) => {
      const response = await replace(placeholder.trim(), value.trim());

      setReplacedTasks(response);
    },
    [replace]
  );

  return (
    <FormModal<FormValues>
      formMethods={formMethods}
      onSubmit={handleSubmit}
      title={t("replaceTasksTitle.title")}
      open={isOpen}
      submitLabel={t("replaceTasksTitle.buttons.replace")}
      onClose={handleModalClose}
    >
      <TextField
        name="placeholder"
        label={t("replaceTasksTitle.fields.placeholder")}
      />

      <TextField
        name="value"
        label={t("replaceTasksTitle.fields.replaceFor")}
      />

      {replacedTasks && replacedTasks.length > 0 && (
        <ReplacedTasksList replacedTasks={replacedTasks} />
      )}

      {replacedTasks &&
        !replacedTasks.length &&
        !formMethods.formState.isSubmitting && (
          <h4>{t("replaceTasksTitle.messages.emptyTasks", { placeholder })}</h4>
        )}
    </FormModal>
  );
}
