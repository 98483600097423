import { Typography } from "@mui/material";
import { ProInfoForm } from "./ProInfoForm";
import { useTranslation } from "react-i18next";
import { FormValues } from "./validation-schema";

export function StepOne(props: {
  onSubmit: (data: FormValues) => void;
  isLoading: boolean;
}) {
  const { t } = useTranslation("professional");

  return (
    <>
      <Typography variant="head5" component="h1" textAlign="center" mb={1.5}>
        {t("authorization.organization.welcome")}
      </Typography>
      <Typography variant="head5" component="h1" textAlign="center" mb={3}>
        {t("authorization.organization.subtitle")}
      </Typography>
      <ProInfoForm isLoading={props.isLoading} onSubmit={props.onSubmit} />
    </>
  );
}
