import Link from "next/link";
import { Routes } from "../../../routing";
import { Box, Typography } from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import React from "react";
import { useTranslation } from "next-i18next";

export const BackButton = () => {
  const { t } = useTranslation("professionalCase");

  return (
    <Box height={40}>
      <Link href={Routes.ProfessionalDashboard}>
        <Typography
          variant="body10"
          sx={{ display: "flex", gap: "2px", alignItems: "center" }}
        >
          <ArrowBackIosRoundedIcon sx={{ width: 10 }} />
          <span>{t("familiesLink")}</span>
        </Typography>
      </Link>
    </Box>
  );
};
