export const styles = {
  wrapper: {
    flex: "0 0 auto",
  },
  detailsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 4,
  },
  authorInfoWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  authorNameWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  emailIconButton: {
    "& svg": {
      width: "16px",
      height: "16px",
    },
    color: "primary.main",
    "&:hover": {
      color: "primary.dark",
    },
  },
};
