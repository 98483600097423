import { useCallback, useMemo, useState } from "react";
import { TasksWithTemplateRoles } from "../../types";
import { ElectronicDocumentTask } from "@_types/*";
import { getTaskRolesRequestPromise } from "./utils";
import { AdminTools } from "../../hooks/useAdminTools";

export const useGetRequiredTemplateRoles = (
  getTemplateRoles: AdminTools["getTemplateRoles"]
) => {
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);
  const [tasksWithRoles, setTasksWithRoles] = useState<
    TasksWithTemplateRoles[]
  >([]);

  const getRequiredRoles = useCallback(
    async (tasks: ElectronicDocumentTask[]) => {
      setIsLoadingRoles(true);

      const promises = tasks.map((task) =>
        getTaskRolesRequestPromise(task, getTemplateRoles)
      );

      const promisesResult = await Promise.all(promises);

      setTasksWithRoles(promisesResult);
      setIsLoadingRoles(false);
    },
    [getTemplateRoles]
  );

  return useMemo(() => {
    return {
      isLoadingRoles,
      tasksWithRoles,
      getRequiredRoles,
    };
  }, [getRequiredRoles, isLoadingRoles, tasksWithRoles]);
};
