import { palette } from "@pairtreefamily/ui";

export const styles = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  headingWrapper: {
    pt: 4,
    pb: 2,
  },
  description: {
    mt: "10px",
    display: "flex",
    gap: 4,
    justifyContent: "space-between",
  },
  toggleWrapper: {
    backgroundColor: palette.background.paper,
    border: `1px solid ${palette.divider}`,
    height: "64px",
    px: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "15px",
    gap: 2,
    minWidth: 230,
  },
  tabsBackground: `linear-gradient(180deg, rgba(247, 247, 247, 0.5) 0%, #FCFCFC 43.75%, rgba(246, 246, 246, 0.8) 91.67%, #F0F0F0 100%)`,
};
