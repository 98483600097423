import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "next-i18next";
import { Checkbox, Modal } from "@pairtreefamily/ui";
import { TaskAssignmentModal } from "./task-assignment-modal";
import { AdminTools } from "../hooks/useAdminTools";

import type {
  ExtendedTaskGroup,
  TaskAssignment,
  ElectronicDocumentTask,
  CaseLoginUser,
} from "@_types/*";
import type { TasksWithTemplateRoles } from "../types";

export type TaskToAssign = ElectronicDocumentTask & { checked: boolean };

export type AssignPersonToTasksModalProps = {
  isOpen: boolean;
  onClose: () => void;
  taskGroups: ExtendedTaskGroup[];
  caseParticipants: AdminTools["caseParticipants"];
  setTaskAssignment: AdminTools["setTaskAssignment"];
  caseId: string;
  getRequiredRoles: (task: ElectronicDocumentTask[]) => void;
  tasksRoles: TasksWithTemplateRoles[];
  isLoadingRoles: boolean;
  caseLoginUsers: CaseLoginUser[];
};

export function AssignPersonToTasksModal(props: AssignPersonToTasksModalProps) {
  const {
    caseParticipants,
    taskGroups,
    isOpen,
    setTaskAssignment,
    onClose,
    getRequiredRoles,
    tasksRoles,
    isLoadingRoles,
    caseLoginUsers,
  } = props;

  const { t } = useTranslation("admin");

  const [tasks, setTasks] = useState<Array<TaskToAssign>>([]);
  const [isTaskAssignmentModalOpen, setIsTaskAssignmentModalOpen] =
    useState(false);

  const cleartasks = () => {
    setTasks(
      tasks.map((item) => {
        return { ...item, checked: false };
      })
    );
  };

  const checkedAssignees: TaskAssignment[] = useMemo(() => {
    const value = tasks
      .filter((item) => item.checked)
      .map((item) => {
        return item.taskAssignments;
      });

    return value.flat();
  }, [tasks]);

  const filteredCheckedTasks = useMemo(() => {
    return tasks.filter((task) => task.checked);
  }, [tasks]);

  useEffect(() => {
    const electronicDocTasks = taskGroups
      .flatMap((item) => item.tasks)
      .filter(
        (item) => item.taskType === "electronicDocument"
      ) as ElectronicDocumentTask[];

    setTasks(
      electronicDocTasks.map((item) => {
        //TODO: Refactor. This approach clears the checked state when the taskGroups prop changes (on window refocus)
        return { ...item, checked: false };
      })
    );
  }, [taskGroups]);

  const providerTasks = useMemo(() => {
    return filteredCheckedTasks?.filter((item) => item.TaskGroup?.providerOnly);
  }, [filteredCheckedTasks]);

  const assignedToFamTask = filteredCheckedTasks?.some(
    (item) => !item.TaskGroup?.providerOnly
  );
  const assignedToProTask = filteredCheckedTasks?.some(
    (item) => item.TaskGroup?.providerOnly
  );

  return (
    <>
      <Modal
        title={t("assignPersonToTasksModal.title")}
        open={isOpen}
        onClose={() => {
          onClose();
          cleartasks();
        }}
        submitButton={{
          label: "Assign",
          disabled: filteredCheckedTasks.length === 0,
          onClick: () => {
            if (assignedToFamTask && assignedToProTask) {
              return t("assignPersonToTasksModal.errors.sameAssignedPerson");
            } else {
              setIsTaskAssignmentModalOpen(true);
              getRequiredRoles(filteredCheckedTasks);
              onClose();
            }
          },
        }}
      >
        <>
          {tasks.map((item) => {
            return (
              <div key={`checkbox-${item.id}`}>
                <Checkbox
                  label={item.title}
                  checked={item.checked}
                  onChange={(checked) => {
                    setTasks(
                      tasks.map((item2) => {
                        if (item2.id === item.id) {
                          item2.checked = checked;
                        }
                        return {
                          ...item2,
                        };
                      })
                    );
                  }}
                />
              </div>
            );
          })}
        </>
      </Modal>
      <TaskAssignmentModal
        parents={caseParticipants?.ok ? caseParticipants.content.parents : []}
        currentAssignees={checkedAssignees}
        setTaskAssignment={async (args) => {
          let res;
          for (const task of filteredCheckedTasks) {
            res = await setTaskAssignment({
              caseId: props.caseId,
              taskId: task.id,
              person: args.person,
              role: args.role,
            });
          }
          onClose();
          cleartasks();
          return res?.ok ?? false;
        }}
        isOpen={isTaskAssignmentModalOpen}
        onClose={() => {
          setIsTaskAssignmentModalOpen(false);
          cleartasks();
        }}
        tasksRoles={tasksRoles}
        isLoadingRoles={isLoadingRoles}
        caseLoginUsers={caseLoginUsers}
        tasks={providerTasks}
      />
    </>
  );
}
