import { z } from "zod";
import { dateValidationSchema } from "@pairtreefamily/ui";

export const validationSchema = z.object({
  approvalDate: dateValidationSchema,
  expirationDate: dateValidationSchema,
  confirm: z.boolean().refine(
    (value) => {
      return !!value;
    },
    {
      params: {
        i18n: "custom.hasToBeConfirmed",
      },
    }
  ),
});

export type FormValues = z.infer<typeof validationSchema>;
