import { useState } from "react";
import { useTranslation } from "next-i18next";

import { Fade, Tab, Tabs } from "@mui/material";

import { TableWrapper } from "../table-wrapper";
import { getTableRows } from "./utils";
import { styles } from "./styles";

import type { AdoptiveParent } from "@_types/*";

export type ParentsListProps = {
  list: AdoptiveParent[];
};

export const ParentList = (props: ParentsListProps) => {
  const { list } = props;
  const { t } = useTranslation("connect");

  const [currentTab, setCurrentTab] = useState(list[0].uuid);

  return (
    <>
      {list.length > 1 && (
        <Tabs
          value={currentTab}
          onChange={(_, value) => setCurrentTab(value)}
          sx={styles.tabs}
        >
          {list.map((item) => (
            <Tab key={item.uuid} value={item.uuid} label={item.firstName} />
          ))}
        </Tabs>
      )}

      {list.map((item) => (
        <Fade
          key={item.uuid}
          exit={false}
          in={currentTab === item.uuid}
          unmountOnExit
        >
          <TableWrapper rows={getTableRows(item, t)} />
        </Fade>
      ))}
    </>
  );
};
