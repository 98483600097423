import { useMemo } from "react";
import { type ThreadListingRow, ThreadsTableField } from "../types";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "next-i18next";
import { Box, Typography } from "@mui/material";
import { Avatar } from "../../avatar/Avatar";
import { formatDateToView } from "@shared/utils";

const columnHelper = createColumnHelper<ThreadListingRow>();

export const useColumns = () => {
  const { t } = useTranslation("safetyCenter");

  return useMemo(
    () => [
      columnHelper.accessor(ThreadsTableField.Title, {
        header: t("threadsTable.headers.threadName"),
        enableSorting: false,
        cell: (cell) => {
          const avatar =
            cell.row.original[ThreadsTableField.AuthorAvatar] || "";

          const firstName =
            cell.row.original[ThreadsTableField.AuthorFirstName];

          const lastName = cell.row.original[ThreadsTableField.AuthorLastName];

          return (
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Avatar firstName={firstName} lastName={lastName} url={avatar} />

              <Typography variant="body4">{cell.getValue()}</Typography>
            </Box>
          );
        },
      }),
      columnHelper.accessor(ThreadsTableField.AuthorFullName, {
        header: t("threadsTable.headers.author"),
        enableSorting: false,
        cell: (cell) => (
          <>
            <Typography variant="body8">{cell.getValue()}</Typography>
            <Typography variant="body6" color="text.light">
              , {cell.row.original[ThreadsTableField.AuthorOrganization]}
            </Typography>
          </>
        ),
      }),
      columnHelper.accessor(ThreadsTableField.Comments, {
        header: t("threadsTable.headers.comments"),
        enableSorting: false,
        cell: (cell) => {
          const commentsCount = cell.getValue();

          if (!commentsCount) {
            return null;
          }

          return (
            <Typography component="div" variant="body6">
              +{commentsCount}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(ThreadsTableField.LastUpdated, {
        header: t("threadsTable.headers.lastUpdated"),
        enableSorting: false,
        cell: (cell) => (
          <Typography component="div" variant="body6">
            {formatDateToView(new Date(cell.getValue()))}
          </Typography>
        ),
      }),
    ],
    [t]
  );
};
