import {
  flexRender,
  getCoreRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  GroupingState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useColumns } from "./useColumns";
import { Fragment, useState } from "react";
import { TableHeader } from "@shared/components";
import type { ExpiringTask } from "@api";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

type ExpiringTasksTableProps = {
  expiringTasks: ExpiringTask[];
};

export const ExpiringTasksTable = ({
  expiringTasks,
}: ExpiringTasksTableProps) => {
  const columns = useColumns();

  const [grouping, setGrouping] = useState<GroupingState>(["caseId"]);

  const [sorting, setSorting] = useState<SortingState>([
    { desc: false, id: "expiration" },
  ]);

  const table = useReactTable({
    data: expiringTasks,
    columns,
    state: {
      grouping,
      sorting,
    },
    onGroupingChange: setGrouping,
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    enableSortingRemoval: false,
  });

  return (
    <Table sx={{ mb: 2 }}>
      <TableHeader<ExpiringTask> table={table} />

      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <Fragment key={row.id}>
            <TableRow>
              <TableCell
                sx={{ verticalAlign: "top", pt: 4 }}
                rowSpan={row.subRows.length + 1}
                key={row.getVisibleCells()[0].id}
              >
                {flexRender(
                  row.getVisibleCells()[0].column.columnDef.cell,
                  row.getVisibleCells()[0].getContext()
                )}
              </TableCell>
            </TableRow>

            {row.subRows.map((subRow) => {
              return (
                <TableRow key={subRow.id}>
                  {subRow.getVisibleCells().map((cell, cellIndex) => {
                    if (cellIndex === 0) {
                      return null;
                    }

                    return (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </Fragment>
        ))}
      </TableBody>
    </Table>
  );
};
