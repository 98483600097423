import type { SortingState, PaginationState } from "@tanstack/react-table";
import { FamilyListingField, FamilyFiltersFields } from "./types";

export const initialSorting: SortingState = [
  {
    id: FamilyListingField.FamName,
    desc: false,
  },
];

export const initialPagination: PaginationState = {
  pageIndex: 0,
  pageSize: 20,
};

export const initialFilters: FamilyFiltersFields = {
  archetype: [],
  education: [],
  familyStructure: [],
  raceEthnicity: [],
  religion: [],
  politicalAffiliation: [],
  state: [],
  openTo: [],
  adoptionRelationshipPreference: [],
  approvedHomeStudy: undefined,
  hasProfileBook: undefined,
};
