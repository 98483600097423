import { Box, Typography } from "@mui/material";
import { CaseDocumentsIcon } from "@pairtreefamily/ui";
import { TaskGroupRow, TaskGroupView } from "@shared/components";
import React, { memo } from "react";
import { useTranslation } from "next-i18next";
import { ExtendedTaskGroup } from "@_types/*";
import { UseProfessionalPageTools } from "@features";

type MyTasksProps = {
  providerTaskGroups: ExtendedTaskGroup[];
  createReferenceTask: UseProfessionalPageTools["createReferenceTask"];
  taskRow: TaskGroupRow;
  taskGroupsError: UseProfessionalPageTools["taskGroupsError"];
};

export const MyTasks = memo(
  ({
    providerTaskGroups,
    createReferenceTask,
    taskRow,
    taskGroupsError,
  }: MyTasksProps) => {
    const { t } = useTranslation("professionalCase");

    return (
      <>
        <Box display="flex" gap={1} mb={4}>
          <CaseDocumentsIcon />

          <Typography component="h2" variant="head2">
            {t("checklistCaption")}
          </Typography>
        </Box>

        {taskGroupsError?.message && (
          <Typography variant="caption2" color="error">
            {taskGroupsError?.data?.code === "NOT_FOUND"
              ? t("errors.noTasks")
              : taskGroupsError?.message}
          </Typography>
        )}

        {providerTaskGroups && (
          <TaskGroupView
            createReferenceTask={(taskGroupId) =>
              createReferenceTask({ taskGroupId })
            }
            taskGroups={providerTaskGroups}
            row={taskRow}
          />
        )}
      </>
    );
  }
);

MyTasks.displayName = "MyTasks";
