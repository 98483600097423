import { Box, Typography } from "@mui/material";
import { ThreadDetailsResponse } from "@api";
import { AuthorPostDetails } from "../author-post-details";
import { TruncableText } from "@shared/components";

type DetailsProps = {
  data: ThreadDetailsResponse;
};

export const Details = ({ data }: DetailsProps) => {
  return (
    <Box sx={{ flex: "0 0 auto" }}>
      <Typography variant="head5">{data.title}</Typography>

      <Box mt={1}>
        {data.description && (
          <Typography component="div" variant="body6" whiteSpace="pre-line">
            <TruncableText expanded={true} deps={data.description}>
              {data.description}
            </TruncableText>
          </Typography>
        )}
      </Box>

      <Box mt={4}>
        <AuthorPostDetails
          avatar={data.author.avatar}
          emailAddress={data.author.emailAddress}
          firstName={data.author.firstName}
          lastName={data.author.lastName}
          lastUpdated={data.updatedAt}
          orgName={data.orgName}
        />
      </Box>
    </Box>
  );
};
