import { Routes } from "@features/routing";
import { Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export function LegalSection() {
  const { t } = useTranslation("professional");

  return (
    <>
      <Typography
        my="24px"
        variant="tooltip"
        component="p"
        color="textSecondary"
        textAlign="center"
      >
        {t("authorization.createAccount.legal1")}
        <Link
          underline="none"
          target="_blank"
          href="https://pairtreefamily.com/terms-and-conditions"
        >
          {" "}
          {t("authorization.createAccount.terms")}{" "}
        </Link>
        {t("authorization.createAccount.legal2")}
        <Link
          underline="none"
          target="_blank"
          href="https://pairtreefamily.com/privacy-policy"
        >
          {" "}
          {t("authorization.createAccount.privacy")}
        </Link>
        .
      </Typography>
      <Typography
        textAlign="center"
        variant="body3"
        component="p"
        color="textSecondary"
      >
        {t("authorization.createAccount.alreadyHaveAccount")}{" "}
        <Link underline="none" href={Routes.ProAccountSignIn}>
          {" "}
          {t("authorization.createAccount.signIn")}
        </Link>
      </Typography>
    </>
  );
}
