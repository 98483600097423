import { Trans, useTranslation } from "next-i18next";
import { ReplacedTask } from "../types";

type ReplacedTasksListProps = {
  replacedTasks: ReplacedTask[];
};

export const ReplacedTasksList = ({
  replacedTasks,
}: ReplacedTasksListProps) => {
  const { t } = useTranslation("admin");

  return (
    <div className="text-left">
      <h4 className="text-body-3 pt-4 pb-2">
        <Trans
          t={t}
          i18nKey={"replaceTasksTitle.messages.success"}
          components={[<span className="text-green" key="0" />]}
        />
      </h4>
      <ul>
        {replacedTasks.map((item) => (
          <li key={`replaced-${item.id}`} className="text-body-4 py-1">
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};
