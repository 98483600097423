import { Box, Typography } from "@mui/material";
import { styles } from "./styles";
import { getFullName } from "@pairtreefamily/utils";
import { getInitials } from "./utils";

type AvatarProps = {
  firstName: string;
  lastName: string;
  url: string;
};

export const Avatar = ({ firstName, lastName, url }: AvatarProps) => {
  const fullName = getFullName(firstName, lastName);
  const initials = getInitials(firstName, lastName);

  return (
    <Box sx={styles.wrapper(fullName)}>
      {url && <img alt={initials} src={url} />}

      {!url && (
        <Typography variant="body4">{initials.toUpperCase()}</Typography>
      )}
    </Box>
  );
};
