import { firstParent } from "./constants";

import type { Person, Task, CaseLoginUser } from "@_types/*";

export const transformPersonName = (person: Person | CaseLoginUser): string => {
  return `${person.firstName} ${person.lastName} (${person.email})`;
};

type GetPersonOptionsArgs = {
  caseLoginUsers: CaseLoginUser[];
  providerTasks: Task[];
  parents: Person[];
  id: string;
};

export const getPersonById = ({
  providerTasks,
  caseLoginUsers,
  parents,
  id,
}: GetPersonOptionsArgs) => {
  if (providerTasks.length > 0) {
    return caseLoginUsers.find((user) => user.id === id);
  }

  if (id === firstParent) {
    return parents[0];
  }

  return parents[1];
};
