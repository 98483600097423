import type { ExpiringTask } from "@api";
import { ExpiringTasksTable } from "../expiring-tasks-table";
import { Loading } from "@pairtreefamily/ui";
import { useTranslation } from "next-i18next";

type ExpiringTasksTabProps = {
  isExpiringTasksLoading: boolean;
  expiringTasks: ExpiringTask[];
};

export const ExpiringTasksTab = ({
  isExpiringTasksLoading,
  expiringTasks,
}: ExpiringTasksTabProps) => {
  const { t } = useTranslation();

  if (isExpiringTasksLoading) {
    return <Loading />;
  }

  if (!expiringTasks.length) {
    return <p>{t("root.messages.noRecords")}</p>;
  }

  return <ExpiringTasksTable expiringTasks={expiringTasks} />;
};
