import { Box, Tabs, Tab, Collapse, Typography, Fade } from "@mui/material";
import { useTranslation } from "next-i18next";
import { NewComment } from "./components";
import { styles } from "./styles";
import { ThreadDetailsResponse } from "@api";
import { CommentView } from "./components/comment-view/CommentView";
import { TransitionGroup } from "react-transition-group";
import { memo, useCallback, useMemo, useRef } from "react";

type CommentsProps = {
  threadId: string;
  orgName: string;
} & Pick<ThreadDetailsResponse, "comments">;

export const Comments = memo(
  ({ threadId, comments, orgName }: CommentsProps) => {
    const { t } = useTranslation("safetyCenter");
    const scrollingContainerRef = useRef<HTMLDivElement>(null);

    const handleCommentAdded = useCallback(() => {
      scrollingContainerRef?.current?.scrollTo(0, 0);
    }, []);

    const sortedComments = useMemo(() => {
      return [...comments].sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
    }, [comments]);

    return (
      <Box mt={3} height="100%" sx={styles.wrapper}>
        <Box>
          <Box px={3}>
            <Tabs value={0}>
              <Tab label={t("threadDetails.comments.title")} id="tab-0" />
            </Tabs>
          </Box>

          <Box sx={styles.divider}></Box>

          <Box px={3} mt={3} mb={2.25}>
            <NewComment
              onCommentAdded={handleCommentAdded}
              threadId={threadId}
            />
          </Box>

          <Box sx={styles.divider}></Box>
        </Box>

        <Box ref={scrollingContainerRef} sx={styles.commentsListWrapper}>
          <Fade in={Boolean(sortedComments.length)}>
            <Box>
              <TransitionGroup>
                {sortedComments.map(
                  ({ uuid, description, author, createdAt }, index) => {
                    return (
                      <Collapse key={uuid}>
                        <Box
                          sx={styles.commentsWrapper(
                            index,
                            sortedComments.length
                          )}
                        >
                          <CommentView
                            key={uuid}
                            text={description}
                            {...author}
                            orgName={orgName}
                            lastUpdated={createdAt}
                          />
                        </Box>
                      </Collapse>
                    );
                  }
                )}
              </TransitionGroup>
            </Box>
          </Fade>

          {!Boolean(comments.length) && (
            <Typography
              mt={4}
              variant="body6"
              textAlign="center"
              color="text.light50"
            >
              {t("threadDetails.comments.noComments")}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }
);

Comments.displayName = "Comments";
