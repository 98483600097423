import { useEffect } from "react";
import { TasksWithTemplateRoles } from "../../../types";
import { TaskAssignment } from "@_types/*";
import { RolesOption } from "../types";

type UseSetRolesOptionsArgs = {
  tasksRoles: TasksWithTemplateRoles[];
  currentAssignees?: TaskAssignment[];
  setRolesOptions: (args: RolesOption[] | null) => void;
  setRoleOption: (args: string) => void;
};

export const useSetRolesOptions = ({
  tasksRoles,
  currentAssignees,
  setRolesOptions,
  setRoleOption,
}: UseSetRolesOptionsArgs) => {
  useEffect(() => {
    const getPandadocRolesOptions = () => {
      const allRoles = tasksRoles.flatMap((item) => item.templateRoles);
      const allUniqueRoles = new Set<string>();
      if (!allRoles?.length) return null;
      const allRequired = allRoles?.map((item) => {
        const alreadyExist = currentAssignees?.find(
          (item2) => item.name === item2.pandadocRole
        );
        const value = {
          id: item.name,
          label: item.name,
          alreadyExist:
            alreadyExist !== undefined || allUniqueRoles.has(item.name),
        };
        if (!allUniqueRoles.has(item.name)) {
          allUniqueRoles.add(item.name);
        }
        return value;
      });
      return allRequired?.filter((item) => !item.alreadyExist);
    };
    const roles = getPandadocRolesOptions();

    setRolesOptions(roles);

    if (roles?.length) {
      setRoleOption(roles[0].id);
    }
  }, [tasksRoles, currentAssignees, setRolesOptions, setRoleOption]);
};
