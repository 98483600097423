import {
  Button,
  Loading,
  PhoneNumberField,
  SidePanel,
  TextAreaField,
  TextField,
  useSnackbarNotification,
} from "@pairtreefamily/ui";
import { useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { ContactFamilyFormData, validationSchema } from "./validation-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { getDefaultValues } from "./utils";
import {
  useGetFamilyProfileDetails,
  useGetOrganizationDataForFamilyRequest,
  useRequestFamilyInformation,
} from "@api";
import { useGetFullFamilyName } from "../../hooks";
import { ProAssociatedWarning } from "./components/ProAssociatedWarning";
import { Box, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useOrganizationsData } from "@shared/components";

type ContactFamilySidePanelProps = {
  isOpen: boolean;
  onClose: () => void;
  slug?: string;
  uuid: string;
};

export const ContactFamilySidePanel = ({
  isOpen,
  onClose,
  slug,
  uuid,
}: ContactFamilySidePanelProps) => {
  const { t } = useTranslation("connect");
  const { userOrganizationData } = useOrganizationsData();
  const { data: familyData } = useGetFamilyProfileDetails({
    slug: slug ?? "",
    organizationUuid: userOrganizationData?.id ?? "",
  });
  const requestFamilyInformation = useRequestFamilyInformation();
  const { callSnackbar } = useSnackbarNotification();
  const formMethods = useForm<ContactFamilyFormData>({
    defaultValues: getDefaultValues(),
    resolver: zodResolver(validationSchema),
  });

  const { data: organizationData, isLoading: isOrganizationDataLoading } =
    useGetOrganizationDataForFamilyRequest();

  const familyFullName = useGetFullFamilyName(familyData);

  useEffect(() => {
    formMethods.reset(
      getDefaultValues({
        organizationPhone: organizationData?.phoneNumber,
        organizationEmail: organizationData?.senderAddress,
        familyName: familyFullName,
      })
    );
  }, [organizationData, familyFullName, formMethods]);

  const handleSubmit = useCallback(
    async (values: ContactFamilyFormData) => {
      try {
        await requestFamilyInformation({
          ...values,
          accountUuid: uuid,
          subject: values.subject || "",
        });

        callSnackbar({
          message: t("familyListing.contactFamily.successContactingFamily"),
        });

        onClose();
      } catch (e) {
        callSnackbar({
          message: t("familyListing.contactFamily.errorContactingFamily"),
          type: "error",
        });
      }
    },
    [callSnackbar, onClose, requestFamilyInformation, t, uuid]
  );

  const handleClose = useCallback(() => {
    formMethods.reset(getDefaultValues());
    onClose();
  }, [formMethods, onClose]);

  const isFamilyAssociatedWithPairTreePro =
    Boolean(familyData?.partnerProfessionals.length) &&
    Boolean(familyData?.orgMemberships.length);

  return (
    <SidePanel<ContactFamilyFormData>
      title={t("familyListing.contactFamily.title")}
      onClose={handleClose}
      formMethods={formMethods}
      onSubmit={handleSubmit}
      open={isOpen}
      actions={
        <>
          <Button
            onClick={onClose}
            type="submit"
            variant="outlined"
            color="primary"
          >
            {t("familyListing.contactFamily.cancel")}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            isLoading={formMethods.formState.isSubmitting}
            disabled={formMethods.formState.isSubmitting}
          >
            {t("familyListing.contactFamily.send")}
          </Button>
        </>
      }
    >
      {isOrganizationDataLoading && <Loading />}

      {!isOrganizationDataLoading && (
        <>
          {isFamilyAssociatedWithPairTreePro && <ProAssociatedWarning />}

          <TextField
            label={t("familyListing.contactFamily.replyTo")}
            required
            name="senderAddress"
          />

          <PhoneNumberField
            required
            name="phoneNumber"
            label={t("familyListing.contactFamily.phoneNumber")}
            mask="+1 000-000-0000"
            placeholder="+1 XXX-XXX-XXXX"
            unmask
          />

          <Box>
            <TextAreaField
              required
              label={t("familyListing.contactFamily.message")}
              name="message"
              rows={8}
              maxLength={2000}
            />

            <Box
              sx={{
                mt: "5px",
                display: "flex",
                gap: 1,
              }}
            >
              <InfoOutlinedIcon sx={{ fontSize: 12, marginTop: "2px" }} />

              <Typography variant="caption3">
                {t("familyListing.contactFamily.messageInfo")}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </SidePanel>
  );
};
