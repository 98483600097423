import { Loading, SidePanel, SidePanelProps } from "@pairtreefamily/ui";
import { useGetThreadDetails } from "@api";

import { Comments, Details } from "./components";
import { Box } from "@mui/material";
import { memo } from "react";
import { styles } from "./styles";

type ThreadDetailsProps = {
  threadId: string;
} & Pick<SidePanelProps<never>, "onClose" | "open">;

export const ThreadDetails = memo(
  ({ onClose, open, threadId }: ThreadDetailsProps) => {
    const { isLoading, data } = useGetThreadDetails(threadId);

    return (
      <SidePanel
        onClose={onClose}
        open={open}
        sxContentWrapper={styles.sidePanelContent}
        sxHeaderWrapper={styles.sidePanelHeader}
      >
        {!data && isLoading && <Loading />}

        {data && (
          <Box sx={styles.contentWrapper}>
            <Box px={3}>
              <Details data={data} />
            </Box>

            <Comments
              threadId={threadId}
              comments={data?.comments}
              orgName={data.orgName}
            />
          </Box>
        )}
      </SidePanel>
    );
  }
);

ThreadDetails.displayName = "ThreadDetails";
