import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import { Education } from "@_types/*";

export const useEducationsOptions = () => {
  const { t } = useTranslation("connect");

  return useMemo(
    () => [
      {
        id: Education.SomeCollege,
        label: t("familyListing.filters.fields.education.options.someCollege"),
      },
      {
        id: Education.MastersDegree,
        label: t(
          "familyListing.filters.fields.education.options.mastersDegree"
        ),
      },
      {
        id: Education.PhdOrHigher,
        label: t("familyListing.filters.fields.education.options.phdOrHigher"),
      },
      {
        id: Education.BachelorsDegree,
        label: t(
          "familyListing.filters.fields.education.options.bachelorsDegree"
        ),
      },
      {
        id: Education.SomeHighSchool,
        label: t(
          "familyListing.filters.fields.education.options.someHighSchool"
        ),
      },
      {
        id: Education.HighSchool,
        label: t("familyListing.filters.fields.education.options.highSchool"),
      },
      {
        id: Education.TradeSchool,
        label: t("familyListing.filters.fields.education.options.tradeSchool"),
      },
      {
        id: Education.PreferNotToSay,
        label: t(
          "familyListing.filters.fields.education.options.preferNotToSay"
        ),
      },
    ],
    [t]
  );
};
