import { useTranslation } from "next-i18next";

import {
  FamilyDocumentsCard,
  FamilyInfoCard,
  HomeStudyStatusCard,
  CaseNotesCard,
} from "./components";
import { Box, Grid, Typography } from "@mui/material";
import { memo, useMemo, useState } from "react";
import { Modal } from "@pairtreefamily/ui";
import { Case, Person } from "@_types/*";
import { Help, HelpFilled, palette, Tooltip } from "@pairtreefamily/ui";
import { ProgressCard } from "./components/ProgressCard";

export type ProfessionalHeaderProps = {
  selectedCase?: Case;
  caseProviders?: Person[];
  percentComplete: number;
  famDocumentsLinks: string | null;
  providerDocumentsLink: string | null;
  onUpdateCaseNote: (note: string) => void;
  onComplete: () => void;
  onStatusChange: (value: boolean) => Promise<void>;
  changeStatusLoading: boolean;
};

export const Header = memo((props: ProfessionalHeaderProps) => {
  const {
    selectedCase,
    caseProviders,
    percentComplete,
    famDocumentsLinks,
    providerDocumentsLink,
    onUpdateCaseNote,
    onComplete,
    onStatusChange,
    changeStatusLoading,
  } = props;

  const { t } = useTranslation("professionalCase");

  const submitButton = useMemo(() => {
    return {
      label: t("header.confirmSwitch"),
      onClick: async () => {
        setOpenFamilyStatusModal(false);
        onStatusChange(!selectedCase?.active);
      },
    };
  }, [onStatusChange, selectedCase?.active, t]);

  const [openFamilyStatusModal, setOpenFamilyStatusModal] = useState(false);
  const [isHelpIconHovered, setIsHelpIconHovered] = useState(false);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <ProgressCard percentComplete={percentComplete} />
            </Grid>
            <Grid item xs={6}>
              <HomeStudyStatusCard
                approvedAt={selectedCase?.approvedAt ?? null}
                expiresAt={selectedCase?.expiresAt ?? null}
                onComplete={onComplete}
              />
            </Grid>
            <Grid item xs={6}>
              <FamilyDocumentsCard
                percentComplete={percentComplete}
                urlCompletedDocuments={famDocumentsLinks ?? ""}
                urlPrivateDocuments={providerDocumentsLink ?? ""}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} item>
          <Grid container columnSpacing={3} height="100%">
            <Grid item xs={6}>
              <FamilyInfoCard
                state={selectedCase?.state ?? ""}
                providerFirstLastName={`${
                  caseProviders?.length ? caseProviders[0]?.firstName : ""
                } ${caseProviders?.length ? caseProviders[0]?.lastName : ""}`}
                familyContactEmail={selectedCase?.familyEmail ?? ""}
                selectedCase={selectedCase}
                setOpenFamilyStatusModal={setOpenFamilyStatusModal}
                changeStatusLoading={changeStatusLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <Box height="100%">
                <Typography
                  mb={1.5}
                  variant="body2"
                  component="p"
                  display="flex"
                >
                  <strong style={{ marginRight: "10px" }}>
                    {t("header.caseNotesCaption")}
                  </strong>
                  <Tooltip title={t("header.caseNotesTooltip")}>
                    <Box
                      sx={{ cursor: "pointer", margin: "auto 0" }}
                      color={palette.primary.main}
                      onMouseEnter={() => {
                        setIsHelpIconHovered(true);
                      }}
                      onMouseLeave={() => {
                        setIsHelpIconHovered(false);
                      }}
                    >
                      {isHelpIconHovered ? (
                        <HelpFilled size={16} />
                      ) : (
                        <Help size={16} />
                      )}
                    </Box>
                  </Tooltip>
                </Typography>
                <CaseNotesCard
                  initialValue={selectedCase?.quickNote}
                  onUpdate={onUpdateCaseNote}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        title={t("header.switchModalTitle", {
          status: selectedCase?.active
            ? t("header.inactiveSwitch")
            : t("header.activeSwitch"),
        })}
        open={openFamilyStatusModal}
        submitButton={submitButton}
        onClose={() => setOpenFamilyStatusModal(false)}
      >
        {!selectedCase?.active ? (
          <Box component="p">
            {t("header.activeSwitchModalBody", {
              status: selectedCase?.active
                ? t("header.inactiveSwitch")
                : t("header.activeSwitch"),
              statusOposite: selectedCase?.active
                ? t("header.activeSwitch")
                : t("header.inactiveSwitch"),
            })}
          </Box>
        ) : (
          <Box component="p">
            {t("header.inactiveSwitchModalBody", {
              status: t("header.inactiveSwitch"),
            })}
          </Box>
        )}
      </Modal>
    </>
  );
});

Header.displayName = "Header";
