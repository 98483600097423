export function Logo() {
  return (
    <svg
      width="228"
      height="72"
      viewBox="0 0 228 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.2933 36.3873C43.5044 31.7323 46.638 27.3216 51.2898 26.5327C55.9448 25.7438 60.3556 28.8774 61.1445 33.5292C61.9333 38.1842 58.7998 42.595 54.1479 43.3838C49.4961 44.1727 45.0822 41.0391 44.2933 36.3873Z"
        fill="white"
      />
      <path
        d="M17.5836 8.19206C16.9356 4.36979 19.5089 0.747877 23.328 0.0998761C27.1471 -0.548124 30.7722 2.0251 31.4202 5.84423C32.0682 9.66336 29.495 13.2884 25.6758 13.9364C21.8567 14.5844 18.2316 12.0112 17.5836 8.19206Z"
        fill="white"
      />
      <path
        d="M1.86858 40.9173C1.32076 37.6898 3.49328 34.6314 6.72076 34.0835C9.94824 33.5357 13.0067 35.7082 13.5545 38.9357C14.1023 42.1632 11.9298 45.2216 8.70233 45.7695C5.47485 46.3173 2.41641 44.1448 1.86858 40.9173Z"
        fill="white"
      />
      <path
        d="M48.3213 20.1375C47.8392 17.2919 49.755 14.5935 52.6006 14.1114C55.4461 13.6293 58.1446 15.5451 58.6267 18.3907C59.1088 21.2362 57.1929 23.9347 54.3474 24.4168C51.5018 24.8989 48.8034 22.983 48.3213 20.1375Z"
        fill="white"
      />
      <path
        d="M35.9284 24.5517C35.5089 22.0849 37.1712 19.7465 39.638 19.327C42.1048 18.9075 44.4432 20.5698 44.8627 23.0365C45.2822 25.5033 43.6199 27.8418 41.1531 28.2612C38.6863 28.6807 36.3479 27.0185 35.9284 24.5517Z"
        fill="white"
      />
      <path
        d="M22.9401 21.0736C22.5206 18.5974 24.2643 16.2402 26.8344 15.8019C29.4044 15.3668 31.8274 17.0197 32.2469 19.4959C32.6664 21.972 30.9227 24.3292 28.3526 24.7644C25.7825 25.1995 23.3596 23.5466 22.9401 21.0736Z"
        fill="white"
      />
      <path
        d="M9.52662 52.25C9.13532 49.9397 10.6911 47.7516 12.9983 47.3571C15.3085 46.9658 17.4967 48.5216 17.888 50.8319C18.2793 53.1422 16.7235 55.3303 14.4164 55.7248C12.1061 56.1161 9.91793 54.5603 9.52349 52.2531"
        fill="white"
      />
      <path
        d="M6.57391 7.69378C6.20139 5.49621 7.68209 3.41134 9.87965 3.03882C12.0772 2.6663 14.1621 4.14699 14.5346 6.34456C14.9071 8.54213 13.4264 10.627 11.2289 10.9995C9.03131 11.372 6.94643 9.89135 6.57391 7.69378Z"
        fill="white"
      />
      <path
        d="M42.0042 49.6304C41.6192 47.3609 43.1468 45.2071 45.4195 44.8221C47.6891 44.437 49.8428 45.9647 50.2279 48.2374C50.6129 50.507 49.0853 52.6607 46.8126 53.0457C44.543 53.4308 42.3893 51.9031 42.0042 49.6304Z"
        fill="white"
      />
      <path
        d="M33.624 9.92052C32.8382 5.29373 35.953 0.90799 40.5798 0.12225C45.2066 -0.66349 49.5923 2.4513 50.3781 7.07808C51.1638 11.7049 48.049 16.0906 43.4222 16.8764C38.7954 17.6621 34.4097 14.5473 33.624 9.92052Z"
        fill="white"
      />
      <path
        d="M16.5444 42.1168C15.3361 34.9919 20.1319 28.2365 27.2599 27.0281C34.3848 25.8198 41.1403 30.6156 42.3486 37.7436C43.557 44.8685 38.7611 51.624 31.6331 52.8323C24.5082 54.0407 17.7528 49.2448 16.5444 42.1168Z"
        fill="white"
      />
      <path
        d="M0.964266 24.0415C0.100265 18.9452 3.39662 14.1368 8.32706 13.301C13.2575 12.4652 17.9563 15.918 18.8203 21.0144C19.6843 26.1108 16.3879 30.9191 11.4575 31.7549C6.52705 32.5908 1.82827 29.1379 0.964266 24.0415Z"
        fill="white"
      />
      <path
        d="M32.293 72.0001H26.5956L27.566 56.4575H31.3257L32.293 72.0001Z"
        fill="white"
      />
      <path
        d="M83.91 41.9422H79.2451V50.3166H73.1014V22.3989H84.5084C91.169 22.3989 95.198 26.3464 95.198 32.0515V32.1298C95.198 38.59 90.1728 41.9422 83.91 41.9422ZM88.976 32.1706C88.976 29.4198 87.0618 27.9442 83.9915 27.9442H79.2451V36.4784H84.1105C87.1808 36.4784 88.976 34.6425 88.976 32.2489V32.1706Z"
        fill="white"
      />
      <path
        d="M110.036 50.317V48.0049C108.56 49.6403 106.527 50.718 103.576 50.718C99.5468 50.718 96.2353 48.4059 96.2353 44.1764V44.0981C96.2353 39.4332 99.7849 37.2777 104.851 37.2777C107.006 37.2777 108.56 37.638 110.077 38.1549V37.7946C110.077 35.282 108.52 33.8847 105.49 33.8847C103.178 33.8847 101.542 34.3233 99.5875 35.0408L98.0712 30.4134C100.424 29.3764 102.736 28.6997 106.367 28.6997C112.987 28.6997 115.901 32.1303 115.901 37.9137V50.317H110.039H110.036ZM110.155 41.7014C109.118 41.2221 107.761 40.9025 106.286 40.9025C103.692 40.9025 102.097 41.9395 102.097 43.8537V43.9321C102.097 45.5675 103.454 46.5261 105.409 46.5261C108.241 46.5261 110.155 44.9722 110.155 42.776V41.6983V41.7014Z"
        fill="white"
      />
      <path
        d="M118.848 26.5882V21.2026H125.23V26.5882H118.848ZM119.008 50.3202V28.941H125.07V50.3202H119.008Z"
        fill="white"
      />
      <path
        d="M142.541 34.8841C138.512 34.8841 136.04 37.3183 136.04 42.4219V50.3201H129.978V28.9409H136.04V33.2487C137.277 30.2974 139.27 28.3832 142.86 28.543V34.8841H142.541Z"
        fill="white"
      />
      <path
        d="M159.928 28.0633V50.3197H153.784V28.0633H145.287V22.3989H168.421V28.0633H159.925H159.928Z"
        fill="white"
      />
      <path
        d="M182.66 34.8841C178.631 34.8841 176.159 37.3183 176.159 42.4219V50.3201H170.097V28.9409H176.159V33.2487C177.396 30.2974 179.389 28.3832 182.979 28.543V34.8841H182.66Z"
        fill="white"
      />
      <path
        d="M204.237 41.7425H189.358C189.957 44.4933 191.871 45.9313 194.584 45.9313C196.617 45.9313 198.093 45.2922 199.769 43.7382L203.24 46.8085C201.245 49.2804 198.375 50.7967 194.506 50.7967C188.083 50.7967 183.337 46.2884 183.337 39.75V39.6717C183.337 33.5687 187.685 28.5435 193.907 28.5435C201.047 28.5435 204.318 34.0888 204.318 40.151V40.2293C204.318 40.8277 204.277 41.1849 204.24 41.7457L204.237 41.7425ZM193.907 33.4058C191.395 33.4058 189.759 35.201 189.28 37.9517H198.413C198.052 35.2386 196.458 33.4058 193.904 33.4058H193.907Z"
        fill="white"
      />
      <path
        d="M227.092 41.7425H212.213C212.812 44.4933 214.726 45.9313 217.439 45.9313C219.472 45.9313 220.948 45.2922 222.624 43.7382L226.096 46.8085C224.1 49.2804 221.23 50.7967 217.361 50.7967C210.938 50.7967 206.192 46.2884 206.192 39.75V39.6717C206.192 33.5687 210.54 28.5435 216.762 28.5435C223.902 28.5435 227.173 34.0888 227.173 40.151V40.2293C227.173 40.8277 227.133 41.1849 227.095 41.7457L227.092 41.7425ZM216.762 33.4058C214.25 33.4058 212.614 35.201 212.135 37.9517H221.268C220.907 35.2386 219.313 33.4058 216.759 33.4058H216.762Z"
        fill="white"
      />
    </svg>
  );
}
