import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import {
  DefaultTableRowRenderer,
  SecondaryItemTableRowRenderer,
  TableRowData,
  TableWrapper,
} from "../table-wrapper";

import type {
  RaceEthnicity,
  OpenTo,
  AdoptionRelationshipPreference,
  OpenToAdditionalInfo,
} from "@_types/*";

export type AdoptionPreferencesProps = {
  raceEthnicity: RaceEthnicity[];
  openTo: OpenTo[];
  openToAdditionalInfo?: OpenToAdditionalInfo[];
  levelOfOpenness: AdoptionRelationshipPreference[];
  availableBudget: number | null;
  budgetAvailableWithinWindow: boolean | null;
};

export const AdoptionPreferences = (props: AdoptionPreferencesProps) => {
  const {
    raceEthnicity,
    openTo,
    openToAdditionalInfo,
    levelOfOpenness,
    availableBudget,
    budgetAvailableWithinWindow,
  } = props;

  const formattedBudget =
    availableBudget === null
      ? null
      : `Up to ${
          availableBudget >= 1000
            ? `$${Math.floor(availableBudget / 1000)}k`
            : `$${availableBudget}`
        }`;
  const formattedBudgetAvailability =
    budgetAvailableWithinWindow === null
      ? null
      : budgetAvailableWithinWindow
      ? "Yes"
      : "No";

  const { t } = useTranslation("connect");

  const tableRows: TableRowData[] = useMemo(
    () => [
      {
        key: t(
          "familyListing.familyDetails.adoptionPreferences.table.rows.raceEthnicity"
        ),
        values: raceEthnicity,
      },
      {
        key: t(
          "familyListing.familyDetails.adoptionPreferences.table.rows.openTo"
        ),
        values: openTo,
        renderValue: (val) => {
          const additionalInfo = openToAdditionalInfo?.find(
            (item) => item.key === val
          );
          return (
            <>
              <DefaultTableRowRenderer
                value={val}
                // using the whole array, not just matched elememts, because
                // all items in this category should be visually similar if
                // any of them have secondary items
                hasSecondaryItem={(openToAdditionalInfo?.length ?? 0) > 0}
              />
              {additionalInfo && (
                <SecondaryItemTableRowRenderer
                  value={additionalInfo.additionalInfo}
                />
              )}
            </>
          );
        },
      },
      {
        key: t(
          "familyListing.familyDetails.adoptionPreferences.table.rows.levelOfOpenness"
        ),
        values: levelOfOpenness,
      },
      {
        key: t(
          "familyListing.familyDetails.adoptionPreferences.table.rows.availableBudget"
        ),
        values: [formattedBudget],
      },
      {
        key: t(
          "familyListing.familyDetails.adoptionPreferences.table.rows.budgetAvailableWithinWindow"
        ),
        values: [formattedBudgetAvailability],
      },
    ],
    [
      raceEthnicity,
      openTo,
      levelOfOpenness,
      formattedBudget,
      formattedBudgetAvailability,
      openToAdditionalInfo,
      t,
    ]
  );

  return (
    <TableWrapper
      title={t("familyListing.familyDetails.adoptionPreferences.title")}
      rows={tableRows}
    />
  );
};
