import { palette, typography } from "@pairtreefamily/ui";

export const styles = {
  labelWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
  },
  counter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    backgroundColor: palette.background.light,
    border: `1px solid ${palette.divider}`,
    height: "24px",
    width: "24px",
    color: palette.text.primary,
    ...typography.caption2,
  },
};
