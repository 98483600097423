import { palette } from "@pairtreefamily/ui";

export const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    pt: 4,
    pb: 2,
    gap: "10px",
  },
  description: {
    maxWidth: 784,
    color: palette.text.primary,
  },
};
