import { useCallback } from "react";
import { initLogger } from "@pairtreefamily/utils";
import type {
  ElectronicDocumentTask,
  ExtendedTaskGroup,
  FileUploadTask,
  ReferenceTask,
  Task,
  TaskGroup,
} from "@_types/*";
import { Bindings } from "pino";

const logger = initLogger("pages.professional.index.tsx");

type UpsertTaskType = {
  caseId: string;
  task: Task | ElectronicDocumentTask | FileUploadTask | ReferenceTask;
};

// TODO: Set correct types
interface UseCreateUpdateTaskProps {
  taskGroups?: ExtendedTaskGroup[];
  currentGroupAddingTask?: TaskGroup;
  upsertElectronicDocumentTask: any;
  caseId: string;
  setTaskManagerModalOpen: (value: boolean) => void;
  upsertFileUploadTask: any;
  upsertExternalSignerTask: any;
}

export const useCreateUpdateTask = ({
  taskGroups,
  currentGroupAddingTask,
  caseId,
  upsertElectronicDocumentTask,
  setTaskManagerModalOpen,
  upsertFileUploadTask,
  upsertExternalSignerTask,
}: UseCreateUpdateTaskProps) => {
  return useCallback(
    // TODO: Set correct types
    async (args: any) => {
      const groupTasks = taskGroups?.find(
        (item: any) => item.id === currentGroupAddingTask?.id
      );
      if (args.taskType === "electronicDocument" && args.templateUuid) {
        await upsertElectronicDocumentTask({
          caseId,
          task: {
            status: args.status ?? "notStarted",
            taskGroupId: currentGroupAddingTask?.id ?? "",
            caseId,
            ...args,
            taskType: "electronicDocument",
            templateUuid: args.templateUuid,
            orderNumber: args.orderNumber ?? groupTasks?.tasks.length ?? 1,
            answer: args.answer,
          },
        });
        setTaskManagerModalOpen(false);
      }
      if (args.taskType === "fileUpload") {
        try {
          const result = await upsertFileUploadTask({
            caseId,
            task: {
              status: args.status ?? "notStarted",
              taskGroupId: currentGroupAddingTask?.id ?? "",
              caseId,
              ...args,
              taskType: "fileUpload",
              orderNumber: args.orderNumber ?? groupTasks?.tasks.length ?? 1,
              answer: args.answer,
            },
          });
          logger
            .child(result)
            .info("TaskManagerModal > upsertFileUploadTask result");
          setTaskManagerModalOpen(false);
          return true;
        } catch (err: any) {
          logger
            .child(err)
            .info("TaskManagerModal > upsertFileUploadTask error");
          return false;
        }
      }
      if (args.taskType === "reference" && args.templateUuid) {
        await upsertExternalSignerTask({
          caseId,
          task: {
            ...args,
            taskGroupId: currentGroupAddingTask?.id || args.taskGroupId || "",
            status: args.status ?? "notStarted",
            title: args.title,
            caseId,
            question: args.question,
            answer: args.answer,
            taskType: "reference",
            templateUuid: args.templateUuid,
            isPrivate: args.isPrivate ?? true,
            expirationDateRequired: args.expirationDateRequired,
            expirationDate: args.expirationDate,
          },
        });
        setTaskManagerModalOpen(false);
      }
      return false;
    },
    [
      caseId,
      currentGroupAddingTask?.id,
      setTaskManagerModalOpen,
      taskGroups,
      upsertElectronicDocumentTask,
      upsertFileUploadTask,
      upsertExternalSignerTask,
    ]
  );
};
