import {
  phoneNumberValidation,
  requiredString,
} from "@shared/validation-schemas";
import { z } from "zod";

export const validationSchema = z.object({
  proUuid: requiredString,
  firstName: requiredString,
  lastName: requiredString,
  title: z.string().optional(),
  contactEmail: z.string().email(),
  contactPhone: phoneNumberValidation,
  contactPhoneExt: z.string().optional(),
});

export type FormValues = z.infer<typeof validationSchema>;
