import { flexRender } from "@tanstack/react-table";

import type { ThreadListingRow } from "../threads/types";
import { TableHeader } from "../../../connect/components/family-listings/components";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { Table as ReactTable } from "@tanstack/table-core/build/lib/types";
import { styles } from "./styles";

interface TableProps {
  table: ReactTable<ThreadListingRow>;
  onRowClick: (row: ThreadListingRow) => void;
}

export const Table = ({ table, onRowClick }: TableProps) => {
  return (
    <div>
      <MuiTable sx={styles.table}>
        <TableHeader table={table} />

        <TableBody>
          {table.getRowModel().rows?.map((row) => (
            <TableRow key={row.id} onClick={() => onRowClick(row.original)}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </div>
  );
};
