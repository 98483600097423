import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "next-i18next";
import {
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Box, Link, Typography } from "@mui/material";

import {
  useGetFamilyList,
  useGetFamilyListCount,
  toSnakeCase,
  FamilyListFilterPayload,
} from "@api";

import { Button, Loading } from "@pairtreefamily/ui";
import {
  ContentWrapper,
  SearchBox,
  TablePagination,
  useOrganizationsData,
} from "@shared/components";
import {
  FamilyDetailsSidePanel,
  FilterSidePanel,
  Table,
  ErrorMessage,
  ContactFamilySidePanel,
} from "./components";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";

import AddIcon from "@mui/icons-material/Add";
import { filterEmptyFields, getActiveFiltersCount } from "./utils";

import { styles } from "./styles";
import { initialFilters, initialPagination, initialSorting } from "./constants";
import { useColumns, useTableData } from "./hooks";

import type { FamilyFiltersFields } from "./types";
import { appConfig } from "@lib";
import { InviteFamilySidePanel } from "../invite-family-side-panel";

export type FamilyListingsProps = {
  organizationUuid: string | null;
};

export const FamilyListings = (props: FamilyListingsProps) => {
  const { organizationUuid } = props;

  const { userOrganizationData } = useOrganizationsData();
  const { t } = useTranslation("connect");

  const [isFilterSideOpened, setIsFilterSideOpened] = useState(false);

  const [selectedContactFamilyId, setSelectedContactFamilyId] = useState<
    string | null
  >(null);

  const [selectedRowSlug, setSelectedRowSlug] = useState<{
    slug: string | undefined;
    isPublished: boolean;
  } | null>(null);

  const [isFamilyDetailsSideOpened, setIsFamilyDetailsSideOpened] =
    useState(false);

  const [isInviteFamilySideOpened, setIsInviteFamilySideOpened] =
    useState(false);

  const [familyNameQuery, setFamilyNameQuery] = useState("");

  const [sorting, setSorting] = useState<SortingState>(initialSorting);
  const [pagination, setPagination] =
    useState<PaginationState>(initialPagination);

  const [filters, setFilters] = useState<FamilyFiltersFields>(initialFilters);

  const handleFamilyNameClick = useCallback(
    (slug: string, isPublished: boolean) => {
      setSelectedRowSlug({ slug, isPublished });
      setIsFamilyDetailsSideOpened(true);
    },
    []
  );

  const handleSearchChange = useCallback((value: string) => {
    setPagination(initialPagination);
    setFamilyNameQuery(value);
  }, []);

  const handleContactClick = useCallback(
    (id: string, slug: string | undefined, isPublished: boolean) => {
      setSelectedContactFamilyId(id);
      setSelectedRowSlug({ slug, isPublished });
    },
    []
  );

  const handleContactClose = useCallback(() => {
    setSelectedContactFamilyId(null);
  }, []);

  const filterRequestPayload = useMemo<FamilyListFilterPayload>(
    () => ({
      ...filterEmptyFields(filters),
      ...(organizationUuid ? { organizationUuid } : {}),
      ...(familyNameQuery.length ? { familyNameQuery } : {}),
      ...(sorting[0] && {
        sortBy: toSnakeCase(sorting[0].id),
        sortDirection: sorting[0].desc ? "desc" : "asc",
      }),
    }),
    [familyNameQuery, filters, organizationUuid, sorting]
  );

  const {
    data: familiesData,
    isLoading: isFamiliesDataLoading,
    isError: isFamiliesDataError,
    refetch,
  } = useGetFamilyList({
    organizationUuid: userOrganizationData?.id ?? "",
    query: {
      limit: pagination.pageSize,
      page: pagination.pageIndex,
    },
    body: filterRequestPayload,
  });

  const columns = useColumns(
    familyNameQuery,
    handleFamilyNameClick,
    handleContactClick,
    refetch
  );

  const { data: dataAmount, isLoading: isDataAmountLoading } =
    useGetFamilyListCount({
      body: filterRequestPayload,
      organizationUuid: userOrganizationData?.id ?? "",
    });

  const dataTableList = useTableData(familiesData?.items);

  const table = useReactTable({
    data: dataTableList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: true,
    enableSortingRemoval: false,
    enableMultiSort: false,
    manualSorting: true,
    manualPagination: true,
    manualFiltering: true,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
  });

  const handlePageChange = (pageIndex: number) => {
    setPagination((prevState) => ({ ...prevState, pageIndex }));
  };

  const handleFiltersChange = useCallback(
    (updatedFilters: FamilyFiltersFields) => {
      setPagination(initialPagination);
      setFilters(updatedFilters);
    },
    []
  );

  const activeFiltersCount = useMemo(
    () => getActiveFiltersCount(filters),
    [filters]
  );

  return (
    <ContentWrapper>
      <Box sx={styles.heading}>
        <Box sx={styles.filter}>
          <Button
            variant="outlined"
            startIcon={<TuneRoundedIcon sx={styles.filterIcon} />}
            onClick={() => setIsFilterSideOpened(true)}
          >
            {t("familyListing.heading.filtersLabel")}
          </Button>

          {Boolean(activeFiltersCount) && !isDataAmountLoading && (
            <>
              <Typography variant="body9">
                {t("familyListing.heading.filteredResults", {
                  amount: dataAmount?.count ?? 0,
                })}
              </Typography>

              <Link onClick={() => setIsFilterSideOpened(true)}>
                {t("familyListing.heading.showFilters", {
                  amount: activeFiltersCount,
                })}
              </Link>
            </>
          )}
        </Box>

        <Box display="flex" gap={2}>
          <SearchBox
            searchQuery={familyNameQuery}
            setSearchQuery={handleSearchChange}
            placeholder={t("familyListing.heading.searchPlaceholder")}
            sx={styles.search}
          />
          <Button
            startIcon={<AddIcon />}
            onClick={() => setIsInviteFamilySideOpened(true)}
          >
            {t("familyListing.heading.inviteFamilyBtn")}
          </Button>
        </Box>
      </Box>

      <Box sx={styles.table}>
        <Table table={table} />
      </Box>

      {isFamiliesDataLoading && <Loading />}

      {isFamiliesDataError && (
        <ErrorMessage
          t={t}
          errorKey="familyListing.errors.caseFetchIssue"
          email={appConfig.companyContactEmail}
        />
      )}

      {!isFamiliesDataError &&
        !isFamiliesDataLoading &&
        !familiesData?.items.length && (
          <ErrorMessage
            t={t}
            errorKey="familyListing.errors.emptyFamilies"
            email={appConfig.supportContactEmail}
          />
        )}

      {!isDataAmountLoading && Boolean(dataAmount?.count) && (
        <Box sx={styles.pagination}>
          <TablePagination
            pageIndex={pagination.pageIndex}
            pageSize={pagination.pageSize}
            totalCount={dataAmount?.count ?? 0}
            onPageChange={handlePageChange}
          />
        </Box>
      )}

      <FilterSidePanel
        selectedFilters={filters}
        totalCount={dataAmount?.count ?? 0}
        isOpen={isFilterSideOpened}
        onChange={handleFiltersChange}
        onClose={() => setIsFilterSideOpened(false)}
      />

      <FamilyDetailsSidePanel
        slug={selectedRowSlug?.slug}
        isPublished={selectedRowSlug?.isPublished ?? false}
        isOpen={isFamilyDetailsSideOpened}
        onContactClick={handleContactClick}
        onClose={() => setIsFamilyDetailsSideOpened(false)}
      />

      {!!selectedContactFamilyId && (
        <ContactFamilySidePanel
          slug={selectedRowSlug?.slug}
          onClose={handleContactClose}
          isOpen={!!selectedContactFamilyId}
          uuid={selectedContactFamilyId}
        />
      )}

      <InviteFamilySidePanel
        isOpen={isInviteFamilySideOpened}
        onClose={() => setIsInviteFamilySideOpened(false)}
      />
    </ContentWrapper>
  );
};
