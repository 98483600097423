import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "next-i18next";
import { useGetExpiringTasks } from "@api";
import { Header } from "./components/Header";
import { ContentWrapper } from "@shared/components";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";
import { DashboardTabs, TabEnum } from "./components/dashboard-tabs";
import { Box } from "@mui/material";
import { cutOffDate, sessionStorageTabKey } from "./constants";
import { FamiliesListTab, ExpiringTasksTab } from "./components";
import { ConcurrentLoader } from "@pairtreefamily/ui";

export const ProfessionalDashboardPage = () => {
  const [showInactiveFamilies, setShowInactiveFamilies] = useState(false);

  const { data: expiringTasks, isLoading: isExpiringTasksLoading } =
    useGetExpiringTasks(cutOffDate, !showInactiveFamilies);

  const { t } = useTranslation();

  useEffect(() => {
    z.setErrorMap(makeZodI18nMap({ t }));
  }, [t]);

  const getExpiringTasks = useMemo((): number => {
    return expiringTasks?.ok ? expiringTasks.content.length : 0;
  }, [expiringTasks]);

  // This workaround fixes issue when loosing a tab state when opening a new tab
  const sessionStorageTab =
    typeof window !== "undefined"
      ? sessionStorage.getItem(sessionStorageTabKey)
      : null;

  const [selectedTab, setSelectedTab] = useState(
    sessionStorageTab ? parseInt(sessionStorageTab, 10) : TabEnum.Families
  );
  const handleTabChange = (event: SyntheticEvent, newValue: TabEnum) => {
    sessionStorage.setItem(sessionStorageTabKey, newValue.toString());
    setSelectedTab(newValue);
  };

  return (
    <>
      <Header />

      <DashboardTabs
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
        expiringTasksCount={getExpiringTasks}
        onShowInactiveChange={setShowInactiveFamilies}
        showInactive={showInactiveFamilies}
      />

      <ContentWrapper>
        {selectedTab === TabEnum.Families && (
          <ConcurrentLoader>
            <Box mt={3}>
              <FamiliesListTab showInactive={showInactiveFamilies} />
            </Box>
          </ConcurrentLoader>
        )}

        {selectedTab === TabEnum.ExpiringTasks && (
          <Box mt={6}>
            <ExpiringTasksTab
              expiringTasks={expiringTasks?.ok ? expiringTasks.content : []}
              isExpiringTasksLoading={isExpiringTasksLoading}
            />
          </Box>
        )}
      </ContentWrapper>
    </>
  );
};
