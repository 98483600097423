export const styles = {
  mainWrapper: {
    mt: 3,
    pb: 10,
  },
  topActionsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 1.5,
  },
  noResultsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 1,
    pt: 8,
  },
  paginationWrapper: {
    mt: 2,
    display: "flex",
    justifyContent: "flex-end",
  },
};
