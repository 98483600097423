export const styles = {
  sidePanelContent: {
    px: 0,
  },
  sidePanelHeader: {
    px: 3,
  },
  contentWrapper: {
    pt: 6,
    display: "flex",
    flexDirection: "column",
    // 48px is a SidePanel header size
    height: "calc(100% - 48px)",
  },
};
