import { Tab, Tabs } from "@mui/material";
import { SyntheticEvent, useCallback } from "react";
import { useTranslation } from "next-i18next";

export enum OrganizationTabEnum {
  Overview,
  Members,
}

type OrganizationTabsProps = {
  value: number;
  onChange: (newValue: OrganizationTabEnum) => void;
};

export const OrganizationTabs = ({
  value,
  onChange,
}: OrganizationTabsProps) => {
  const { t } = useTranslation("organization");

  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: OrganizationTabEnum) => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <Tabs value={value} onChange={handleChange}>
      <Tab
        label={t("tabs.overview")}
        id={`tab-${OrganizationTabEnum.Overview}`}
      />
      <Tab
        label={t("tabs.members")}
        id={`tab-${OrganizationTabEnum.Members}`}
      />
    </Tabs>
  );
};
