import { UseFormReturn } from "react-hook-form";
import { FormValues } from "../validation-schema";
import { useMemo } from "react";

export const useGetIsPersonValid = (
  formMethods: UseFormReturn<FormValues>
): boolean => {
  const [firstName, lastName, email] = formMethods.watch([
    "firstName",
    "lastName",
    "email",
  ]);

  return useMemo(() => {
    return !!email && !!firstName && !!lastName;
  }, [email, firstName, lastName]);
};
