import { palette } from "@pairtreefamily/ui";

export const styles = {
  wrapper: {
    display: "flex",
    gap: 3,
  },
  bannerPhoto: {
    flexShrink: 0,
    width: 216,
    height: 160,
    objectFit: "cover",
    borderRadius: "10px",
  },
  infoSide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  familyNameLabel: {
    color: palette.text.dark,
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    wordBreak: "break-word",
  },
  verifiedSection: {
    display: "flex",
    alignItems: "center",
    gap: 0.5,
  },
  verifiedIcon: {
    color: palette.secondary.main,
  },
  verifiedLabel: {
    maxWidth: 115,
    color: palette.text.primary,
  },
};
